import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider } from "./context/session";
import Routers from "./routes";
import { NotificationsContainer } from "./components/design-systems/Alert/NotificationContainer";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <div className="App">
          <Routers />
          <NotificationsContainer />
        </div>
      </SessionProvider>
    </QueryClientProvider>
  );
}

export default App;
