import { SubmitHandler, useForm } from "react-hook-form";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../../../components/design-systems/FormGroup/FormGroup";
import { SideDialog } from "../../../components/design-systems/Dialog/SIdeDialog/SideDialog";
import { Button } from "../../../components/design-systems/Button";
import { ReminderFormData } from "../../ClinicCommunication/ProspectDialog/ProspectDialog";

type ReminderDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<ReminderFormData>;
  isLoading: boolean;
};

const ReminderDialog: React.FC<ReminderDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReminderFormData>();

  const handleDialogSubmit: SubmitHandler<ReminderFormData> = (data) => {
    console.log(data);
    onSubmit(data);
  };

  return (
    <SideDialog
      isOpen={isOpen}
      onClose={onClose}
      title={<h1>Add Reminder</h1>}
      actions={
        <div className="tw-flex tw-items-center tw-gap-2">
          <Button type="submit" form="reminderForm" isLoading={isLoading}>
            Save
          </Button>
        </div>
      }
    >
      <form
        className="tw-px-8 tw-pt-10"
        id="reminderForm"
        onSubmit={handleSubmit(handleDialogSubmit)}
      >
        <div className="tw-space-y-6">
          <FormGroup className="tw-space-y-3">
            <FormLabel className="tw-text-sm" htmlFor="reminder_date">
              Reminder date
            </FormLabel>
            <FormInput
              id="reminder_date"
              type="date"
              {...register("reminder_date", {
                required: "Reminder date is required",
              })}
            />
            {errors.reminder_date?.message && (
              <FormError>
                {errors.reminder_date.message as React.ReactNode}
              </FormError>
            )}
          </FormGroup>

          <FormGroup className="tw-space-y-3">
            <FormLabel className="tw-text-sm" htmlFor="reminder_comment">
              Comment
            </FormLabel>
            <FormInput
              id="reminder_comment"
              type="text"
              placeholder="Write a comment"
              {...register("reminder_comment", {
                required: "Comment is required",
              })}
            />
            {errors.reminder_comment?.message && (
              <FormError>{errors.reminder_comment.message}</FormError>
            )}
          </FormGroup>
        </div>
      </form>
    </SideDialog>
  );
};

export default ReminderDialog;
