import { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";
import {
  Description,
  FontWeightType,
  FontSizeType,
} from "../Description/Description";
import clsx from "clsx";

type AccordionProps = {
  title: React.ReactNode;
  titleSize?: FontSizeType;
  titleWeight?: FontWeightType;
  description?: string;
  descriptionSize?: FontSizeType;
  descriptionWeight?: FontWeightType;
  children: React.ReactNode;
  defaultOpen?: boolean;
  showBorder?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  title,
  titleSize = "lg",
  titleWeight = "semibold",
  description,
  descriptionSize,
  descriptionWeight,
  children,
  defaultOpen = false,
  showBorder = true,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={clsx("tw-w-full tw-border-dental-neutral-400 tw-mb-2", {
        "tw-border-y-[1.5px]": showBorder,
      })}
    >
      <div
        className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-cursor-pointer"
        onClick={toggleAccordion}
      >
        <Description
          title={title}
          titleSize={titleSize}
          titleWeight={titleWeight}
          description={description}
          descriptionSize={descriptionSize}
          descriptionWeight={descriptionWeight}
        />
        <div
          className={`tw-transition-transform tw-duration-500 ${
            isOpen ? "tw-rotate-180" : ""
          }`}
        >
          <CaretDown className="tw-w-5 tw-h-5" />
        </div>
      </div>
      <div
        className={`tw-overflow-hidden tw-transition-max-height tw-duration-500 ${
          isOpen ? "tw-max-h-[2000px] " : "tw-max-h-0"
        }`}
      >
        <div className="tw-p-4">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
