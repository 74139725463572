import { SubmitHandler, useForm } from "react-hook-form";

import {
  FormGroup,
  FormInput,
  FormLabel,
  FormError,
} from "../design-systems/FormGroup/FormGroup";
import { Button } from "../design-systems/Button/Button";
import { useCreateOnlineBookingMutation } from "../../api/rest/booking";
import { useParams } from "react-router-dom";
import { notify } from "../design-systems/Alert/notify";
import { AxiosError } from "axios";
import { ErrorResponse } from "../AddEmployee/AddEmployee";
import { useQueryClient } from "@tanstack/react-query";
import { StepDialog } from "../design-systems/Dialog/StepDialog/StepDialog";
import { PageWrapper } from "../PageWrapper";
import { Description } from "../design-systems/Description/Description";

type AddOnlineBookingDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormData = {
  name: string;
  baseUrl: string;
};

const AddOnlineBookingDialog: React.FC<AddOnlineBookingDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { clinicId } = useParams<{ clinicId: string }>();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const { mutate: createOnlineBookingMutation, isLoading } =
    useCreateOnlineBookingMutation({
      onSuccess: (response) => {
        notify(response.message, { type: "success" });
        queryClient.invalidateQueries(["onlineBooking"]);
        onClose();
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
        console.error("Mutation error:", error);
      },
    });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    createOnlineBookingMutation({
      clinicId: Number(clinicId),
      name: data.name,
      baseUrl: data.baseUrl,
    });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <StepDialog
      isOpen={isOpen}
      onClose={handleClose}
      totalSteps={1}
      currentStep={1}
      actions={
        <Button
          type="submit"
          form="onlineBookingForm"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      }
      isLoading={isLoading}
    >
      <PageWrapper className="tw-flex tw-mx-auto tw-h-full tw-max-w-xl tw-flex-col tw-px-0 tw-pb-12 tw-pt-4">
        <div className="">
          <form id="onlineBookingForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="tw-pb-8">
              <Description
                title="What should we call it?"
                titleWeight="semibold"
                titleSize="xxl"
                description="The name is only used internally to help you keep track of your online booking setups"
                gap={2}
              />
            </div>
            <div className="tw-space-y-6">
              <FormGroup className="tw-space-y-3">
                <FormLabel className="tw-text-sm" htmlFor="name">
                  Online booking name
                </FormLabel>
                <FormInput
                  id="name"
                  type="text"
                  placeholder="Give your online booking a name"
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <FormError>
                    {errors.name.message as React.ReactNode}
                  </FormError>
                )}
              </FormGroup>
              <FormGroup className="tw-space-y-3">
                <FormLabel className="tw-text-sm" htmlFor="baseUrl">
                  Base URL
                </FormLabel>
                <FormInput
                  id="baseUrl"
                  type="text"
                  placeholder="Add base URL"
                  {...register("baseUrl", {
                    required: "Base URL is required",
                  })}
                />
                {errors.baseUrl && (
                  <FormError>
                    {errors.baseUrl.message as React.ReactNode}
                  </FormError>
                )}
              </FormGroup>
            </div>
          </form>
        </div>
      </PageWrapper>
    </StepDialog>
  );
};

export default AddOnlineBookingDialog;
