import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../context/session";
import Login from "../Pages/Login";

const AuthRedirect: React.FC = () => {
  const { isAuthenticated, setToken, setUser } = useSession();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id");

  useEffect(() => {
    if (userId) {
      // Clear the local storage
      window.localStorage.clear();
      // Reset session state
      setToken(null);
      setUser(null);
    }
  }, [userId, setToken, setUser]);

  useEffect(() => {
    if (isAuthenticated() && userId) {
      navigate(`/?user_id=${userId}`, { replace: true });
    }
  }, [isAuthenticated, userId, navigate]);

  return isAuthenticated() && !userId ? (
    <Navigate to="/company/clinics" replace />
  ) : (
    <Login />
  );
};

export { AuthRedirect };
