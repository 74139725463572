import clsx from "clsx";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconDescription from "../design-systems/IconDescription/IconDescription";
import {
  CaretDown,
  CaretUp,
  FileText,
  Storefront,
  User,
  X,
} from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import DashboardIcon from "../icons/Dashboard";
import LogoutIcon from "../icons/LogoutIcon";
import { ReactComponent as LogoSvgWhite } from "../../img/logo-white.svg";
import { ReactComponent as LogoMarkSvg } from "../../img/logo-mark.svg";
import { useSession } from "../../context/session";

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
  const i18n = useTranslation();
  const [isActionsOpen, setIsActionsOpen] = useState(false);

  const { onSignout } = useSession();
  const navigate = useNavigate();

  const sidebarStyles = isOpen
    ? "tw-transform tw-translate-x-0"
    : "tw-transform tw-translate-x-full";

  const handleLogout = () => {
    onSignout();
    navigate("/");
  };

  return (
    <>
      <div
        className={`tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-50 tw-z-40 ${
          isOpen ? "tw-opacity-0 tw-visible" : "tw-opacity-0 tw-invisible"
        }`}
        style={{ transition: "opacity 0.3s ease-in-out" }}
        onClick={onClose}
      ></div>

      <div
        className={clsx(
          "tw-fixed tw-top-0 tw-right-0 tw-flex tw-flex-col tw-w-full tw-h-full tw-shadow-lg tw-z-50 tw-overflow-auto",
          sidebarStyles
        )}
        style={{
          transition: "transform 0.3s ease-in-out",
          background: "linear-gradient(to bottom, rgb(128, 109, 255), #5a189a)",
        }}
      >
        <LogoMarkSvg className="tw-fixed tw-bottom-28 tw-left-1/2 tw--translate-x-1/2 tw-z-[-1] tw-max-w-md tw-w-full tw-p-10 tw-opacity-20" />
        <div className="tw-flex tw-justify-between tw-items-center tw-px-8 tw-py-5">
          <LogoSvgWhite className="tw-w-44" />
          <div className="tw-rounded-full tw-p-2" onClick={onClose}>
            <X size={40} weight="bold" className="tw-text-white" />
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-5 tw-py-2 tw-px-8">
          <div onClick={onClose}>
            <Link to="/insights">
              <IconDescription
                Icon={<DashboardIcon size={30} className="tw-text-white" />}
                description={i18n.t("label.insights")}
                className="tw-text-white tw-py-4"
                textSize="lg"
              />
            </Link>
            <Link to="/profile">
              <IconDescription
                Icon={<User size={30} weight="fill" />}
                description={i18n.t("label.profile")}
                className="tw-text-white tw-py-4 "
                textSize="lg"
              />
            </Link>
            <Link to="/company">
              <IconDescription
                Icon={<Storefront size={30} weight="fill" />}
                description={i18n.t("label.company")}
                className="tw-text-white tw-py-4 "
                textSize="lg"
              />
            </Link>
            <Link to="/agreements">
              <IconDescription
                Icon={<FileText size={30} weight="fill" />}
                description={i18n.t("label.agreements")}
                className="tw-text-white tw-py-4 "
                textSize="lg"
              />
            </Link>
          </div>
          <div>
            <IconDescription
              Icon={
                isActionsOpen ? <CaretUp size={30} /> : <CaretDown size={30} />
              }
              description={i18n.t("label.actions")}
              className="tw-text-white tw-py-4"
              textSize="lg"
              onClick={() => setIsActionsOpen(!isActionsOpen)}
            />
            <div
              className={clsx(
                "tw-overflow-hidden tw-transition-all tw-duration-700 tw-ease-in-out",
                isActionsOpen ? "tw-max-h-96" : "tw-max-h-0"
              )}
            >
              {isActionsOpen && (
                <div className="tw-flex tw-w-full">
                  <div className="tw-w-[2px] tw-rounded-xl tw-bg-white" />
                  <div
                    className="tw-flex tw-flex-col tw-gap-5 tw-pl-2 tw-grow"
                    onClick={onClose}
                  >
                    <Link to={`/profile`}>
                      <IconDescription
                        description={i18n.t("label.communication")}
                        className="tw-text-white tw-py-2"
                      />
                    </Link>
                    <Link to={`/company`}>
                      <IconDescription
                        description={i18n.t("label.onlineBooking")}
                        className="tw-text-white tw-py-2"
                      />
                    </Link>
                    <Link to={`/agreements`}>
                      <IconDescription
                        description={i18n.t("label.offerPipeline")}
                        className="tw-text-white tw-py-2"
                      />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tw-mt-auto">
          <IconDescription
            Icon={<LogoutIcon className="tw-text-white" size={30} />}
            description={i18n.t("label.logout")}
            className="tw-rounded-lg tw-w-full tw-px-8 tw-py-8 tw-cursor-pointer tw-text-white tw-border-t-2"
            textSize="lg"
            onClick={handleLogout}
          />
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
