import { useForm } from "react-hook-form";
import { useLoginMutation } from "../api/auth";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../components/design-systems/FormGroup/FormGroup";
import { ReactComponent as LogoSvg } from "../img/logo.svg";
import { Button } from "../components/design-systems/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { CreateAccountType } from "../types/models/auth";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAccountType>();
  const { mutate: login, isLoading } = useLoginMutation();
  const navigate = useNavigate();

  const onSubmit = (data: CreateAccountType) => {
    login(data, {
      onSuccess: () => {
        navigate("/logged-in");
      },
    });
  };

  return (
    <div className="tw-flex tw-min-h-screen">
      <div className="tw-flex-1 tw-bg-dental-neutral-900 tw-m-2 tw-flex tw-flex-col tw-justify-center tw-pr-24 max-sm:tw-hidden">
        <div className="tw-m-20">
          <LogoSvg className="tw-w-32 tw-mb-16" />
          <h1 className="tw-text-3xl tw-text-dental-primary-P6 tw-mb-6">
            Welcome to Dental Media
          </h1>
          <p className="tw-text-base tw-text-dental-neutral-N6">
            Congratulations, your organization has been set up on Dental Media!
            You just need to create a personal login and you’ll be ready to go.
          </p>
        </div>
        <img
          src={require("../img/mockup-front-view.png")}
          alt="platform-mockup"
          className="tw-pb-28"
        />
      </div>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-p-8">
        <div className="tw-flex tw-justify-end tw-mb-4">
          <Link
            to="/"
            className="tw-text-base tw-font-medium tw-bg-dental-primary-P5 tw-px-4 tw-py-3 tw-rounded-md"
          >
            Login
          </Link>
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-items-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tw-space-y-10 tw-w-full tw-max-w-md"
          >
            <div className="tw-mb-10">
              <h1 className="tw-text-left tw-text-3xl tw-mb-4">
                Create your login
              </h1>
              <p className="tw-text-base tw-text-dental-neutral-N4 ">
                Just enter your login credentials to get back onto Dental Media
                and continue your workflow.
              </p>
            </div>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="name">Name*</FormLabel>
              <FormInput
                type="name"
                {...register("name", { required: "Name is required" })}
                placeholder="Enter your full name"
              />
              <FormError>{errors.name?.message}</FormError>
            </FormGroup>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="email">Email*</FormLabel>
              <FormInput
                type="email"
                {...register("email", { required: "Email is required" })}
                placeholder="Enter your email"
              />
              <FormError>{errors.email?.message}</FormError>
            </FormGroup>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <FormInput
                type="phone"
                {...register("phone", { required: "Phone number is required" })}
                placeholder="Enter your phone number"
              />
              <FormError>{errors.phone?.message}</FormError>
            </FormGroup>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="password">Password*</FormLabel>
              <FormInput
                type="password"
                {...register("password", { required: "Password is required" })}
                placeholder="Enter your password"
              />
              <FormError>{errors.password?.message}</FormError>
            </FormGroup>
            <div className="tw-flex tw-justify-end">
              <Button type="submit" disabled={isLoading} className="tw-w-">
                Login
              </Button>
            </div>
            {isLoading && <div>Loading...</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
