import { RadioGroup as TailwindRadioGroup } from "@headlessui/react";
import type { ExtractProps } from "../../../types";
import clsx from "clsx";
import { Circle, RadioButton } from "@phosphor-icons/react";

const RadioGroup = (props: ExtractProps<typeof TailwindRadioGroup>) => (
  <TailwindRadioGroup {...props} />
);

RadioGroup.Label = (props: ExtractProps<typeof TailwindRadioGroup.Label>) => (
  <TailwindRadioGroup.Label {...props} />
);

RadioGroup.Option = (props: ExtractProps<typeof TailwindRadioGroup.Option>) => (
  <TailwindRadioGroup.Option
    className="ui-active:tw-custom-focus-visible tw-flex tw-w-full tw-cursor-pointer tw-gap-2 tw-rounded-md tw-border tw-border-solid tw-p-4 ui-checked:tw-border-dental-neutral-N1 ui-not-checked:tw-border-gray-500"
    {...props}
  />
);

type OptionContentProps = {
  active?: boolean;
  checked?: boolean;
  children: React.ReactNode;
};
RadioGroup.OptionContent = ({
  checked = false,
  children,
}: OptionContentProps) => {
  const Icon = checked ? RadioButton : Circle;

  return (
    <>
      <div>
        <Icon
          className={clsx("tw-h-5 tw-w-5", {
            "tw-text-dental-neutral-N1": checked,
          })}
          weight={checked ? "fill" : "regular"}
        />
      </div>
      {children}
    </>
  );
};

export { RadioGroup };
