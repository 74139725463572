import { useForm } from "react-hook-form";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../components/design-systems/FormGroup/FormGroup";
import { ReactComponent as LogoSvg } from "../img/logo-white.svg";
import { Button } from "../components/design-systems/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ResetPasswordType, useResetPasswordMutation } from "../api/rest/user";
import { notify } from "../components/design-systems/Alert/notify";
import { AxiosError } from "axios";
import { ErrorResponse } from "../components/AddEmployee/AddEmployee";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordType>();
  const i18n = useTranslation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";
  const { mutate: resetPasswordMutation, isLoading } =
    useResetPasswordMutation();

  const onSubmit = (data: ResetPasswordType) => {
    const resetPasswordData = { ...data, token: token };
    resetPasswordMutation(resetPasswordData, {
      onSuccess: (data) => {
        notify(data.message);
        navigate("/");
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
        console.error("Mutation error:", error);
      },
    });
  };

  return (
    <div className="tw-flex tw-min-h-screen">
      <div className="tw-flex-1 tw-bg-dental-neutral-900 tw-m-2 tw-flex tw-flex-col tw-justify-center tw-pr-24 max-sm:tw-hidden">
        <div className="tw-m-20">
          <LogoSvg className="tw-w-32 tw-mb-16" />
          <h1 className="tw-text-3xl tw-text-dental-primary-P6 tw-mb-6">
            {i18n.t("welcome.dentalMedia")}
          </h1>
          {/* <p className="tw-text-base tw-text-dental-neutral-N6">
            {i18n.t("create.account.congratulations")}
          </p> */}
        </div>
        <img
          src={require("../img/mockup-front-view.png")}
          alt="platform-mockup"
          className="tw-pb-28"
        />
      </div>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-p-8">
        <div className="tw-flex tw-justify-end tw-mb-4">
          <Link
            to="/"
            className="tw-text-base tw-font-medium tw-bg-dental-primary-P5 tw-px-4 tw-py-3 tw-rounded-md"
          >
            {i18n.t("label.login")}
          </Link>
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-items-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tw-space-y-10 tw-w-full tw-max-w-md"
          >
            <div className="tw-mb-10">
              <h1 className="tw-text-left tw-text-3xl tw-mb-4">
                Reset your password
              </h1>
              <p className="tw-text-base tw-text-dental-neutral-N4 ">
                Create a new password to login to Dentli with and continue your
                workflow.
              </p>
            </div>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="email">{i18n.t("label.email")}</FormLabel>
              <FormInput
                type="email"
                {...register("email", { required: "Email is required" })}
                placeholder="Enter email"
              />
              <FormError>{errors.password?.message}</FormError>
            </FormGroup>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="password">
                {i18n.t("label.password")}
              </FormLabel>
              <FormInput
                type="password"
                {...register("password", { required: "Password is required" })}
                placeholder={i18n.t("placeholder.enter.password")}
              />
              <FormError>{errors.password?.message}</FormError>
            </FormGroup>
            <FormGroup className="tw-space-y-4">
              <FormLabel htmlFor="password_confirmation">
                {i18n.t("label.confirmPassword")}
              </FormLabel>
              <FormInput
                type="password"
                {...register("password_confirmation", {
                  required: "Confirm password is required",
                })}
                placeholder={i18n.t("placeholder.confirm.password")}
              />
              <FormError>{errors.password_confirmation?.message}</FormError>
            </FormGroup>
            <div className="tw-flex tw-justify-end">
              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                className="tw-w-"
              >
                {i18n.t("label.changePassword")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
