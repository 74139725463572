import { useRef, FC } from "react";
import { useDashboardQuery } from "../api/dashboard";

import { Loading } from "../components/design-systems/Loading/Loading";

import { useSession } from "../context/session";

const EmbedDashboard: FC<{ dashboardUrl: string | undefined }> = ({
  dashboardUrl,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (!dashboardUrl) {
    return null;
  }

  return (
    <iframe
      src={dashboardUrl}
      title="QuickSight Dashboard"
      width="100%"
      height="1000px"
      style={{ border: "none" }}
      ref={iframeRef}
    ></iframe>
  );
};

const Insights: FC = () => {
  const { user } = useSession();

  const email = user?.email || "";
  // const email = "carsten@dentalmedia.io";
  const authorization = "B_ixe5ITMUiKZxqwwqV-hHftilc=";

  const { data: dashboardUrl, isLoading: isDashboardLoading } =
    useDashboardQuery(authorization, email);

  if (!user) {
    return null;
  }

  return (
    <div className="tw-pl-2">
      {(user.access_level === "full" ||
        user.access_level === "admin" ||
        user.access_level === "practitioner") && (
        <div>
          {isDashboardLoading && <Loading />}
          <div style={{ width: "100%", height: "100%" }}>
            <EmbedDashboard dashboardUrl={dashboardUrl} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Insights;
