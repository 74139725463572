import { Routes, Route } from "react-router-dom";
import CompanyClinics from "./CompanyClinics";
import CompanyUsers from "./CompanyUsers";
import CompanySettings from "./CompanySettings/CompanySettings";

const CompanyRoutes = () => {
  return (
    <Routes>
      <Route path="clinics" element={<CompanyClinics />} />
      <Route path="users" element={<CompanyUsers />} />
      <Route path="settings" element={<CompanySettings />} />
    </Routes>
  );
};

export default CompanyRoutes;
