import { Outlet, useLocation, useParams } from "react-router-dom";

import { Pen, Plus, Storefront } from "@phosphor-icons/react";
import { useClinicsQuery } from "../../api/rest/clinic";

import { getClinicById } from "./clinic.utils";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { Button } from "../../components/design-systems/Button";
import { useTranslation } from "react-i18next";
import SingleClinicMenu from "./SingleClinicMenu";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { useState } from "react";
import AddUser from "../../components/AddUser/AddUser";
import { useSession } from "../../context/session";
import EditClinicInformationDialog from "./SingeClinicSettings/EditClinicInformationDialog";

const SingleClinic = () => {
  const { clinicId } = useParams();
  const { token } = useSession();
  const { t } = useTranslation();
  const location = useLocation();

  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditClinicSettingsDialogOpen, setIsEditClinicSettingsDialogOpen] =
    useState(false);

  const clinicsQuery = useClinicsQuery();
  const clinicsData = clinicsQuery.data || [];
  const clinic = getClinicById(clinicsData, clinicId);

  const openAddUserDialog = () => setIsAddUserDialogOpen(true);
  const closeAddUserDialog = () => setIsAddUserDialogOpen(false);

  const openEditClinicSettingsDialog = () =>
    setIsEditClinicSettingsDialogOpen(true);
  const closeEditClinicSettingsDialog = () =>
    setIsEditClinicSettingsDialogOpen(false);

  if (clinicsQuery.isLoading) {
    return <Loading />;
  }

  if (!clinic || !token) {
    return null;
  }

  const getActiveTab = (): "users" | "settings" => {
    if (location.pathname.includes("/users")) {
      return "users";
    } else if (location.pathname.includes("/settings")) {
      return "settings";
    } else {
      return "users"; // Default to "users" if no match
    }
  };

  return (
    <PageWithHeroWrapper
      title={`Clinic`}
      text={clinic.name}
      IconComponent={Storefront}
      showBackButton="/company/clinics"
      actions={
        <>
          {getActiveTab() === "users" && (
            <Button prefix={<Plus />} onClick={openAddUserDialog}>
              {t("label.addUser")}
            </Button>
          )}
          {getActiveTab() === "settings" && (
            <Button prefix={<Pen />} onClick={openEditClinicSettingsDialog}>
              {t("label.editClinic")}
            </Button>
          )}
        </>
      }
      submenu={
        <SingleClinicMenu activeTab={getActiveTab()} clinicId={clinicId!} />
      }
    >
      <div className="">
        <Outlet />
      </div>
      {isAddUserDialogOpen && (
        <AddUser
          onClose={closeAddUserDialog}
          clinics={[clinic]}
          token={token}
        />
      )}
      {isEditClinicSettingsDialogOpen && (
        <EditClinicInformationDialog onClose={closeEditClinicSettingsDialog} />
      )}
    </PageWithHeroWrapper>
  );
};

export default SingleClinic;
