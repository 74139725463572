import { useState } from "react";
import {
  DotsThreeOutlineVertical,
  Power,
  Trash,
  User as UserIcon,
} from "@phosphor-icons/react";
import IconCountButton from "../IconCountButton/IconCountButton";
import { User } from "../../../types/models/user";
import PenIcon from "../../icons/Pen";
import { useDeleteUserMutation } from "../../../api/rest/company";
import { useQueryClient } from "@tanstack/react-query";
import { notify } from "../Alert/notify";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../AddEmployee/AddEmployee";
import { apiUrl } from "../../../config";
import EditUser from "../../EditUser/EditUser";
import { Menu } from "../Menu/Menu";

const UserDisclosure = ({
  onEdit,
  onDelete,
}: {
  onEdit: () => void;
  onDelete: () => void;
}) => {
  return (
    <Menu>
      <Menu.Button
        isDropdown={false}
        className="tw-bg-transparent tw-border-none"
      >
        <DotsThreeOutlineVertical
          size={24}
          weight="fill"
          className="tw-text-dental-neutral-N1"
        />
      </Menu.Button>
      <Menu.Items align="bottomRight">
        <Menu.Item>
          {() => (
            <button className="tw-w-full" onClick={onEdit}>
              <IconCountButton
                text="Edit"
                icon={<PenIcon size={16} />}
                transparentBackground={true}
              />
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {() => (
            <button
              className="tw-w-full"
              onClick={() => console.log("Send invite")}
            >
              <IconCountButton
                text="Send invite"
                icon={<Power size={16} />}
                transparentBackground={true}
              />
            </button>
          )}
        </Menu.Item>
        <hr />
        <Menu.Item>
          {() => (
            <button onClick={onDelete}>
              <IconCountButton
                text="Delete"
                icon={
                  <Trash size={16} className="tw-text-dental-semantic-S1" />
                }
                color="red"
                transparentBackground={true}
              />
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

const UserRow = ({ user }: { user: User }) => {
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);

  const queryClient = useQueryClient();

  const { mutate: deleteUserMutation } = useDeleteUserMutation({
    onSuccess: (data) => {
      notify(data.message, { type: "success" });
      queryClient.invalidateQueries(["users"]);
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as ErrorResponse)?.message ||
        "An unexpected error occurred";
      notify(errorMessage, { type: "error" });
      console.error("Mutation error:", error);
    },
  });

  const closeEditUserDialog = () => setIsEditUserDialogOpen(false);

  const handleEdit = () => {
    setIsEditUserDialogOpen(true);
  };

  const handleDelete = () => {
    deleteUserMutation(user.id);
  };

  return (
    <div
      key={user.id}
      className="tw-grid tw-grid-cols-3 tw-py-6 tw-border-b-[2px] tw-border-dental-neutral-N8 max-md:tw-grid-cols-3 tw-relative"
    >
      <div className="tw-flex tw-items-center tw-gap-4">
        {user.photo ? (
          <img
            alt="User"
            src={`${apiUrl}/${user.photo}`}
            className="tw-object-cover tw-w-16 tw-h-16 tw-rounded-full"
          />
        ) : (
          <div className="tw-bg-dental-neutral-N8 tw-p-4 tw-rounded-full">
            <UserIcon
              size={24}
              weight="fill"
              className="tw-text-dental-neutral-N4"
            />
          </div>
        )}
        <div>
          <p className="tw-text-base tw-text">{user.name}</p>
          <IconCountButton
            className="md:tw-hidden"
            text={`${user.access_level} access`}
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-items-center max-md:tw-hidden">
        <IconCountButton text={`${user.access_level} access`} />
      </div>
      <div className="tw-flex tw-justify-end tw-items-center tw-pr-2 tw-col-span-1">
        <UserDisclosure onEdit={handleEdit} onDelete={handleDelete} />
      </div>
      {isEditUserDialogOpen && (
        <EditUser onClose={closeEditUserDialog} user={user} />
      )}
    </div>
  );
};

export default UserRow;
