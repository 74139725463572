import {
  UseMutationOptions,
  UseQueryOptions,
  useQuery,
  useMutation,
} from "@tanstack/react-query";
import axiosInstance from "../../axiosConfig";
import { useSession } from "../../context/session";
import { AxiosError } from "axios";

const fetchClinicSettings = async (clinicId: number, token: string) => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch clinic settings."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/clinics/${clinicId}/settings`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch clinic settings");
  }

  return response.data;
};

const useClinicSettingsQuery = (
  clinicId: number,
  options?: UseQueryOptions<any, AxiosError>
) => {
  const { token } = useSession();

  return useQuery<any, AxiosError>(
    ["clinicSettings", clinicId],
    () => fetchClinicSettings(clinicId, token!),
    {
      ...options,
      enabled: !!token && !!clinicId,
    }
  );
};

type UpdateSettingsPayload = {
  setting: string;
  value: string;
};

const updateClinicSettings = async (
  clinicId: number,
  token: string,
  { setting, value }: UpdateSettingsPayload
) => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to update clinic settings."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/clinics/${clinicId}/settings/updateSetting`,
    { setting, value },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-HTTP-Method-Override": "PATCH",
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to update clinic settings");
  }

  return response.data;
};

const useUpdateClinicSettingsMutation = (
  clinicId: number,
  options?: UseMutationOptions<any, AxiosError, UpdateSettingsPayload>
) => {
  const { token } = useSession();

  return useMutation<any, AxiosError, UpdateSettingsPayload>(
    (settings) => updateClinicSettings(clinicId, token!, settings),
    {
      ...options,
    }
  );
};

type TreatmentData = {
  dpm_treatment_id: string;
};

const createNewTreatment = async (
  onlineBookingId: number,
  token: string,
  treatmentData: { dpm_treatment_id: string }
) => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to create a new treatment."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/onlinebooking/${onlineBookingId}/treatments`,
    treatmentData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to create a new treatment");
  }

  return response.data;
};

const useCreateNewTreatmentMutation = (
  onlineBookingId: number,
  options?: UseMutationOptions<any, AxiosError, TreatmentData>
) => {
  const { token } = useSession();

  return useMutation<any, AxiosError, TreatmentData>(
    (treatmentData) =>
      createNewTreatment(onlineBookingId, token!, treatmentData),
    {
      ...options,
      mutationKey: ["treatments", onlineBookingId],
    }
  );
};

const deleteTreatment = async (
  onlineBookingId: number,
  treatmentId: string,
  token: string
) => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to delete a treatment."
    );
  }

  const response = await axiosInstance.delete(
    `/api/public/v2/onlinebooking/${onlineBookingId}/treatments/${treatmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to delete treatment");
  }

  return response.data;
};

const useDeleteTreatmentMutation = (
  onlineBookingId: number,
  options?: UseMutationOptions<any, AxiosError, { treatmentId: string }>
) => {
  const { token } = useSession();

  return useMutation<any, AxiosError, { treatmentId: string }>(
    ({ treatmentId }) => deleteTreatment(onlineBookingId, treatmentId, token!),
    {
      ...options,
      mutationKey: ["treatments", onlineBookingId],
    }
  );
};

export {
  useClinicSettingsQuery,
  useUpdateClinicSettingsMutation,
  useCreateNewTreatmentMutation,
  useDeleteTreatmentMutation,
};
