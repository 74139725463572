import axiosInstance from "../axiosConfig";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

const fetchDashboardHtml = async (
  authorization: string,
  email: string
): Promise<string> => {
  const response = await axiosInstance.get(
    "https://dashboard.core.dentli.io/TestOrg",
    {
      headers: {
        email: email,
        authorization: authorization,
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      `Failed to fetch dashboard HTML with status: ${response.status}`
    );
  }
};

const useDashboardQuery = (
  authorization: string,
  email: string,
  options?: UseQueryOptions<string, Error>
) => {
  return useQuery<string, Error>(
    ["dashboardHtml", authorization, email],
    () => fetchDashboardHtml(authorization, email),
    {
      ...options,
      staleTime: Infinity,
      cacheTime: 24 * 60 * 60 * 1000,
    }
  );
};

export { useDashboardQuery };
