import { createContext, useContext, useEffect, useState } from "react";
import { User } from "../types/models/user";
import { setupAxiosInterceptors } from "../axiosConfig";

type Session = {
  token: string | null;
  setToken: (token: string | null) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  productAccess: any[] | null;
  setProductAccess: (access: any[] | null) => void;
  isAuthenticated: () => boolean;
  onSignout: () => void;
  handleSignedIn: (userData: User, authToken: string, access: any[]) => void;
};

const SessionContext = createContext<Session>({
  token: null,
  setToken: () => {},
  user: null,
  setUser: () => {},
  productAccess: null,
  setProductAccess: () => {},
  isAuthenticated: () => false,
  onSignout: () => {},
  handleSignedIn: () => {},
});

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};

const authTokenKey = "authToken";
const userStorageKey = "user";
const productAccessKey = "productAccess";

const setAuthTokenInStorage = (token: string) => {
  window.localStorage.setItem(authTokenKey, token);
};

const getAuthTokenFromStorage = () => {
  return window.localStorage.getItem(authTokenKey);
};

const removeAuthTokenFromStorage = () => {
  window.localStorage.removeItem(authTokenKey);
};

const setUserInStorage = (user: User) => {
  window.localStorage.setItem(userStorageKey, JSON.stringify(user));
};

const getUserFromStorage = () => {
  const userData = window.localStorage.getItem(userStorageKey);
  if (!userData) return null;

  try {
    return JSON.parse(userData);
  } catch (error) {
    console.error("Failed to parse user data:", error);
    return null;
  }
};

const removeUserFromStorage = () => {
  window.localStorage.removeItem(userStorageKey);
};

const setProductAccessInStorage = (access: any[]) => {
  window.localStorage.setItem(productAccessKey, JSON.stringify(access));
};

const getProductAccessFromStorage = () => {
  const accessData = window.localStorage.getItem(productAccessKey);
  if (!accessData) return null;

  try {
    return JSON.parse(accessData);
  } catch (error) {
    console.error("Failed to parse product access data:", error);
    return null;
  }
};

const removeProductAccessFromStorage = () => {
  window.localStorage.removeItem(productAccessKey);
};

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [token, setToken] = useState<string | null>(getAuthTokenFromStorage());
  const [user, setUser] = useState<User | null>(getUserFromStorage());
  const [productAccess, setProductAccess] = useState<any[] | null>(getProductAccessFromStorage());


  const handleSignedIn = (userData: User, authToken: string, access: any[]) => {
    setUser(userData);
    setToken(authToken);
    setProductAccess(access);
    setUserInStorage(userData);
    setAuthTokenInStorage(authToken);
    setProductAccessInStorage(access);
  };

  const isAuthenticated = () => {
    return token != null;
  };

  const onSignout = () => {
    setToken(null);
    setUser(null);
    setProductAccess(null);
    removeAuthTokenFromStorage();
    removeUserFromStorage();
    removeProductAccessFromStorage();
    console.log("User signed out");
  };

  useEffect(() => {
    if (!token) {
      onSignout();
    } else {
      setAuthTokenInStorage(token);
    }

    // Setup Axios interceptors
    setupAxiosInterceptors(onSignout);
  }, [token]);

  return (
    <SessionContext.Provider
      value={{
        token,
        setToken,
        isAuthenticated,
        onSignout,
        user,
        setUser,
        productAccess,
        setProductAccess,
        handleSignedIn,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export {
  setAuthTokenInStorage,
  getAuthTokenFromStorage,
  removeAuthTokenFromStorage,
};
