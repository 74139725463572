import React from "react";
import { Link } from "react-router-dom";
import { Circle, DotsThreeOutlineVertical, Trash } from "@phosphor-icons/react";
import clsx from "clsx";
import {
  OnlineBooking as BookingType,
  OnlineBookingClnicType,
} from "../../types/models/bookings";
import IconCountButton from "../../components/design-systems/IconCountButton/IconCountButton";
import { useTranslation } from "react-i18next";
import { Badge } from "../../components/design-systems/Badge";
import { Menu } from "../../components/design-systems/Menu/Menu";
import { Description } from "../../components/design-systems/Description/Description";

type OnlineBookingListProps = {
  clinic: OnlineBookingClnicType; // Pass only one clinic instead of an array
  clinicId: number;
  onDeleteBooking: (onlineBookingId: number) => void;
};

const OnlineBookingList: React.FC<OnlineBookingListProps> = ({
  clinic,
  clinicId,
  onDeleteBooking,
}) => {
  const { t } = useTranslation();
  return (
    <div className="tw-pb-6">
      {clinic?.onlinebookings?.map((booking: BookingType) => (
        <Link
          key={booking.id}
          to={`/booking/settings/${clinicId}/${booking.id}`}
          className="tw-no-underline"
        >
          <div className="tw-grid tw-grid-cols-4 max-md:tw-grid-cols-1 tw-p-2 tw-cursor-pointer hover:tw-bg-dental-primary-P5">
            <div className="tw-col-span-1">
              <img
                alt="online-booking"
                className="tw-border tw-w-full tw-rounded-md tw-shadow-md"
                src={require("../../img/online-booking.png")}
              />
            </div>
            <div className="tw-space-y-6 tw-col-span-2 tw-py-2 tw-px-6">
              <Description
                title={t("label.domain")}
                titleWeight="semibold"
                titleSize="base"
                description={booking.base_url}
              />
              <Description
                title="Treatment IDs"
                titleWeight="semibold"
                titleSize="base"
                description={
                  <div className="tw-flex tw-gap-2">
                    {booking.treatments.map((treatment) => (
                      <div
                        key={treatment.id}
                        className="tw-bg-dental-primary-P5 tw-text-sm tw-font-medium tw-inline-block tw-rounded-md tw-p-2"
                      >
                        {treatment.treatment_id}
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
            <div className="tw-ml-auto max-md:tw-hidden">
              <div className="tw-flex tw-items-center">
                <Badge
                  color={booking.active ? "success" : "blue"}
                  className="tw-flex tw-items-center tw-gap-2"
                >
                  <Circle
                    className={clsx({
                      "tw-text-green-600": booking.active,
                      "tw-text-dental-neutral-N2": !booking.active,
                    })}
                    size={8}
                    weight="fill"
                  />
                  <p> {booking.active ? "Active" : "Inactive"}</p>
                </Badge>
                <Menu>
                  <Menu.Button
                    isDropdown={false}
                    className="tw-bg-transparent tw-border-none"
                  >
                    <DotsThreeOutlineVertical
                      size={24}
                      weight="fill"
                      className="tw-text-dental-neutral-N1"
                    />
                  </Menu.Button>
                  <Menu.Items align="bottomRight">
                    <Menu.Item>
                      <button
                        className="tw-w-full"
                        onClick={() => onDeleteBooking(booking.id)}
                      >
                        <IconCountButton
                          text="Delete"
                          icon={
                            <Trash
                              size={16}
                              className="tw-text-dental-semantic-S1"
                            />
                          }
                          color="red"
                          transparentBackground={true}
                        />
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default OnlineBookingList;
