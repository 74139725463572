import { AxiosError } from "axios";
import axiosInstance from "../axiosConfig";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

type FullUrlType = {
  full_url: string;
};

const fetchFullUrlFromShortlink = async (
  shortlink: string
): Promise<FullUrlType> => {
  const response = await axiosInstance.get(
    `/api/shortlink/get-full-url/${shortlink}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to fetch users from clinic");
  }

  return response.data.data;
};

const useFullUrlFromShortlinkQuery = (
  shortlink: string,
  options?: UseQueryOptions<FullUrlType, AxiosError>
) => {
  return useQuery<FullUrlType, AxiosError>(
    ["fullUrlFromShortlink", shortlink],
    () => fetchFullUrlFromShortlink(shortlink),
    {
      ...options,
    }
  );
};

export { useFullUrlFromShortlinkQuery };
