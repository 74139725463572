const SmsIcon = ({
    className,
    size = 20,
    ...props
}: {
    className?: string;
    size?: number;
}) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 40 38"
            stroke="currentColor"
            {...props}
        >

            <path d="M11.3 17.85C11.8667 17.85 12.3417 17.6583 12.725 17.275C13.1083 16.8917 13.3 16.4167 13.3 15.85C13.3 15.2833 13.1083 14.8083 12.725 14.425C12.3417 14.0417 11.8667 13.85 11.3 13.85C10.7333 13.85 10.2583 14.0417 9.875 14.425C9.49167 14.8083 9.3 15.2833 9.3 15.85C9.3 16.4167 9.49167 16.8917 9.875 17.275C10.2583 17.6583 10.7333 17.85 11.3 17.85ZM20.15 17.85C20.7167 17.85 21.1917 17.6583 21.575 17.275C21.9583 16.8917 22.15 16.4167 22.15 15.85C22.15 15.2833 21.9583 14.8083 21.575 14.425C21.1917 14.0417 20.7167 13.85 20.15 13.85C19.5833 13.85 19.1083 14.0417 18.725 14.425C18.3417 14.8083 18.15 15.2833 18.15 15.85C18.15 16.4167 18.3417 16.8917 18.725 17.275C19.1083 17.6583 19.5833 17.85 20.15 17.85ZM28.65 17.85C29.2167 17.85 29.6917 17.6583 30.075 17.275C30.4583 16.8917 30.65 16.4167 30.65 15.85C30.65 15.2833 30.4583 14.8083 30.075 14.425C29.6917 14.0417 29.2167 13.85 28.65 13.85C28.0833 13.85 27.6083 14.0417 27.225 14.425C26.8417 14.8083 26.65 15.2833 26.65 15.85C26.65 16.4167 26.8417 16.8917 27.225 17.275C27.6083 17.6583 28.0833 17.85 28.65 17.85ZM8 32L2.55 37.45C2.08333 37.9167 1.54167 38.0224 0.925 37.7672C0.308333 37.512 0 37.0563 0 36.4V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H37C37.8 0 38.5 0.3 39.1 0.9C39.7 1.5 40 2.2 40 3V29C40 29.8 39.7 30.5 39.1 31.1C38.5 31.7 37.8 32 37 32H8ZM3 29H37V3H3V29Z" fill="black" />
        </svg>


    );
};

export default SmsIcon;
