import { CalendarCheck } from "@phosphor-icons/react";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { useClinicsQuery } from "../../api/rest/clinic";
import { Link } from "react-router-dom";
import ClinicRow from "../../components/design-systems/ClinicRow/ClinicRow";
import { useTranslation } from "react-i18next";
import { useSession } from "../../context/session";
import { Clinic } from "../../types/models/company";

const OnlineBookingSelectClinic = () => {
  const { productAccess } = useSession();
  const { t } = useTranslation();
  const clinicsQuery = useClinicsQuery();
  const clinicsData = clinicsQuery.data || [];

  const onlineBookingProduct = productAccess?.find(
    (product) => product.name === "Online Booking"
  );

  const clinicsFromOnlineBookingProduct =
    onlineBookingProduct?.products.flatMap((product: any) => product.clinics) ||
    [];

  console.log({ clinicsData });

  return (
    <PageWithHeroWrapper
      title={t("label.onlineBooking")}
      text="Set up and manage your online booking system"
      IconComponent={CalendarCheck}
    >
      <div className="">
        {clinicsFromOnlineBookingProduct.length > 0 ? (
          clinicsFromOnlineBookingProduct.map((clinic: Clinic) => (
            <Link
              key={clinic.id}
              to={`/booking/${clinic.id}`}
              className="tw-no-underline"
            >
              <ClinicRow clinic={clinic} />
            </Link>
          ))
        ) : (
          <p>No clinics available for the Online Booking product.</p>
        )}
      </div>
    </PageWithHeroWrapper>
  );
};

export default OnlineBookingSelectClinic;
