import { Megaphone } from "@phosphor-icons/react";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { Description } from "../../components/design-systems/Description/Description";

const OfferPipeline = () => {
  return (
    <PageWithHeroWrapper
      title="Offer Pipeline"
      text="offer pipeline"
      IconComponent={Megaphone}
    >
      <div>
        <Description
          theme="default"
          title="This page is coming soon"
          titleSize="sm"
          titleWeight="normal"
        />
      </div>
    </PageWithHeroWrapper>
  );
};

export default OfferPipeline;
