import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Alert, AlertProps } from "../Alert/Alert";
import { IRequestError } from "../../../api/error";

type FormLabelProps = React.HTMLProps<HTMLLabelElement> & {
  isOptional?: boolean;
  className?: string;
};
export const FormLabel: React.FC<FormLabelProps> = ({
  children,
  isOptional,
  className,
  ...props
}) => (
  <label className={clsx("", className)} {...props}>
    {children}
    {isOptional && <span className="tw-ml-2 tw-text-gray-500">(Optional)</span>}
  </label>
);

export const FormInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & { className?: string }
>(({ className, ...props }, ref) => (
  <input
    ref={ref}
    className={clsx(
      "tw-py-[15px] tw-px-4 tw-border-[1px] tw-border-dental-primary-P5 tw-bg-dental-neutral-N10 tw-rounded-lg tw-w-full tw-text-dental-neutral-N1",
      className
    )}
    {...props}
  />
));

export const FormError = (props: AlertProps) =>
  props.children ? (
    <Alert
      type="error"
      size="sm"
      contentClassname="tw-text-alert-error"
      data-testid="form-error"
      {...props}
    />
  ) : null;

type FormSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  className?: string;
};

export const FormSelect: React.FC<FormSelectProps> = ({
  className,
  children,
  ...props
}) => (
  <select
    className={clsx(
      "tw-py-[15px] tw-px-4 tw-border-[1px] tw-border-dental-primary-P5 tw-rounded-lg tw-w-full tw-text-dental-neutral-N6",
      className
    )}
    {...props}
  >
    {children}
  </select>
);

// FormGroup Component
type FormGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
};
export const FormGroup: React.FC<FormGroupProps> = ({
  children,
  className,
  ...props
}) => (
  <div className={clsx("tw-space-y-2", className)} {...props}>
    {children}
  </div>
);

export const FormErrorList = ({
  error,
  className,
}: {
  error: IRequestError | Error;
  className?: string;
}) => {
  const i18n = useTranslation();

  const isErrorRequestError = (
    error: Error | IRequestError
  ): error is IRequestError => {
    return (error as IRequestError).errors !== undefined;
  };

  return (
    <div
      className={clsx(
        "tw-space-y-1 tw-rounded tw-border tw-border-error tw-p-2",
        className
      )}
    >
      {isErrorRequestError(error) ? (
        error.errors.length > 1 ? (
          <Alert type="error" className="tw-gap-2">
            <ul className="tw-list-disc tw-pl-4">
              {error.errors.map((e, i) => (
                <li key={i}>{i18n.t(e.message.code)}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <Alert type="error">{i18n.t(error.errors[0].message.code)}</Alert>
        )
      ) : (
        // Handle standard Error object
        <Alert type="error">{i18n.t(error.message)}</Alert>
      )}
    </div>
  );
};
