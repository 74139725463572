import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import axiosInstance from "../../axiosConfig";
import { useSession } from "../../context/session";
import {
  OnlineBooking,
  OnlineBookingClnicType,
} from "../../types/models/bookings";
import { AxiosError } from "axios";
import { Clinic } from "../../types/models/company";

type DeleteUserResponse = {
  message: string;
};

type CreateOnlineBookingType = {
  message: string;
  data: OnlineBooking;
};

type FetchSingleOnlineBookingType = OnlineBooking & {
  clinic: Clinic;
};
const fetchOnlineBooking = async (
  clinicId: number,
  token: string
): Promise<OnlineBookingClnicType[]> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch online booking details."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/onlinebooking?clinic_id=${clinicId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch online booking details");
  }

  return response.data.data;
};

const createOnlineBooking = async (
  clinicId: number,
  name: string,
  baseUrl: string,
  token: string
): Promise<CreateOnlineBookingType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to create an online booking."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/onlinebooking`,
    { clinic_id: clinicId, name, base_url: baseUrl },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to create online booking");
  }

  return response.data.data;
};

const updateOnlineBooking = async (
  onlineBookingId: number,
  updatedData: Partial<OnlineBooking>,
  token: string
) => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to update an online booking."
    );
  }

  const response = await axiosInstance.put(
    `/api/public/v2/onlinebooking/${onlineBookingId}`,
    updatedData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to update online booking");
  }

  return response.data;
};

const deleteOnlineBooking = async (
  onlineBookingId: number,
  token: string
): Promise<DeleteUserResponse> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to delete an online booking."
    );
  }

  const response = await axiosInstance.delete(
    `/api/public/v2/onlinebooking/${onlineBookingId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to delete online booking");
  }
  return response.data;
};

const fetchSingleOnlineBooking = async (
  onlineBookingId: number,
  token: string
): Promise<FetchSingleOnlineBookingType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch online booking details."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/onlinebooking/${onlineBookingId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch online booking details");
  }

  return response.data.data;
};

const useOnlineBookingQuery = (
  clinicId: number,
  options?: UseQueryOptions<OnlineBookingClnicType[], Error>
) => {
  const { token } = useSession();

  return useQuery<OnlineBookingClnicType[], Error>(
    ["onlineBooking", clinicId],
    () => fetchOnlineBooking(clinicId, token!),
    {
      ...options,
      enabled: !!token && !!clinicId,
    }
  );
};

const useCreateOnlineBookingMutation = (
  options?: UseMutationOptions<CreateOnlineBookingType, AxiosError, unknown>
) => {
  const { token } = useSession();

  return useMutation<
    CreateOnlineBookingType,
    AxiosError,
    { clinicId: number; name: string; baseUrl: string }
  >(
    ({ clinicId, name, baseUrl }) =>
      createOnlineBooking(clinicId, name, baseUrl, token!),
    options
  );
};

const useUpdateOnlineBookingMutation = (
  options?: UseMutationOptions<any, AxiosError, unknown>
) => {
  const { token } = useSession();

  return useMutation<
    any,
    AxiosError,
    { onlineBookingId: number; updatedData: Partial<OnlineBooking> }
  >(
    ({ onlineBookingId, updatedData }) =>
      updateOnlineBooking(onlineBookingId, updatedData, token!),
    options
  );
};

const useDeleteOnlineBookingMutation = (
  options?: UseMutationOptions<
    DeleteUserResponse,
    AxiosError,
    { onlineBookingId: number }
  >
) => {
  const { token } = useSession();

  return useMutation<
    DeleteUserResponse,
    AxiosError,
    { onlineBookingId: number }
  >(
    ({ onlineBookingId }) => deleteOnlineBooking(onlineBookingId, token!),
    options
  );
};

const useSingleOnlineBookingQuery = (
  onlineBookingId: number,
  options?: UseQueryOptions<FetchSingleOnlineBookingType, Error>
) => {
  const { token } = useSession();

  return useQuery<FetchSingleOnlineBookingType, Error>(
    ["singleOnlineBooking", onlineBookingId],
    () => fetchSingleOnlineBooking(onlineBookingId, token!),
    {
      ...options,
      enabled: !!token && !!onlineBookingId,
    }
  );
};

export {
  useOnlineBookingQuery,
  useCreateOnlineBookingMutation,
  useDeleteOnlineBookingMutation,
  useSingleOnlineBookingQuery,
  useUpdateOnlineBookingMutation,
};
