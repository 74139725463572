import { Users, CaretRight } from "@phosphor-icons/react";
import { Clinic } from "../../../types/models/company";
import IconCountButton from "../IconCountButton/IconCountButton";

type ClinicRowProps = {
  clinic: Clinic;
};

const ClinicRow: React.FC<ClinicRowProps> = ({ clinic }) => {
  return (
    <div
      key={clinic.id}
      className="tw-grid tw-grid-cols-3 tw-py-6 tw-border-b-[2px] tw-border-dental-neutral-N8 max-md:tw-grid-cols-3"
    >
      <div>
        <p className="tw-text-base tw-text">{clinic.name}</p>
        <span className="tw-text-sm tw-text-dental-neutral-N4">
          {clinic.address}, {clinic.city}
        </span>
      </div>
      <div className="tw-flex tw-justify-center tw-items-center">
        <IconCountButton
          icon={<Users size={16} weight="fill" />}
          text="user"
          count={clinic.users_count}
        />
      </div>
      <div className="tw-flex tw-justify-end tw-items-center tw-pr-2 tw-col-span-1">
        <CaretRight size={24} />
      </div>
    </div>
  );
};

export default ClinicRow;
