import { useState } from "react";
import { Button } from "../../components/design-systems/Button/Button";
import { useSession } from "../../context/session";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { useTranslation } from "react-i18next";
import { PageWrapper } from "../../components/PageWrapper";
import { StepDialog } from "../../components/design-systems/Dialog/StepDialog/StepDialog";
import EditProfile from "./EditProfile/EditProfile";
import { Pen, User } from "@phosphor-icons/react";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { Description } from "../../components/design-systems/Description/Description";
import { apiUrl } from "../../config";

const Profile = () => {
  const { user } = useSession();
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);

  if (!user) {
    return <Loading />;
  }

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <PageWithHeroWrapper
      title="Profile"
      text="Manage your own Dentli profile"
      IconComponent={User}
      actions={
        <>
          <Button prefix={<Pen />} onClick={openDialog}>
            Edit profile
          </Button>
        </>
      }
    >
      <div className="tw-space-y-8">
        <div>
          <Description
            theme="defaultFlip"
            title="Profile image"
            titleSize="sm"
            titleWeight="normal"
          />
          {user.photo ? (
            <img
              src={`${apiUrl}/${user.photo}`}
              alt="Profile"
              className="tw-object-cover tw-rounded-[32px] tw-border-2 tw-border-dental-primary-P2"
              style={{ width: "50px", height: "50px" }}
            />
          ) : (
            <User size={50} />
          )}
        </div>
        <Description
          theme="defaultFlip"
          title={t("label.name")}
          titleSize="sm"
          titleWeight="normal"
          description={user.name}
          descriptionSize="base"
          descriptionWeight="medium"
        />
        <Description
          theme="defaultFlip"
          title={t("label.email")}
          titleSize="sm"
          titleWeight="normal"
          description={user.email}
          descriptionSize="base"
          descriptionWeight="medium"
        />
        <Description
          theme="defaultFlip"
          title={t("label.phone")}
          titleSize="sm"
          titleWeight="normal"
          description={user.phone}
          descriptionSize="base"
          descriptionWeight="medium"
        />
      </div>
      {isDialogOpen && (
        <StepDialog
          totalSteps={1}
          currentStep={1}
          actions={
            <>
              <Button color="primary" variant="outline" onClick={closeDialog}>
                Cancel
              </Button>
            </>
          }
          isLoading={false}
          onClose={closeDialog}
        >
          <PageWrapper className="tw-flex tw-mx-auto tw-h-full tw-max-w-xl tw-flex-col tw-px-0 tw-pb-12 tw-pt-4">
            <EditProfile />
          </PageWrapper>
        </StepDialog>
      )}
    </PageWithHeroWrapper>
  );
};

export default Profile;
