import {
  useActionStatusableDetailQuery,
  useGetProspectsQuery,
  useCreateReminderMutation,
  useUpdateActionStatusEventMutation,
  useUpdateActionStatusProgressMutation,
  useDeleteReminderMutation,
} from "../../api/rest/communication/communication";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { notify } from "../../components/design-systems/Alert/notify";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import {
  CaretDown,
  GenderFemale,
  GenderMale,
  Megaphone,
  Plus,
  Trash,
} from "@phosphor-icons/react";
import { useParams } from "react-router-dom";
import { Description } from "../../components/design-systems/Description/Description";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "../../components/AddEmployee/AddEmployee";

import { useState } from "react";
import { Button } from "../../components/design-systems/Button";
import PhoneIcon from "../../components/icons/Phone";
import EmailIcon from "../../components/icons/EmailIcon";
import SmsIcon from "../../components/icons/SmsIcon";
import CommentIcon from "../../components/icons/CommentIcon";
import EventDialog from "./Dialogs/EventDialog";
import ReminderDialog from "./Dialogs/ReminderDialog";
// import StatusDialog from "./Dialogs/StatusDialog";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import ProspectsAccordion from "./ProspectsAccordion";
import { Menu } from "../../components/design-systems/Menu/Menu";

type EventFormData = {
  event_id: any;
  event_content: string;
};
type ReminderFormData = {
  actionstatus_id: number;
  reminder_date: any;
  reminder_comment: string;
};

type ProgressFormData = {
  progress_id: number;
};

const ProspectsPage = () => {
  const queryClient = useQueryClient();

  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [isReminderDialogOpen, setIsReminderDialogOpen] = useState(false);

  const { clinicId, patientId } = useParams<{
    clinicId: string;
    patientId: string;
  }>();

  const actionStatusQuery = useActionStatusableDetailQuery(
    Number(clinicId),
    "patient",
    Number(patientId)
  );

  const actionStatusData = actionStatusQuery.data;
  const patientData = actionStatusData?.patient;

  const findPropspectsQuery = useGetProspectsQuery(Number(clinicId));
  const findPropsectsData = findPropspectsQuery.data;

  const actionstatusEventTypes = actionStatusData?.AvailableEventTypes.map(
    (eventType: { id: number; label: string }) => ({
      value: eventType.label,
      label: eventType.label,
      id: eventType.id,
    })
  );

  const actionstatusProgressTypes =
    actionStatusData?.AvailableProgressTypes.map((progressType) => ({
      id: progressType.id,
      value: progressType.label,
      label: progressType.label,
    }));

  const {
    mutate: createReminderMutation,
    isLoading: createReminderMutationLoading,
  } = useCreateReminderMutation(
    Number(clinicId),
    "patient",
    actionStatusData?.patient?.id!,
    {
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["actionStatusableDetail"]);
        notify("You have successfully created a reminder");
      },
    }
  );

  const {
    mutate: updateActionstatusMutation,
    isLoading: updateActionstatusMutationloading,
  } = useUpdateActionStatusEventMutation(
    Number(clinicId),
    "patient",
    actionStatusData?.patient?.id!,
    {
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
      onSuccess: () => {
        notify("You have successfully added an action");
        queryClient.invalidateQueries(["actionStatusableDetail"]);
      },
    }
  );

  const {
    mutate: updateActionStatusProgressMutation,
    // isLoading: updateActionStatusProgressMutationLoading,
  } = useUpdateActionStatusProgressMutation(
    Number(clinicId),
    "patient",
    actionStatusData?.patient?.id!,
    {
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["actionStatusableDetail"]);
        notify("You have successfully updated the status");
      },
    }
  );

  const {
    mutate: deleteReminderMutation,
    // isLoading: deleteReminderMutationLoading,
  } = useDeleteReminderMutation(
    Number(clinicId),
    "patient",
    actionStatusData?.patient?.id!,
    {
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
      onSuccess: () => {
        notify("You have successfully cleared a reminder");
        queryClient.invalidateQueries(["actionStatusableDetail"]);
      },
    }
  );

  if (actionStatusQuery.isLoading || !actionStatusData) {
    return <Loading />;
  }

  if (actionStatusQuery.isError) {
    notify("Failed to fetch action status data", { type: "error" });
    return null;
  }

  if (!clinicId) {
    return null;
  }

  const openEventDialog = () => setIsEventDialogOpen(true);
  const closeEventDialog = () => setIsEventDialogOpen(false);

  const openReminderDialog = () => setIsReminderDialogOpen(true);
  const closeReminderDialog = () => setIsReminderDialogOpen(false);

  const returnIcon = (event_type_id: number) => {
    if (event_type_id === 1) {
      return <PhoneIcon className="tw-text-white" size={24} />;
    } else if (event_type_id === 2) {
      return <EmailIcon className="tw-text-white" size={24} />;
    } else if (event_type_id === 3) {
      return <SmsIcon className="tw-text-white" size={24} />;
    } else {
      return <CommentIcon className="tw-text-white" size={24} />;
    }
  };

  const renderProgress = (prospect: any) => {
    if (prospect.actionstatus?.latest_progress?.type?.label) {
      return `${prospect.actionstatus?.latest_progress?.type?.label}`;
    } else {
      return "";
    }
  };

  const handleReminderSubmit = (data: ReminderFormData) => {
    createReminderMutation(data);
    closeReminderDialog();
  };

  const handleActionStatusSubmit = (data: EventFormData) => {
    updateActionstatusMutation(data);
    closeEventDialog();
  };

  const handleStatusChange = (progressId: number) => {
    updateActionStatusProgressMutation({ progress_id: progressId });
  };

  const tabs = [
    {
      title: "Reminders",
      content: (
        <>
          <div className="tw-grid tw-grid-cols-[0.5fr,1fr,1fr,auto] tw-gap-4 tw-mb-2 tw-pb-2 tw-text-sm tw-border-b">
            <div>Date</div>
            <div>Comment</div>
            <div>Status</div>
            <div className="tw-w-6"></div>
            {/* Empty header for the cross icon */}
          </div>
          <div className="tw-space-y-6">
            {patientData?.actionstatus?.reminders ? (
              patientData?.actionstatus?.reminders
                ?.filter((reminder) => !reminder.done) // Filter reminders where done is false
                .map((reminder) => (
                  <div
                    key={reminder.id}
                    className="tw-grid tw-grid-cols-[0.5fr,1fr,1fr,auto] tw-gap-4"
                  >
                    <Description
                      title={reminder.reminder_date}
                      titleSize="sm"
                      titleWeight="medium"
                    />
                    <Description
                      title={reminder.reminder_comment}
                      titleSize="sm"
                      titleWeight="medium"
                    />
                    <Description
                      title={reminder.done ? "Done" : "Pending"}
                      titleSize="sm"
                      titleWeight="medium"
                    />
                    <div
                      onClick={() =>
                        deleteReminderMutation({ reminder_id: reminder.id })
                      }
                    >
                      <Trash
                        size={24}
                        className="tw-text-dental-semantic-S1 tw-cursor-pointer"
                      />
                    </div>
                  </div>
                ))
            ) : (
              <p>No reminders found</p>
            )}
          </div>
        </>
      ),
      action: (
        <Button onClick={openReminderDialog}>
          <Plus />
        </Button>
      ),
    },
    {
      title: "Actions",
      content: (
        <>
          <div className="tw-grid tw-grid-cols-[0.5fr,1fr,1fr,auto] tw-gap-4 tw-mb-2 tw-pb-2 tw-text-sm tw-border-b">
            <div>Type</div>
            <div>Content</div>
            <div>Date</div>
            {/* <div></div> Empty header for the cross icon */}
          </div>

          <div className="tw-space-y-6">
            {patientData?.actionstatus?.events.length &&
            patientData?.actionstatus?.events.length > 0 ? (
              patientData?.actionstatus?.events.map((event: any) => (
                <div
                  key={event.id}
                  className="tw-grid tw-grid-cols-[0.5fr,1fr,1fr,auto] tw-gap-4"
                >
                  <Description
                    title={returnIcon(event.actionstatus_event_type_id)}
                    titleSize="sm"
                    titleWeight="medium"
                  />
                  <Description
                    title={event.content}
                    titleSize="sm"
                    titleWeight="medium"
                  />
                  <Description
                    title={new Date(event.created_at).toLocaleDateString()}
                    titleSize="sm"
                    titleWeight="medium"
                  />
                  {/* <div>
                    <Trash size={24} className="tw-text-dental-semantic-S1" />
                  </div> */}
                </div>
              ))
            ) : (
              <p>No actions found.</p>
            )}
          </div>
        </>
      ),
      action: (
        <Button onClick={openEventDialog}>
          <Plus />
        </Button>
      ),
    },
  ];

  console.log({
    actionStatusData,
    patientData,
    findPropspectsQuery,
    findPropsectsData,
    actionstatusProgressTypes,
  });

  return (
    <PageWithHeroWrapper
      title="Prospect"
      text="Communication"
      IconComponent={Megaphone}
      showBackButton={`/communication/${clinicId}`}
      actions={
        <>
          <Menu>
            <Menu.Button
              isDropdown={false}
              className="tw-bg-transparent tw-border-none"
            >
              <button className="tw-bg-purple-200 tw-text-left tw-px-3 tw-py-2 tw-flex tw-items-center tw-gap-4 tw-rounded-lg">
                <div className="tw-flex tw-flex-col tw-text-black">
                  <p className="tw-text-xs tw-font-semibold">Status</p>
                  <p className="tw-text-base tw-font-medium">
                    {renderProgress(patientData)}
                  </p>
                </div>
                <CaretDown size={24} className="tw-text-black" />
              </button>
            </Menu.Button>
            <Menu.Items align="bottomRight">
              {actionstatusProgressTypes?.map((progress) => (
                <Menu.Item
                  key={progress.id}
                  onClick={() => handleStatusChange(progress.id)}
                >
                  {progress.label}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </>
      }
    >
      <section className="tw-flex tw-gap-4 max-md:tw-flex-col">
        <div className="tw-w-1/2 max-md:tw-w-full">
          <div className="tw-bg-blue-100 tw-p-4 tw-rounded-xl tw-gap-4 tw-grid tw-grid-cols-3 max-md:tw-grid-cols-2 tw-h-fit">
            <Description
              title="ID"
              titleSize="lg"
              titleWeight="semibold"
              description={actionStatusData.patient.id}
            />
            <Description
              title="Journal ID"
              titleSize="lg"
              titleWeight="semibold"
              description={actionStatusData.patient.journal_pt_id}
            />
            <Description
              title="DOB"
              titleSize="lg"
              titleWeight="semibold"
              description={actionStatusData.patient.dob}
            />
            <Description
              title="Gender"
              titleSize="lg"
              titleWeight="semibold"
              description={
                actionStatusData?.patient.gender === "F" ? (
                  <GenderFemale className="tw-mt-1" size={30} weight="bold" />
                ) : actionStatusData?.patient.gender === "M" ? (
                  <GenderMale className="tw-mt-1" size={30} weight="bold" />
                ) : (
                  "N/A"
                )
              }
            />
            <Description
              title="Journal Active"
              titleSize="lg"
              titleWeight="semibold"
              description={
                actionStatusData.patient.journal_active === 1 ? "Yes" : "No"
              }
            />
          </div>
          <ProspectsAccordion actionStatusData={actionStatusData} />
        </div>

        <div className="tw-w-1/2 max-md:tw-w-full">
          <Tab.Group>
            <div className="tw-bg-white tw-shadow-2xl tw-border tw-rounded-xl">
              <Tab.List className="tw-flex tw-overflow-x-auto tw-gap-2 tw-ml-8 tw-mt-4 tw-rounded-lg">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.title}
                    className={({ selected }) =>
                      clsx(
                        "tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium",
                        "tw-rounded-lg tw-focus:outline-none",
                        selected
                          ? "tw-bg-dental-primary-P2 tw-text-white"
                          : "tw-bg-gray-300 tw-text-primary "
                      )
                    }
                  >
                    {tab.title}
                  </Tab>
                ))}
              </Tab.List>

              <Tab.Panels className="tw-mt-2">
                {tabs.map((tab, index) => (
                  <Tab.Panel
                    key={index}
                    className="tw-px-12 tw-py-6 tw-h-96 tw-overflow-y-auto"
                  >
                    <div className="tw-flex tw-justify-between tw-items-center tw-pb-6">
                      <h2>{tab.title}</h2>
                      {tab.action}
                    </div>
                    {tab.content}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </section>

      <EventDialog
        isOpen={isEventDialogOpen}
        onClose={closeEventDialog}
        onSubmit={handleActionStatusSubmit}
        actionstatusEventTypes={actionstatusEventTypes}
        isLoading={updateActionstatusMutationloading}
      />
      <ReminderDialog
        isOpen={isReminderDialogOpen}
        onClose={closeReminderDialog}
        onSubmit={handleReminderSubmit}
        isLoading={createReminderMutationLoading}
      />
    </PageWithHeroWrapper>
  );
};

export default ProspectsPage;
export type { ProgressFormData, EventFormData, ReminderFormData };
