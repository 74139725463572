import { Navigate, Outlet } from "react-router-dom";
import { useSession } from "../context/session";

const Private = () => {
  const { isAuthenticated } = useSession();

  return isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
};

export default Private;
