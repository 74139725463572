import React from "react";
import { PageWrapper } from "../../PageWrapper";
import HeroBlock from "../HeroBlock/HeroBlok";
import { IconProps } from "@phosphor-icons/react";
import clsx from "clsx";

type PageWithHeroWrapperProps = {
  title: string;
  text: string;
  IconComponent?: React.ElementType<IconProps>;
  children: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
  submenu?: React.ReactNode;
  showBackButton?: boolean | string;
};

const PageWithHeroWrapper: React.FC<PageWithHeroWrapperProps> = ({
  title,
  text,
  IconComponent,
  children,
  className,
  actions,
  submenu,
  showBackButton = false,
}) => {
  return (
    <div className={clsx("tw-flex tw-flex-col", className)}>
      <section className="tw-px-3 tw-flex tw-flex-col tw-items-center md:tw-flex-row tw-justify-between tw-py-4 print:tw-p-0 md:tw-px-16 md:tw-pt-8">
        <HeroBlock
          title={title}
          text={text}
          IconComponent={IconComponent}
          actions={actions}
          showBackButton={showBackButton}
        />
      </section>
      {submenu && (
        <section
          id="sub-menu"
          className="tw-flex tw-justify-center tw-gap-4 tw-py-2"
        >
          {submenu}
        </section>
      )}
      <PageWrapper>
        <div>{children}</div>
      </PageWrapper>
    </div>
  );
};

export default PageWithHeroWrapper;
