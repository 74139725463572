import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Gear, Users } from "@phosphor-icons/react";

type ActiveTabType = "users" | "settings";

type MenuProps = {
  activeTab: ActiveTabType;
  clinicId: string;
};

type MenuLink = {
  label: string;
  tab: ActiveTabType;
  to: string;
  logo: React.ReactNode;
};

const SingleClinicMenu: React.FC<MenuProps> = ({ activeTab, clinicId }) => {
  const { t } = useTranslation();

  const MenuLinks: MenuLink[] = [
    {
      label: t("label.users"),
      tab: "users",
      to: `/company/clinic/${clinicId}/users`,
      logo: <Users size={28} />,
    },
    {
      label: t("label.settings"),
      tab: "settings",
      to: `/company/clinic/${clinicId}/settings`,
      logo: <Gear size={28} />,
    },
  ];

  return (
    <div className="tw-flex tw-gap-12 tw-justify-center">
      {MenuLinks.map(({ label, tab, to, logo }) => (
        <NavLink
          key={tab}
          to={to}
          className={`tw-cursor-pointer tw-flex tw-flex-col tw-items-center ${
            activeTab === tab ? "tw-text-dental-primary-P2" : ""
          }`}
        >
          <div className="tw-flex tw-items-center tw-gap-2 tw-pb-3">
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-1">
              {logo}
              <p
                className={clsx("tw-font-semibold", {
                  "tw-text-dental-neutral-N1": activeTab === tab,
                  "tw-text-dental-neutral-N6": activeTab !== tab,
                })}
              >
                {label}
              </p>
            </div>
          </div>
          {activeTab === tab && (
            <div
              className="tw-bg-dental-primary-P1 tw-rounded-sm"
              style={{ width: "30px", height: "3px" }}
            />
          )}
        </NavLink>
      ))}
    </div>
  );
};

export default SingleClinicMenu;
