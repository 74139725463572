import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Trash, UploadSimple } from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import {
  FormGroup,
  FormLabel,
  FormInput,
  FormError,
} from "../../../components/design-systems/FormGroup/FormGroup";
import { notify } from "../../../components/design-systems/Alert/notify";
import { apiUrl } from "../../../config";
import {
  useOrganisationsQuery,
  useUpdateOrganisationMutation,
} from "../../../api/rest/organisation";
import { StepDialog } from "../../../components/design-systems/Dialog/StepDialog/StepDialog";
import { PageWrapper } from "../../../components/PageWrapper";
import { Button } from "../../../components/design-systems/Button/Button";

type FormValues = {
  id: number;
  name: string;
  address: string;
  city?: string;
  zip?: string;
  logo?: string;
};

const EditCompanyInformationDialog = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const organisationQuery = useOrganisationsQuery();
  const organisation = organisationQuery.data || [];

  const organisationLogo = organisation[0]?.logo;
  const organisationId = organisation[0]?.id;

  const [imagePreview, setImagePreview] = useState<string | null>(
    organisationLogo ? `${apiUrl}/${organisationLogo}` : null
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {
    mutate: updateOrganisationMutation,
    isLoading: updateOrganisationLoading,
  } = useUpdateOrganisationMutation();

  const initialData: FormValues = {
    id: organisation[0]?.id || 0,
    name: organisation[0]?.name || "",
    address: organisation[0]?.address || "",
    city: organisation[0]?.city || "",
    zip: organisation[0]?.zip || "",
    logo: organisationLogo || "",
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: initialData,
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setImagePreview(base64String);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onSubmit = async (data: FormValues) => {
    let formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("id", data.id.toString());
    formData.append("name", data.name);
    formData.append("address", data.address);
    formData.append("city", data.city || "");
    formData.append("zip", data.zip || "");

    if (selectedFile) {
      formData.append("logo", selectedFile);
    }

    if (organisationId) {
      updateOrganisationMutation(
        {
          organisationId,
          formData,
        },
        {
          onSuccess: () => {
            notify("You have successfully updated the organisation");
            queryClient.invalidateQueries();
            closeDialog();
          },
          onError: (error) => {
            notify("There was an error in the update", { type: "error" });
            console.error("Mutation error:", error);
          },
        }
      );
    }
  };

  const closeDialog = () => {
    onClose();
    setImagePreview(`${apiUrl}/${organisationLogo}`);
    reset(initialData);
  };

  return (
    <StepDialog
      totalSteps={1}
      currentStep={1}
      actions={
        <>
          <Button color="primary" variant="outline" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={updateOrganisationLoading}
          >
            Save
          </Button>
        </>
      }
      isLoading={false}
      onClose={closeDialog}
    >
      <PageWrapper className="tw-flex tw-mx-auto tw-h-full tw-max-w-xl tw-flex-col tw-px-0 tw-pb-12 tw-pt-4">
        <div className="tw-space-y-6 tw-w-full tw-max-w-lg">
          <h1 className="tw-font-light">Edit company information</h1>
          <FormGroup>
            <Controller
              name="logo"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <div className="tw-bg-dental-primary-P6 tw-p-4 tw-rounded-lg">
                    <div
                      className="tw-relative tw-flex tw-flex-col tw-gap-2 tw-py-6 tw-items-center tw-bg-dental-neutral-N10 tw-border-[1px] tw-border-dashed tw-border-dental-neutral-N6 tw-rounded-[4px] tw-cursor-pointer"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      {imagePreview ? (
                        <>
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                            }}
                          />
                          <Trash
                            onClick={handleRemoveImage}
                            size={20}
                            className="tw-text-dental-semantic-S1 tw-absolute tw-bottom-3 tw-right-3"
                          />
                        </>
                      ) : (
                        <>
                          <UploadSimple size={32} weight="bold" />
                          <p className="tw-text-dental-neutral-N2 tw-font-medium">
                            {t("finishSetup.uploadLogo")}
                          </p>
                          <p className="tw-text-xs tw-text-dental-neutral-N5">
                            {t("label.maxFileSize")}
                          </p>
                        </>
                      )}
                      <input
                        id="logo"
                        type="file"
                        // accept="image/*"
                        ref={fileInputRef}
                        onChange={(e) => {
                          handleImageChange(e);
                          onChange(e.target.files);
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="name">{t("label.name")}</FormLabel>
            <FormInput
              id="name"
              {...register("name", { required: "Company name is required" })}
            />
            <FormError>{errors.name?.message}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="address">{t("label.streetAddress")}</FormLabel>
            <FormInput
              id="address"
              {...register("address", { required: "Address is required" })}
            />
            <FormError>{errors.address?.message}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="city">{t("label.city")}</FormLabel>
            <FormInput
              id="city"
              {...register("city", { required: "City is required" })}
            />
            <FormError>{errors.city?.message}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="zip">{t("label.zipCode")}</FormLabel>
            <FormInput
              id="zip"
              {...register("zip", { required: "Zip code is required" })}
            />
            <FormError>{errors.zip?.message}</FormError>
          </FormGroup>
        </div>
      </PageWrapper>
    </StepDialog>
  );
};

export default EditCompanyInformationDialog;
