import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Private from "./Private";
import MainLayout from "./MainLayout";
import NoNavbarLayout from "./NoNavbarLayout";
import Signup from "../Pages/Signup";
import Insights from "../Pages/Insights";
import SingleClinic from "../Pages/SingleClinic/SingleClinic";
import Profile from "../Pages/Profile/Profile";
import Agreements from "../Pages/Agreements";
import CreateAccount from "../Pages/CreateAccount";
import { AuthRedirect } from "./AuthRedirect";
import ShortlinkRedirect from "../Pages/ShortLinkRedirect";
import { BiReportPage } from "../Pages/BiReportPage";
import Company from "../Pages/Company/Company";
import ResetPassword from "../Pages/ResetPassword";
import OnlineBooking from "../Pages/OnlineBooking/OnlineBooking";
import OnlineBookingSettings from "../Pages/OnlineBookingSettings/OnlineBookingSettings";
import Communication from "../Pages/Communication/Communication";
import { SessionProvider } from "../context/session";
import CompanyClinics from "../Pages/Company/CompanyClinics";
import CompanyUsers from "../Pages/Company/CompanyUsers";
import ClinicCommunication from "../Pages/ClinicCommunication/ClinicCommunication";
import SingleClinicUsers from "../Pages/SingleClinic/SingleClinicUsers";
import OnlineBookingSelectClinic from "../Pages/OnlineBooking/OnlineBookingSelectClinic";
import CompanySettings from "../Pages/Company/CompanySettings/CompanySettings";
import OfferPipeline from "../Pages/OfferPipeline/OfferPipeline";
import SingleClinicSettings from "../Pages/SingleClinic/SingeClinicSettings/SingleClinicSettings";
import ProspectsPage from "../Pages/ProspectsPage/ProspectsPage";

const Routers = () => {
  return (
    <Router>
      <SessionProvider>
        <Routes>
          <Route element={<Private />}>
            <Route element={<MainLayout />}>
              <Route path="/company" element={<Company />}>
                <Route path="clinics" element={<CompanyClinics />} />
                <Route path="users" element={<CompanyUsers />} />
                <Route path="settings" element={<CompanySettings />} />
              </Route>
              <Route path="/profile" element={<Profile />} />
              <Route path="/agreements" element={<Agreements />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/communication" element={<Communication />} />
              <Route path="/offer-pipeline" element={<OfferPipeline />} />
              <Route
                path="/communication/:clinicId"
                element={<ClinicCommunication />}
              />
              <Route
                path="/communication/:clinicId/:patientId"
                element={<ProspectsPage />}
              />
              <Route
                path="/company/clinic/:clinicId/*"
                element={<SingleClinic />}
              >
                <Route path="users" element={<SingleClinicUsers />} />
                <Route path="settings" element={<SingleClinicSettings />} />
              </Route>
              <Route path="/booking" element={<OnlineBookingSelectClinic />} />
              <Route path="/booking/:clinicId" element={<OnlineBooking />} />
            </Route>
            <Route element={<NoNavbarLayout />}>
              <Route
                path="/booking/settings/:clinicId/:bookingId"
                element={<OnlineBookingSettings />}
              />
            </Route>
          </Route>
          <Route path="/" element={<AuthRedirect />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/s/:shortlink" element={<ShortlinkRedirect />} />
          <Route path="/report/bi/:reportId" element={<BiReportPage />} />
        </Routes>
      </SessionProvider>
    </Router>
  );
};

export default Routers;
