import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../components/icons/Dashboard";
import LogoutIcon from "../../components/icons/LogoutIcon";
import { useSession } from "../../context/session";
import { CaretDown, FileText, Storefront, User } from "@phosphor-icons/react";
import IconDescription from "../../components/design-systems/IconDescription/IconDescription";
import { ReactComponent as LogoSvg } from "../../img/logo-white.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useCurrentUserQuery } from "../../api/rest/company";
import ProfileImagePlaceholder from "../design-systems/ProfileImagePlaceholder/ProfileImagePlaceholder";
import { apiUrl } from "../../config";
import { useOrganisationsQuery } from "../../api/rest/organisation";
import { useClinicsQuery } from "../../api/rest/clinic";
import { useState } from "react";
import { Tooltip } from "../design-systems/Tooltip";

const Navbar = () => {
  const { user, onSignout, productAccess } = useSession();
  const i18n = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const clinicsQuery = useClinicsQuery();
  const organisationQuery = useOrganisationsQuery();
  const clinics = clinicsQuery.data || [];
  const organisation = organisationQuery.data || [];
  const userQuery = useCurrentUserQuery(user!.id);
  const userData = userQuery.data;

  const [actionsIsOpen, setActionsIsOpen] = useState(true);
  const [settingsIsOpen, setSettingsIsOpen] = useState(true);

  if (!userData) {
    return null;
  }

  const isIndependentClinic = organisation.length === 0 && clinics.length > 0;

  const determineBookingPath = () => {
    if (clinics.length === 1 || isIndependentClinic) {
      return `/booking/${clinics[0]!.id}`;
    }
    return "/booking";
  };

  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  const getColor = (path: string) => {
    return isActive(path) ? "#7A69C0" : "#AAAAAA";
  };

  const getTextClass = (path: string) => {
    return isActive(path)
      ? "tw-text-dental-neutral-N9 tw-font-semibold"
      : "tw-text-dental-neutral-N6";
  };

  const handleLogout = () => {
    onSignout();
    navigate("/");
  };

  const hasCommunicationAccess = productAccess?.some(
    (product) => product.name === "Communication"
  );

  const hasOnlineBookingAccess = productAccess?.some(
    (product) => product.name === "Online Booking"
  );

  console.log({ productAccess });

  return (
    <div
      className="tw-bg-dental-neutral-900 tw-rounded-lg tw-m-2 tw-p-2 max-lg:tw-hidden"
      style={{
        width: "260px",
        minWidth: "260px",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div
        className="tw-text-white tw-flex tw-flex-col tw-justify-between tw-px-7 tw-py-8 max-lg:tw-hidden"
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        <div>
          <div className="tw-flex tw-flex-col tw-items-start tw-text-center tw-mb-10 tw-mt-4">
            <LogoSvg className="tw-w-24 tw-mb-10" />
            <div className="tw-flex tw-items-center tw-text-left tw-gap-2">
              {userData?.photo ? (
                <img
                  src={`${apiUrl}/${userData.photo}`}
                  alt="Profile"
                  className="tw-object-cover tw-rounded-[32px] tw-border-2 tw-border-dental-primary-P2"
                  style={{ width: "50px", height: "50px" }}
                />
              ) : (
                <ProfileImagePlaceholder className="tw-mb-6" />
              )}
              <p className="tw-text-md tw-font-medium">{userData.name}</p>
            </div>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-6 tw-py-6 tw-mb-20 ">
            <Link to={`/insights`}>
              <IconDescription
                Icon={
                  <DashboardIcon
                    size={20}
                    className={clsx({
                      "tw-text-dental-primary-P2": isActive("/insights"),
                    })}
                  />
                }
                description={i18n.t("label.insights")}
                className={clsx(
                  "tw-text-dental-neutral-N6 tw-py-2",
                  getTextClass("/insights")
                )}
              />
            </Link>
            <hr className="tw-border-b-[1px] tw-border-dental-neutral-N4" />

            <section id="actions-navbar">
              <div
                className="tw-flex tw-justify-between tw-items-center"
                onClick={() => setActionsIsOpen((prev) => !prev)}
              >
                <p className="tw-font-bold tw-text-xs tw-text-dental-neutral-N5 tw-pb-2">
                  Actions
                </p>
                <div
                  className={`tw-transition-transform tw-duration-500 ${
                    actionsIsOpen ? "tw-rotate-180" : ""
                  }`}
                >
                  <CaretDown className="tw-w-5 tw-h-5 tw-text-dental-neutral-N5" />
                </div>
              </div>
              <div
                className={`tw-overflow-hidden tw-transition-max-height tw-duration-500 tw-flex tw-flex-col tw-gap-4 ${
                  actionsIsOpen ? "tw-max-h-[2000px] " : "tw-max-h-0"
                }`}
              >
                {hasCommunicationAccess ? (
                  <Link to={`/communication`}>
                    <IconDescription
                      Icon={
                        <User
                          size={20}
                          weight="fill"
                          color={getColor("/communication")}
                        />
                      }
                      description={i18n.t("label.communication")}
                      className={clsx(
                        "tw-text-dental-neutral-N6 tw-py-2",
                        getTextClass("/communication")
                      )}
                    />
                  </Link>
                ) : (
                  <Tooltip
                    placement="bottom-start"
                    content="You don't have access to the Communication products"
                  >
                    <div className="tw-opacity-50 tw-pointer-events-none">
                      <IconDescription
                        Icon={
                          <User
                            size={20}
                            weight="fill"
                            color={getColor("/communication")}
                          />
                        }
                        description={i18n.t("label.communication")}
                        className="tw-text-dental-neutral-N6 tw-py-2"
                      />
                    </div>
                  </Tooltip>
                )}

                {hasOnlineBookingAccess ? (
                  <Link to={determineBookingPath()}>
                    <IconDescription
                      Icon={
                        <Storefront
                          size={20}
                          weight="fill"
                          color={getColor("/booking")}
                        />
                      }
                      description={i18n.t("label.onlineBooking")}
                      className={clsx(
                        "tw-text-dental-neutral-N6 tw-py-2",
                        getTextClass("/booking")
                      )}
                    />
                  </Link>
                ) : (
                  <Tooltip
                    placement="bottom-start"
                    content="You don't have access to the Online Booking products"
                  >
                    <div className="tw-opacity-50 tw-pointer-events-none">
                      <IconDescription
                        Icon={
                          <Storefront
                            size={20}
                            weight="fill"
                            color={getColor("/booking")}
                          />
                        }
                        description={i18n.t("label.onlineBooking")}
                        className="tw-text-dental-neutral-N6 tw-py-2"
                      />
                    </div>
                  </Tooltip>
                )}
                <Link to={`/offer-pipeline`}>
                  <IconDescription
                    Icon={
                      <FileText
                        size={20}
                        weight="fill"
                        color={getColor("/offer-pipeline")}
                      />
                    }
                    description={i18n.t("label.offerPipeline")}
                    className={clsx(
                      "tw-text-dental-neutral-N6 tw-py-2",
                      getTextClass("/offer-pipeline")
                    )}
                  />
                </Link>
              </div>
            </section>

            <section id="settings-navbar">
              <div
                className="tw-flex tw-justify-between tw-items-center"
                onClick={() => setSettingsIsOpen((prev) => !prev)}
              >
                <p className="tw-font-bold tw-text-xs tw-text-dental-neutral-N5 tw-pb-2">
                  Settings
                </p>
                <div
                  className={`tw-transition-transform tw-duration-500 ${
                    settingsIsOpen ? "tw-rotate-180" : ""
                  }`}
                >
                  <CaretDown className="tw-w-5 tw-h-5 tw-text-dental-neutral-N5" />
                </div>
              </div>
              <div
                className={`tw-overflow-hidden tw-transition-max-height tw-duration-500 tw-flex tw-flex-col tw-gap-4 ${
                  settingsIsOpen ? "tw-max-h-[2000px] " : "tw-max-h-0"
                }`}
              >
                <Link to={`/company/clinics`}>
                  <IconDescription
                    Icon={
                      <Storefront
                        size={20}
                        weight="fill"
                        color={getColor("/company")}
                      />
                    }
                    description={i18n.t("label.company")}
                    className={clsx(
                      "tw-text-dental-neutral-N6 tw-py-2",
                      getTextClass("/company")
                    )}
                  />
                </Link>

                <Link to={`/profile`}>
                  <IconDescription
                    Icon={
                      <User
                        size={20}
                        weight="fill"
                        color={getColor("/profile")}
                      />
                    }
                    description={i18n.t("label.profile")}
                    className={clsx(
                      "tw-text-dental-neutral-N6 tw-py-2",
                      getTextClass("/profile")
                    )}
                  />
                </Link>
                <Link to={`/agreements`}>
                  <IconDescription
                    Icon={
                      <FileText
                        size={20}
                        weight="fill"
                        color={getColor("/agreements")}
                      />
                    }
                    description={i18n.t("label.agreements")}
                    className={clsx(
                      "tw-text-dental-neutral-N6 tw-py-2",
                      getTextClass("/agreements")
                    )}
                  />
                </Link>
              </div>
            </section>
          </div>
        </div>

        <div className="tw-border-t-[1px] tw-border-dental-primary-P1 tw-pt-1">
          <IconDescription
            Icon={<LogoutIcon />}
            description={i18n.t("label.logout")}
            className="tw-rounded-lg tw-w-full tw-px-4 tw-py-6 tw-cursor-pointer tw-text-dental-neutral-N6"
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
