import { useClinicsQuery } from "../../api/rest/clinic";
import ClinicRow from "../../components/design-systems/ClinicRow/ClinicRow";
import { Link } from "react-router-dom";

const CompanyClinics = () => {
  const clinicsQuery = useClinicsQuery();
  const clinics = clinicsQuery.data || [];

  return (
    <div className="tw-flex-1">
      {clinics.map((clinic) => (
        <Link
          key={clinic.id}
          to={`/company/clinic/${clinic.id}/users`}
          className="tw-no-underline"
        >
          <ClinicRow clinic={clinic} />
        </Link>
      ))}
    </div>
  );
};

export default CompanyClinics;
