import { SubmitHandler, useForm } from "react-hook-form";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../../../components/design-systems/FormGroup/FormGroup";
import MultiToggleButtonGroup from "../../../components/design-systems/CustomToggleButtonGroup/MultiToggleButtonGroup";
import { SideDialog } from "../../../components/design-systems/Dialog/SIdeDialog/SideDialog";
import { Button } from "../../../components/design-systems/Button";
import { FilterFormData } from "../ClinicCommunication";
// import Select from "react-select";
import { useSetFilterPresetMutation } from "../../../api/rest/communication/communication";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { notify } from "../../../components/design-systems/Alert/notify";
import { ErrorResponse } from "../../../components/AddEmployee/AddEmployee";
import { FilterPresetType } from "../../../api/rest/communication/communication.types";
import { useEffect } from "react";

type FilterDialogProps = {
  clinicId: number;
  actionstatusProgressTypes: any;
  availableAppointmentTypes: any;
  isOpen: boolean;
  onClose: () => void;
  onClear: () => void;
  filteredPresets?: FilterPresetType;
  onSubmit: SubmitHandler<FilterFormData>;
  defaultValues: FilterFormData;
  onReasonChange: (newReasons: string[]) => void;
  onActionStatusChange: (newActionStatus: string[]) => void;
  onAppointmentTypeChange: (newAppointmentTypes: any[]) => void;
};

const FilterSideDialog: React.FC<FilterDialogProps> = ({
  clinicId,
  actionstatusProgressTypes,
  availableAppointmentTypes,
  isOpen,
  onClose,
  filteredPresets,
  onSubmit,
  onClear,
  defaultValues,
  onReasonChange,
  onActionStatusChange,
  onAppointmentTypeChange,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<FilterFormData>({
    defaultValues,
  });

  const queryClient = useQueryClient();

  const { mutate: setFilterPresetMutation, isLoading: setFilterPresetLoading } =
    useSetFilterPresetMutation(clinicId, {
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
      onSuccess: () => {
        notify("Filter preset applied successfully", { type: "success" });
        queryClient.invalidateQueries(["findProspects"]);
        onClose();
      },
    });

  const selectedReasons = watch("reasons") || [];
  const selectedStatus = watch("actionstatusProgress") || [];
  const selectedAppointmentTypes = watch("availableAppointmentTypes") || [];

  const appointmentOptions = availableAppointmentTypes.map((type: any) => ({
    label: type.name,
    value: type.id,
  }));

  const selectedAppointmentOptions = appointmentOptions.filter((option: any) =>
    selectedAppointmentTypes.includes(option.value)
  );

  useEffect(() => {
    if (filteredPresets) {
      setValue("reasons", filteredPresets.segment || []);
      setValue("actionstatusProgress", filteredPresets.status || []);

      const preselectedAppointments = availableAppointmentTypes
        .filter((type: any) =>
          filteredPresets.appointment_time.includes(type.name)
        )
        .map((type: any) => type.id);

      setValue("availableAppointmentTypes", preselectedAppointments);
    }
  }, [filteredPresets, availableAppointmentTypes, setValue]);

  const handleClearFilters = () => {
    reset();
    onClear();
  };

  const handleReasonChange = (newReasons: string[]) => {
    setValue("reasons", newReasons);
    onReasonChange(newReasons);

    if (!newReasons.includes("dropout")) {
      setValue("availableAppointmentTypes", []);
      onAppointmentTypeChange([]);
    }
  };

  const handleApplyFilter: SubmitHandler<FilterFormData> = (data) => {
    onSubmit(data);

    const preset = {
      segment: selectedReasons,
      status: selectedStatus,
      appointment_time: selectedAppointmentOptions.map(
        (opt: { label: any }) => opt.label
      ),
    };

    setFilterPresetMutation({ preset });

    onClose();
  };

  return (
    <SideDialog
      isOpen={isOpen}
      onClose={onClose}
      title={<h1>Filters</h1>}
      actions={
        <div className="tw-flex tw-items-center tw-gap-2">
          <Button variant="outline" onClick={handleClearFilters}>
            Clear Filter
          </Button>
          <Button
            type="submit"
            form="filterForm"
            isLoading={setFilterPresetLoading}
          >
            Apply Filter
          </Button>
        </div>
      }
    >
      <form
        className="tw-px-8 tw-pt-10"
        id="filterForm"
        onSubmit={handleSubmit(handleApplyFilter)}
      >
        <div className="tw-space-y-6">
          {/* Segment (Reasons) */}
          <FormGroup className="tw-space-y-3">
            <FormLabel className="tw-text-sm" htmlFor="reasons">
              Segment
            </FormLabel>
            <MultiToggleButtonGroup
              options={[
                { value: "dropout", label: "Dropout" },
                { value: "recall", label: "Recall" },
                { value: "treatment_proposal", label: "Treatment Proposal" },
              ]}
              value={selectedReasons}
              onChange={handleReasonChange}
            />
          </FormGroup>

          <FormGroup className="tw-space-y-3">
            <FormLabel className="tw-text-sm" htmlFor="actionstatusProgress">
              Status
            </FormLabel>
            <MultiToggleButtonGroup
              options={actionstatusProgressTypes}
              value={selectedStatus}
              onChange={(newActionStatus) => {
                setValue("actionstatusProgress", newActionStatus);
                onActionStatusChange(newActionStatus);
              }}
            />
          </FormGroup>

          {selectedReasons.includes("recall") && (
            <FormGroup className="tw-space-y-3">
              <FormLabel className="tw-text-sm" htmlFor="recallDate">
                Recall Date
              </FormLabel>
              <FormInput
                id="recallDate"
                type="date"
                placeholder="Enter Date"
                {...register("recallDate")}
              />
              {errors.recallDate && (
                <FormError>{errors.recallDate.message}</FormError>
              )}
            </FormGroup>
          )}

          {selectedReasons.includes("dropout") && (
            <>
              {/* <FormGroup className="tw-space-y-3">
                <FormLabel className="tw-text-sm" htmlFor="appointmentTypes">
                  Appointment Type
                </FormLabel>
                <Select
                  isMulti
                  options={appointmentOptions}
                  value={selectedAppointmentOptions}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setValue("availableAppointmentTypes", selectedValues);
                    onAppointmentTypeChange(selectedValues);
                  }}
                  placeholder="Select appointment types"
                />
              </FormGroup> */}
              <FormGroup className="tw-space-y-3">
                <FormLabel className="tw-text-sm" htmlFor="dropoutDate">
                  Dropout Date
                </FormLabel>
                <FormInput
                  id="dropoutDate"
                  type="date"
                  placeholder="Enter Date"
                  {...register("dropoutDate")}
                />
                {errors.dropoutDate && (
                  <FormError>{errors.dropoutDate.message}</FormError>
                )}
              </FormGroup>
            </>
          )}

          {selectedReasons.includes("treatment_proposal") && (
            <FormGroup className="tw-space-y-3">
              <FormLabel className="tw-text-sm" htmlFor="proposalDate">
                Proposal Date
              </FormLabel>
              <FormInput
                id="proposalDate"
                type="date"
                placeholder="Enter Date"
                {...register("proposalDate")}
              />
              {errors.proposalDate && (
                <FormError>{errors.proposalDate.message}</FormError>
              )}
            </FormGroup>
          )}
        </div>
      </form>
    </SideDialog>
  );
};

export default FilterSideDialog;
