import { SubmitHandler, useForm, Controller } from "react-hook-form";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../../../components/design-systems/FormGroup/FormGroup";
import { SideDialog } from "../../../components/design-systems/Dialog/SIdeDialog/SideDialog";
import { Button } from "../../../components/design-systems/Button";
import Select from "react-select";
import { EventFormData } from "../../ClinicCommunication/ProspectDialog/ProspectDialog";

type EventDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<EventFormData>;
  actionstatusEventTypes: any;
  isLoading: boolean;
};

const EventDialog: React.FC<EventDialogProps> = ({
  actionstatusEventTypes,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EventFormData>();

  const handleDialogSubmit: SubmitHandler<EventFormData> = (data) => {
    const selectedEvent = actionstatusEventTypes.find(
      (type: any) => type.value === data.event_id.value
    );
    const formDataWithId = {
      ...data,
      event_id: selectedEvent?.id,
    };
    onSubmit(formDataWithId);
  };

  return (
    <SideDialog
      isOpen={isOpen}
      onClose={onClose}
      title={<h1>Add action</h1>}
      actions={
        <div className="tw-flex tw-items-center tw-gap-2">
          <Button type="submit" form="filterForm" isLoading={isLoading}>
            Save
          </Button>
        </div>
      }
    >
      <form
        className="tw-px-8 tw-pt-10"
        id="filterForm"
        onSubmit={handleSubmit(handleDialogSubmit)}
      >
        <div className="tw-space-y-6">
          <FormGroup className="tw-space-y-3">
            <FormLabel className="tw-text-sm" htmlFor="event_id">
              Type
            </FormLabel>
            <Controller
              control={control}
              name="event_id"
              render={({ field }) => (
                <Select
                  {...field}
                  options={actionstatusEventTypes}
                  className="tw-w-full"
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  value={field.value}
                />
              )}
            />
            {errors.event_content && (
              <FormError>{errors.event_content.message}</FormError>
            )}
          </FormGroup>
          <FormGroup className="tw-space-y-3">
            <FormLabel className="tw-text-sm" htmlFor="event_content">
              Comment
            </FormLabel>
            <FormInput
              id="event_content"
              type="text"
              placeholder="Write a comment"
              {...register("event_content")}
            />
            {errors.event_content && (
              <FormError>{errors.event_content.message}</FormError>
            )}
          </FormGroup>
        </div>
      </form>
    </SideDialog>
  );
};

export default EventDialog;
