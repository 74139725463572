import React from "react";
import { Button } from "../../components/design-systems/Button";
import { StepDialog } from "../../components/design-systems/Dialog/StepDialog/StepDialog";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { PageWrapper } from "../../components/PageWrapper";
import { Description } from "../../components/design-systems/Description/Description";

const embeddingOptions = [
  {
    title: "The Embedded widget",
    description:
      "The embedded widget loads immediately and is always shown on the page. It integrates into the design and takes the user through the full booking process. Including collection of info on new patients.",
    snippet: (obid: string | undefined) => `
      <!--Dentli Online Booking Widget-->
<div class="dentli_booking_container" data-dentli_ob_mode="embedded" data-dentli_ob_id="${obid}"></div>
<!--End of Dentli Online Booking Widget-->
    `,
  },
  {
    title: "The Quickflow widget",
    description:
      "The quickflow widget, like the Embedded widget, is always shown on the website. The Quickflow container will take up the width of its container up to a max-width of 400px. It will take the height of its container. It is, a much lighter design than the Embedded widget and only displays the first few available timeslots. This widget skips a few steps of the booking process allowing for a faster and more simple booking, but the patient will have a much more limited selection of timeslots and options.",
    snippet: (obid: string | undefined) => `
      <!--Dentli Online Booking Widget-->
<div class="dentli_booking_container" data-dentli_ob_mode="quick" data-dentli_ob_id="${obid}"></div>
<!--End of Dentli Online Booking Widget-->
    `,
  },
  {
    title: "The Quickflow mini widget",
    description:
      "The Quickflow mini widget only shows timeslots for one defined treatment treatment. To setup the mini version simply add data-dentli_ob_treatment_id=[treatment_id] to the html snippet. The mini will show treatment name and price if the data is available. If you do not want to display these add data-dentli_ob_show_text='false' to the html snippet.",
    snippet: (obid: string | undefined) => `
      <!--Dentli Online Booking Widget-->
<div class="dentli_booking_container" data-dentli_ob_mode="quick-mini" data-dentli_ob_id="${obid}" data-dentli_ob_treatment_id=[treatment_id] data-dentli_ob_show_text="false"></div>
<!--End of Dentli Online Booking Widget-->
    `,
  },
  {
    title: "The Modal widget (a tag)",
    description:
      "The Modal widget is a button that, when clicked, opens a full-page modal with the full online booking widget. It can be implemented using either a button tag or an a tag.",
    snippet: (obid: string | undefined) => `
      <!--Dentli Online Booking Widget-->
<a class="dentli_booking_container" data-dentli_ob_mode="modal" data-dentli_ob_id="${obid}">Book now</a>
<!--End of Dentli Online Booking Widget-->
    `,
  },
  {
    title: "The Modal widget (button)",
    description:
      "The Modal widget is a button that, when clicked, opens a full-page modal with the full online booking widget. It can be implemented using either a button tag or an a tag.",
    snippet: (obid: string | undefined) => `
      <!--Dentli Online Booking Widget-->
<button class="dentli_booking_container" data-dentli_ob_mode="modal" data-dentli_ob_id="${obid}">Book now</button>
<!--End of Dentli Online Booking Widget-->
    `,
  },
];

type EmbeddingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  obid: string | undefined;
};

const EmbeddingModal: React.FC<EmbeddingModalProps> = ({
  isOpen,
  onClose,
  obid,
}) => {
  return (
    <StepDialog
      isOpen={isOpen}
      onClose={onClose}
      totalSteps={0}
      currentStep={1}
      actions={<Button onClick={onClose}>Close</Button>}
      isLoading={false}
    >
      <PageWrapper className="tw-flex tw-mx-auto tw-h-full tw-max-w-5xl tw-flex-col tw-px-0 tw-pb-12 tw-pt-4">
        <Tab.Group>
          <Tab.List className="tw-flex tw-overflow-x-auto tw-gap-2 tw-rounded-lg tw-pb-4">
            {embeddingOptions.map((option) => (
              <Tab
                key={option.title}
                className={({ selected }) =>
                  clsx(
                    "tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium",
                    "tw-rounded-lg tw-focus:outline-none",
                    selected
                      ? "tw-bg-dental-primary-P2 tw-text-white"
                      : "tw-bg-dental-primary-P5 tw-text-primary"
                  )
                }
              >
                {option.title}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="tw-mt-2 tw-overflow-y-auto">
            {embeddingOptions.map((option, index) => (
              <Tab.Panel
                key={index}
                className="tw-px-4 tw-py-2 tw-bg-white tw-rounded-lg"
              >
                <div className="tw-my-4">
                  <Description
                    title={option.title}
                    titleSize="xl"
                    titleWeight="medium"
                    description={option.description}
                  />
                </div>

                <pre className="tw-bg-gray-100 tw-p-2 tw-rounded-lg tw-overflow-auto">
                  <code className="tw-whitespace-pre-wrap tw-text-xs">
                    {option.snippet(obid)}
                  </code>
                </pre>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </PageWrapper>
    </StepDialog>
  );
};

export default EmbeddingModal;
