import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import type React from "react";
import { Fragment, useState } from "react";
// import { ReactComponent as LogoSvg } from "../../../../img/logo.svg";
import clsx from "clsx";
import { X } from "@phosphor-icons/react";

type SideDialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  headerChildren?: React.ReactNode;
  actions?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  stackedActions?: boolean;
};

const SideDialog = ({
  isOpen = true,
  onClose = () => {},
  children,
  title,
  headerChildren,
  actions,
  isLoading,
  className = "",
  stackedActions = true,
}: SideDialogProps) => {
  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 100);
  };

  return (
    <Transition appear show={isOpen && !isExiting} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-50 tw-bg-gray-500 tw-bg-opacity-50"
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0 tw-translate-x-full"
          enterTo="tw-opacity-100 tw-translate-x-0"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100 tw-translate-x-0"
          leaveTo="tw-opacity-0 tw-translate-x-full"
        >
          <HeadlessDialog.Panel
            className={`tw-fixed tw-top-0 tw-right-0 tw-h-full tw-w-1/3 tw-transform tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-align-middle tw-shadow-lg tw-transition-all ${className}`}
          >
            <HeadlessDialog.Title
              as="div"
              className="tw-flex tw-h-20 tw-items-center tw-justify-between tw-pl-4 tw-pr-4 tw-pt-6 md:tw-pl-12 md:tw-pr-8"
            >
              {/* <LogoSvg
                className={`tw-w-36 ${
                  headerChildren ? "tw-hidden md:tw-block" : ""
                }`}
              /> */}
              <h1>{title}</h1>
              <div className="tw-flex tw-grow tw-justify-center">
                {headerChildren}
              </div>

              <div className="tw-flex tw-w-36 tw-justify-end">
                <button onClick={handleClose} className="md:tw-block">
                  <X size={32} />
                </button>
              </div>
            </HeadlessDialog.Title>
            <div className="tw-h-[calc(100vh-6rem)] tw-overflow-auto tw-px-4">
              {children}
            </div>
            {actions && (
              <div
                className={clsx(
                  "tw-flex tw-items-center tw-justify-end tw-gap-2 tw-border-t tw-px-4 tw-py-4 md:tw-flex-row md:tw-px-12",
                  {
                    "tw-flex-col": stackedActions,
                    "tw-flex-row": !stackedActions,
                  }
                )}
              >
                {actions}
              </div>
            )}
          </HeadlessDialog.Panel>
        </Transition.Child>
      </HeadlessDialog>
    </Transition>
  );
};

export { SideDialog };
export type { SideDialogProps };
