import { useState, useEffect, useRef } from "react";
import { PlusCircle, User as UserLogo } from "@phosphor-icons/react";
import EditCompanyInformationDialog from "./EditCompanyInformationDialog";
import {
  useOrganisationsQuery,
  useUpdateOrganisationMutation,
} from "../../../api/rest/organisation";
import { Description } from "../../../components/design-systems/Description/Description";
import { apiUrl } from "../../../config";
import { ColorResult, SketchPicker } from "react-color";
import { notify } from "../../../components/design-systems/Alert/notify";
import { useQueryClient } from "@tanstack/react-query";

type ColorKey =
  | "primary_color"
  | "secondary_color"
  | "base_color"
  | "accent_color";

const colorDescriptions: Record<
  ColorKey,
  { title: string; description: string }
> = {
  primary_color: {
    title: "Primary Color",
    description:
      "Used for essential UI elements like survey rating scales, menus, etc.",
  },
  secondary_color: {
    title: "Secondary Color",
    description: "Used for secondary UI elements like buttons, links, etc.",
  },
  base_color: {
    title: "Base Color",
    description: "Used for background elements and large sections of color.",
  },
  accent_color: {
    title: "Accent Color",
    description: "Used for highlighting elements and important actions.",
  },
};

const CompanySettings = () => {
  const organisationQuery = useOrganisationsQuery();
  const organisation = organisationQuery.data || [];
  const queryClient = useQueryClient();

  const [isEditCompanyInfoDialogOpen, setIsEditCompanyInfoDialogOpen] =
    useState(false);
  const [colorPickersVisibility, setColorPickersVisibility] = useState<
    Record<ColorKey, boolean>
  >({
    primary_color: false,
    secondary_color: false,
    base_color: false,
    accent_color: false,
  });
  const [currentColors, setCurrentColors] = useState<Record<ColorKey, string>>({
    primary_color: organisation[0]?.brand_colors?.primary_color || "#FFFFFF",
    secondary_color:
      organisation[0]?.brand_colors?.secondary_color || "#FFFFFF",
    base_color: organisation[0]?.brand_colors?.base_color || "#FFFFFF",
    accent_color: organisation[0]?.brand_colors?.accent_color || "#FFFFFF",
  });

  const pickerRefs = {
    primary_color: useRef<HTMLDivElement | null>(null),
    secondary_color: useRef<HTMLDivElement | null>(null),
    base_color: useRef<HTMLDivElement | null>(null),
    accent_color: useRef<HTMLDivElement | null>(null),
  };

  const {
    mutate: updateOrganisationMutation,
    // isLoading: updateOrganisationLoading,
  } = useUpdateOrganisationMutation();

  const toggleColorPicker = (colorKey: ColorKey) => {
    setColorPickersVisibility((prev) => ({
      ...prev,
      [colorKey]: !prev[colorKey],
    }));
  };

  const handleColorChange = (color: ColorResult, colorKey: ColorKey) => {
    setCurrentColors((prevColors) => ({
      ...prevColors,
      [colorKey]: color.hex,
    }));

    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("primary_color", currentColors.primary_color);
    formData.append("secondary_color", currentColors.secondary_color);
    formData.append("base_color", currentColors.base_color);
    formData.append("accent_color", currentColors.accent_color);

    // Override the specific color that has changed
    formData.set(colorKey, color.hex);

    updateOrganisationMutation(
      {
        organisationId: organisation[0]!.id,
        formData,
      },
      {
        onSuccess: () => {
          notify("Color updated successfully");
          queryClient.invalidateQueries();
        },
        onError: () => {
          notify("Failed to update color", { type: "error" });
        },
      }
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    Object.keys(pickerRefs).forEach((colorKey) => {
      if (
        colorPickersVisibility[colorKey as ColorKey] &&
        pickerRefs[colorKey as ColorKey].current &&
        !pickerRefs[colorKey as ColorKey].current!.contains(
          event.target as Node
        )
      ) {
        setColorPickersVisibility((prev) => ({
          ...prev,
          [colorKey]: false,
        }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPickersVisibility]);

  const CloseEditCompanyInfoDialog = () =>
    setIsEditCompanyInfoDialogOpen(false);

  return (
    <div>
      <section id="company-about" className="tw-mb-16">
        <h1 className="tw-pb-6 tw-font-light">Company Information</h1>
        <div className="tw-space-y-8">
          <div>
            <Description
              theme="defaultFlip"
              title="Company logo"
              titleSize="sm"
              titleWeight="normal"
            />
            {organisation[0]?.logo ? (
              <img
                src={`${apiUrl}/${organisation[0]?.logo}`}
                alt="Profile"
                className="tw-object-cover tw-rounded-[32px] tw-border-2 tw-border-dental-primary-P2"
                style={{ width: "50px", height: "50px" }}
              />
            ) : (
              <UserLogo size={50} />
            )}
          </div>
          <Description
            theme="defaultFlip"
            title="Company name"
            titleSize="sm"
            titleWeight="normal"
            description={organisation[0]?.name}
            descriptionSize="base"
            descriptionWeight="medium"
          />
          <Description
            theme="defaultFlip"
            title="Company address"
            titleSize="sm"
            titleWeight="normal"
            description={`${organisation[0]?.address}, ${organisation[0]?.zip} ${organisation[0]?.city}`}
            descriptionSize="base"
            descriptionWeight="medium"
          />
        </div>
      </section>

      <section id="company-branding">
        <h1 className="tw-pb-6 tw-font-light">Company branding</h1>
        <div className="tw-grid md:tw-grid-cols-2 tw-gap-8">
          {(
            [
              "primary_color",
              "secondary_color",
              "base_color",
              "accent_color",
            ] as ColorKey[]
          ).map((colorKey) => (
            <div
              className="tw-flex tw-items-center tw-gap-8 tw-bg-white tw-rounded-md tw-p-4"
              key={colorKey}
            >
              <div className="tw-relative" ref={pickerRefs[colorKey]}>
                <button
                  style={{ backgroundColor: currentColors[colorKey] }}
                  className="tw-rounded tw-border tw-w-20 tw-h-20 tw-flex tw-justify-center tw-items-center"
                  onClick={() => toggleColorPicker(colorKey)}
                >
                  <PlusCircle size={32} />
                </button>
                {colorPickersVisibility[colorKey] && (
                  <div className="tw-absolute tw-bottom-10 tw-z-20">
                    <SketchPicker
                      color={currentColors[colorKey]}
                      onChangeComplete={(color) =>
                        handleColorChange(color, colorKey)
                      }
                    />
                  </div>
                )}
              </div>
              <Description
                title={colorDescriptions[colorKey].title}
                titleSize="base"
                titleWeight="semibold"
                description={colorDescriptions[colorKey].description}
              />
            </div>
          ))}
        </div>
      </section>

      {isEditCompanyInfoDialogOpen && (
        <EditCompanyInformationDialog onClose={CloseEditCompanyInfoDialog} />
      )}
    </div>
  );
};

export default CompanySettings;
