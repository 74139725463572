import { Dialog3 } from "./Dialog3";
import { StepProgress } from "./StepProgress";

type StepDialogProps = {
  children: React.ReactNode;
  totalSteps: number;
  currentStep: number;
  actions: React.ReactNode;
  isLoading: boolean;
  isOpen?: boolean;
  onClose: () => void;
  closeIcon?: string;
};

const StepDialog = ({
  children,
  totalSteps,
  currentStep,
  actions,
  isLoading,
  isOpen,
  onClose,
  closeIcon = "cancel",
}: StepDialogProps) => (
  <Dialog3
    closeIcon={closeIcon}
    isOpen={isOpen}
    onClose={() => onClose()}
    stackedActions={false}
    headerChildren={
      totalSteps > 0 && (
        <StepProgress
          className="tw-max-w-md tw-items-center"
          totalSteps={totalSteps}
          finishedSteps={currentStep}
        />
      )
    }
    actions={actions}
    isLoading={isLoading}
  >
    <div>{children}</div>
  </Dialog3>
);

export { StepDialog };
