type Environment = "staging" | "production";

type Config = {
  environment: Environment;
  apiUrl: string;
};

const configs: Record<Environment, Config> = {
  staging: {
    environment: "staging",
    apiUrl: "https://eu-core.staging.dentli.io",
  },
  production: {
    environment: "production",
    apiUrl: "https://eu.core.dentli.io",
  },
};

const { environment, apiUrl } =
  configs[(process.env.REACT_APP_ENVIRONMENT as Environment) ?? "staging"];

export type { Environment };
export { apiUrl, environment };
