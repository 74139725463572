import { User } from "@phosphor-icons/react";
import clsx from "clsx";

const ProfileImagePlaceholder = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx(
        "tw-bg-white tw-p-5 tw-rounded-3xl tw-border-[2px] tw-border-dental-primary-P2",
        className
      )}
    >
      <User
        size={40}
        weight="fill"
        className="tw-text-dental-neutral-N4 tw-bg-white"
      />
    </div>
  );
};

export default ProfileImagePlaceholder;
