import {
  CalendarCheck,
  CaretLeft,
  // Check,
  Circle,
  X,
} from "@phosphor-icons/react";
import { Button } from "../../components/design-systems/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useSingleOnlineBookingQuery,
  useUpdateOnlineBookingMutation,
} from "../../api/rest/booking";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { useForm, Controller } from "react-hook-form";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../../components/design-systems/FormGroup/FormGroup";
import { useState, useEffect, useMemo } from "react";
import { PageWrapper } from "../../components/PageWrapper";
import { getBrandColors, getClinicById } from "../SingleClinic/clinic.utils";
import { useClinicsQuery } from "../../api/rest/clinic";
import { useOrganisationsQuery } from "../../api/rest/organisation";
import {
  useClinicSettingsQuery,
  useCreateNewTreatmentMutation,
  useUpdateClinicSettingsMutation,
  useDeleteTreatmentMutation,
} from "../../api/rest/settings";
import { notify } from "../../components/design-systems/Alert/notify";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../components/AddEmployee/AddEmployee";
import { Badge } from "../../components/design-systems/Badge";
import EmbeddingModal from "./EmbeddingModal";
import { Description } from "../../components/design-systems/Description/Description";

type ColorScheme = {
  primary_color: string;
  secondary_color: string;
  base_color: string;
  accent_color: string;
};

type FormData = {
  domain: string;
  showPractitionerPicker: boolean;
  treatmentIds: string[];
  colorScheme: ColorScheme;
  buttonColor: string;
  headerColor: string;
  [key: string]: any; // Allow dynamic form fields
};

const OnlineBookingSettings = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      buttonColor: "",
      headerColor: "",
    },
  });

  const { clinicId, bookingId } = useParams<{
    clinicId: string;
    bookingId: string;
  }>();

  const clinicsQuery = useClinicsQuery();
  const organisationsQuery = useOrganisationsQuery();
  const bookingQuery = useSingleOnlineBookingQuery(Number(bookingId));
  const clinicSettingsQuery = useClinicSettingsQuery(Number(clinicId));

  const clinicsData = clinicsQuery.data || [];
  const organisationsData = organisationsQuery.data || [];
  const bookingData = bookingQuery.data;

  const { mutate: updateSettings } = useUpdateClinicSettingsMutation(
    Number(clinicId),
    {
      onSuccess: (response) => {
        notify(response.message, { type: "success" });
      },
      onError: (error) => {
        console.log("Error updating settings:", error);
      },
    }
  );

  const { mutate: createNewTreatment } = useCreateNewTreatmentMutation(
    Number(bookingId),
    {
      onSuccess: (response) => {
        notify(response.message, { type: "success" });
        setTreatmentIds((prev) => [
          ...prev,
          response.data.treatments.slice(-1)[0].treatment_id,
        ]);
        queryClient.invalidateQueries(["treatments", Number(bookingId)]);
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
    }
  );

  const { mutate: deleteTreatment } = useDeleteTreatmentMutation(
    Number(bookingId),
    {
      onSuccess: (response) => {
        notify(response.message, { type: "success" });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
    }
  );

  const {
    mutate: updateOnlineBooking,
    isLoading: isUpdateOnlineBookingLoading,
  } = useUpdateOnlineBookingMutation({
    onSuccess: (response) => {
      notify(response.message, { type: "success" });
      queryClient.invalidateQueries(["singleOnlineBooking", Number(bookingId)]);
    },
    onError: (error) => {
      console.log("Error updating online booking:", error);
      notify("Failed to update online booking", { type: "error" });
    },
  });

  const loading = useMemo(() => {
    return (
      clinicsQuery.isLoading ||
      organisationsQuery.isLoading ||
      bookingQuery.isLoading ||
      clinicSettingsQuery.isLoading
    );
  }, [
    clinicsQuery.isLoading,
    organisationsQuery.isLoading,
    bookingQuery.isLoading,
    clinicSettingsQuery.isLoading,
  ]);

  const error = useMemo(() => {
    return (
      clinicsQuery.error ||
      organisationsQuery.error ||
      bookingQuery.error ||
      clinicSettingsQuery.error
    );
  }, [clinicsQuery, organisationsQuery, bookingQuery, clinicSettingsQuery]);

  const clinicSettingsOptions: string[] = clinicSettingsQuery.data?.data || [];
  const clinic = getClinicById(clinicsData, clinicId);

  const [treatmentId, setTreatmentId] = useState<string>("");
  const [treatmentIds, setTreatmentIds] = useState<string[]>([]);
  const [isEmbeddingModalOpen, setEmbeddingModalOpen] = useState(false);

  useEffect(() => {
    if (bookingData) {
      setValue("domain", bookingData.base_url);
      setValue("buttonColor", bookingData.button_color || "");
      setValue("headerColor", bookingData.header_color || "");
      setTreatmentIds(
        bookingData.treatments.map((treatment) => treatment.treatment_id)
      );
    }
  }, [bookingData, setValue]);

  if (loading || error) {
    return <Loading />;
  }

  if (!bookingData || !clinic) {
    return <Description title="Error" description="No online booking" />;
  }

  const brandColors = getBrandColors(clinic, organisationsData);

  const clinicSettingsOptionsFilled = bookingData.clinic.options.reduce(
    (acc, option) => {
      acc[option.option_key] = option.option_value;
      return acc;
    },
    {} as Record<string, string>
  );

  const clinicOptionsFilled = { ...clinicSettingsOptionsFilled };
  clinicSettingsOptions.forEach((option) => {
    if (!(option in clinicOptionsFilled)) {
      clinicOptionsFilled[option] = "";
    }
  });

  const formatLabel = (label: string): string => {
    return label
      .split("_")
      .map((word, index) =>
        index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
      )
      .join(" ");
  };

  const handleAddTreatmentId = () => {
    if (treatmentId && !treatmentIds.includes(treatmentId)) {
      createNewTreatment({ dpm_treatment_id: treatmentId });
      setTreatmentId("");
    }
  };

  const handleRemoveTreatmentId = (id: string) => {
    deleteTreatment({ treatmentId: id });
    setTreatmentIds(treatmentIds.filter((treatmentId) => treatmentId !== id));
  };

  const handleBlur = (name: string, value: string) => {
    if (value) {
      updateSettings({ setting: name, value });
    }
  };

  const onSubmit = (data: FormData) => {
    console.log({ ...data, treatmentIds });
    updateOnlineBooking({
      onlineBookingId: Number(bookingId),
      updatedData: {
        base_url: data.domain,
        active: true,
        button_color: data.buttonColor,
        header_color: data.headerColor,
      },
    });
  };

  console.log({ bookingData, clinic, brandColors });

  return (
    <div className="tw-p-2">
      <div className="tw-bg-dental-neutral-N2 tw-flex tw-items-center tw-justify-between tw-rounded-md tw-p-3 tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-10">
        <button
          onClick={() => navigate(-1)}
          className="tw-bg-dental-primary-P1 tw-p-3 tw-flex tw-items-center tw-gap-2 tw-rounded-md"
        >
          <CaretLeft className="tw-text-dental-neutral-N10" size={24} />
          <p className="tw-text-dental-neutral-N10 tw-text-sm tw-font-medium">
            Online bookings
          </p>
        </button>
      </div>
      <div className="tw-bg-dental-neutral-N2 tw-flex tw-flex-col tw-justify-between tw-rounded-md tw-p-2 tw-fixed tw-left-0 tw-top-[80px] tw-bottom-0 tw-w-[320px] tw-min-w-[320px] max-md:tw-hidden">
        <div className="tw-p-6">
          <div className="tw-relative">
            <img
              alt="online-booking"
              className="tw-border tw-rounded-md tw-w-full tw-mb-4"
              src={require("../../../src/img/online-booking.png")}
            />
          </div>
          <h1 className="tw-text-dental-neutral-N9 tw-pb-2">
            {bookingData.name}
          </h1>
          <div className="tw-inline-flex tw-items-center tw-gap-1 tw-px-2 tw-py-1 tw-rounded-md tw-bg-dental-primary-P1">
            <CalendarCheck size={16} className="tw-text-dental-neutral-N9" />
            <p className="tw-text-sm tw-font-medium tw-text-dental-neutral-N9">
              Online booking
            </p>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-p-6 tw-bg-dental-neutral-900">
          {!bookingData.active && (
            <span className="tw-text-dental-neutral-N9 tw-text-xs tw-font-medium">
              Your online booking is ready! It'll be online as soon as you click
              Activate.
            </span>
          )}
          <Button
            onClick={handleSubmit(onSubmit)}
            className="tw-w-full"
            type="submit"
            form="bookingForm"
          >
            {bookingData.active ? "Save" : "Activate"}
          </Button>
          {bookingData.active && (
            <Button
              variant="outline"
              color="white"
              onClick={() => setEmbeddingModalOpen(true)}
              className="tw-w-full"
            >
              Embedded links
            </Button>
          )}
        </div>
      </div>
      <div className="tw-w-full tw-pt-[80px]">
        <PageWrapper className="md:tw-ml-[320px]">
          <div className="tw-max-w-7xl tw-mx-auto tw-w-full tw-flex tw-gap-20">
            <section className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-justify-between tw-pb-4 tw-mb-12 tw-border-b tw-border-neutral-300">
                <div className="tw-flex tw-items-center tw-gap-3">
                  <CalendarCheck
                    size={40}
                    className="tw-text-dental-primary-P2"
                  />
                  <h1>Online booking</h1>
                </div>
                <div className="tw-flex-shrink-0 tw-ml-auto">
                  <Badge
                    color={bookingData.active ? "success" : "blue"}
                    className="tw-flex tw-items-center tw-gap-2"
                  >
                    <Circle
                      className={clsx({
                        "tw-text-green-600": bookingData.active,
                        "tw-text-dental-neutral-N2": !bookingData.active,
                      })}
                      size={8}
                      weight="fill"
                    />
                    <p> {bookingData.active ? "Active" : "Inactive"}</p>
                  </Badge>
                </div>
              </div>

              <form
                id="bookingForm"
                onSubmit={handleSubmit(onSubmit)}
                className="tw-mt-4"
              >
                <div id="settings" className="tw-flex tw-gap-12">
                  <h3 className="max-sm:tw-hidden">Settings</h3>
                  <div className="tw-w-full">
                    {clinicSettingsOptions.map((setting) => (
                      <FormGroup key={setting} className="tw-mb-8">
                        <FormLabel htmlFor={setting}>
                          {formatLabel(setting)}
                        </FormLabel>
                        <Controller
                          name={setting}
                          control={control}
                          defaultValue={clinicOptionsFilled[setting] || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              onBlur={() => handleBlur(field.name, field.value)}
                            />
                          )}
                        />
                        {errors[setting] && (
                          <FormError>
                            {errors[setting]?.message as React.ReactNode}
                          </FormError>
                        )}
                      </FormGroup>
                    ))}

                    <FormGroup className="tw-mb-8">
                      <FormLabel htmlFor="treatments">Treatment IDs</FormLabel>
                      <div className="tw-flex tw-items-center tw-border-[1px] tw-border-dental-primary-P5 tw-bg-dental-neutral-N10 tw-rounded-lg tw-h-[56px]">
                        <FormInput
                          id="treatments"
                          placeholder="Enter treatment ID"
                          className="tw-border-none tw-bg-transparent tw-flex-grow"
                          value={treatmentId}
                          onChange={(e) => setTreatmentId(e.target.value)}
                        />
                        <button
                          type="button"
                          className="tw-ml-2 tw-bg-neutral-500 tw-text-white tw-text-sm tw-font-medium tw-m-2 tw-rounded-md tw-h-[80%] tw-px-4 lg:tw-w-32"
                          onClick={handleAddTreatmentId}
                        >
                          Add
                        </button>
                      </div>
                      {treatmentIds.length > 0 && (
                        <div className="tw-mt-4 tw-flex tw-gap-2 tw-items-center">
                          {treatmentIds.map((id, index) => (
                            <div
                              key={index}
                              className="tw-flex tw-gap-2 tw-p-2 tw-rounded-md tw-bg-dental-primary-P5"
                            >
                              <p>{id}</p>
                              <X
                                className="tw-cursor-pointer"
                                onClick={() => handleRemoveTreatmentId(id)}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {errors.treatmentIds && (
                        <FormError>
                          {errors.treatmentIds.message as React.ReactNode}
                        </FormError>
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div id="settings" className="tw-mt-4 tw-flex tw-gap-12">
                  <h3 className="max-sm:tw-hidden tw-mr-4">Style</h3>
                  <div className="tw-w-full tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-2">
                    <FormGroup className="tw-mb-8">
                      <FormLabel htmlFor="buttonColor">Button color</FormLabel>
                      <Controller
                        name="buttonColor"
                        control={control}
                        defaultValue={
                          bookingData.button_color || brandColors.primary_color
                        }
                        render={({ field }) => (
                          <div className="tw-flex tw-gap-4">
                            {Object.entries(brandColors).map(
                              ([colorName, colorValue]) => (
                                <label
                                  key={colorName}
                                  className="tw-cursor-pointer"
                                >
                                  <input
                                    type="radio"
                                    value={colorValue}
                                    checked={field.value === colorValue}
                                    onChange={() => field.onChange(colorValue)}
                                    className="tw-hidden"
                                  />
                                  <div
                                    className={clsx(
                                      "tw-w-20 tw-h-20 tw-rounded-md",
                                      field.value === colorValue &&
                                        "tw-border-2 tw-border-black"
                                    )}
                                    style={{ backgroundColor: colorValue }}
                                  />
                                </label>
                              )
                            )}
                          </div>
                        )}
                      />
                      {errors.buttonColor && (
                        <FormError>
                          {errors.buttonColor.message as React.ReactNode}
                        </FormError>
                      )}
                    </FormGroup>
                    <FormGroup className="tw-mb-8">
                      <FormLabel htmlFor="headerColor">Header color</FormLabel>
                      <Controller
                        name="headerColor"
                        control={control}
                        defaultValue={
                          bookingData.header_color || brandColors.primary_color
                        }
                        render={({ field }) => (
                          <div className="tw-flex tw-gap-4">
                            {Object.entries(brandColors).map(
                              ([colorName, colorValue]) => (
                                <label
                                  key={colorName}
                                  className="tw-cursor-pointer"
                                >
                                  <input
                                    type="radio"
                                    value={colorValue}
                                    checked={field.value === colorValue}
                                    onChange={() => field.onChange(colorValue)}
                                    className="tw-hidden"
                                  />
                                  <div
                                    className={clsx(
                                      "tw-w-20 tw-h-20 tw-rounded-md",
                                      field.value === colorValue &&
                                        "tw-border-2 tw-border-black"
                                    )}
                                    style={{ backgroundColor: colorValue }}
                                  />
                                </label>
                              )
                            )}
                          </div>
                        )}
                      />
                      {errors.headerColor && (
                        <FormError>
                          {errors.headerColor.message as React.ReactNode}
                        </FormError>
                      )}
                    </FormGroup>
                  </div>
                </div>
                <Button
                  isLoading={isUpdateOnlineBookingLoading}
                  className="tw-w-full md:tw-hidden"
                >
                  Activate
                </Button>
              </form>
            </section>
          </div>
        </PageWrapper>
      </div>

      <EmbeddingModal
        isOpen={isEmbeddingModalOpen}
        onClose={() => setEmbeddingModalOpen(false)}
        obid={bookingId}
      />
    </div>
  );
};

export default OnlineBookingSettings;
