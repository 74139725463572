import axiosInstance from "../../../src/axiosConfig";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { Clinic } from "../../types/models/company";
import { useSession } from "../../context/session";
import { Practitioner } from "../../types/models/user";

const fetchClinics = async (token: string): Promise<Clinic[]> => {
  if (!token) {
    throw new Error("No token found. User must be logged in to fetch clinics.");
  }

  const response = await axiosInstance.get("/api/public/v2/clinics", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Failed to fetch clinics");
  }

  return response.data.data;
};

const updateClinic = async (
  token: string,
  clinicId: number,
  formData: FormData
) => {
  const response = await axiosInstance.post(
    `/api/public/v2/clinics/${clinicId}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to update organisation");
  }

  return response.data;
};

export const fetchPractitionersFromClinic = async (
  clinicId: number,
  token: string
): Promise<Practitioner[]> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch practitioners."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/clinics/${clinicId}/practitioners`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch practitioners");
  }

  return response.data.data;
};

const useClinicsQuery = (options?: UseQueryOptions<Clinic[], Error>) => {
  const { token } = useSession();

  return useQuery<Clinic[], Error>(["clinics"], () => fetchClinics(token!), {
    ...options,
    enabled: !!token,
  });
};

type UpdateClinicRequestType = {
  clinicId: number;
  formData: FormData;
  token: string;
};

const useUpdateClinicMutation = (
  options?: UseMutationOptions<unknown, Error, UpdateClinicRequestType>
) => {
  return useMutation<unknown, Error, UpdateClinicRequestType>(
    ({ token, clinicId, formData }) => updateClinic(token, clinicId, formData),
    options
  );
};

const usePractitionersQuery = (
  clinicId: number,
  options?: UseQueryOptions<Practitioner[], Error>
) => {
  const { token } = useSession();

  return useQuery<Practitioner[], Error>(
    ["practitioners", clinicId],
    () => fetchPractitionersFromClinic(clinicId, token!),
    {
      ...options,
      enabled: !!token && !!clinicId,
    }
  );
};

export { useClinicsQuery, useUpdateClinicMutation, usePractitionersQuery };
