import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { Trash, User } from "@phosphor-icons/react";
import { Button } from "../../../components/design-systems/Button";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/design-systems/Loading/Loading";
import { useQueryClient } from "@tanstack/react-query";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../../../components/design-systems/FormGroup/FormGroup";
import { useSession } from "../../../context/session";
import { useUpdateUserMutation } from "../../../api/rest/profile";
import { apiUrl } from "../../../config";
import { User as UserType } from "../../../types/models/user";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../components/AddEmployee/AddEmployee";
import { notify } from "../../../components/design-systems/Alert/notify";

type IFormInput = Pick<UserType, "name" | "email" | "phone" | "photo">;

const EditProfile: React.FC = () => {
  const { user } = useSession();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(
    user?.photo ? `${apiUrl}/${user.photo}` : null
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      photo: user?.photo || "",
    },
  });

  const { mutate: updateUserMutation, isLoading: updateUserLoading } =
    useUpdateUserMutation({
      onSuccess: (data) => {
        console.log({ data });
        notify(data.message, { type: "success" });
        queryClient.invalidateQueries();
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
    });

  if (!user) {
    return <Loading />;
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setImagePreview(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onSubmit = async (data: any) => {
    let formData = new FormData();

    formData.append("_method", "PATCH");
    if (data.name) {
      formData.append("name", data.name);
    }
    if (data.email) {
      formData.append("email", data.email);
    }
    if (data.phone) {
      formData.append("phone", data.phone);
    }
    if (fileInputRef.current?.files![0]) {
      formData.append("photo", fileInputRef.current.files[0]);
    }

    updateUserMutation({ userId: user.id, formData });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="tw-flex tw-flex-col tw-items-center tw-w-full tw-max-w-lg tw-mx-auto tw-space-y-8"
    >
      <FormGroup className="tw-space-y-3 tw-flex tw-flex-row-reverse tw-items-center tw-gap-10 tw-self-start">
        <Controller
          name="photo"
          control={control}
          defaultValue=""
          render={({ field: { onChange } }) => (
            <>
              <FormLabel
                className="tw-text-sm tw-text-dental-primary-P2 tw-font-medium tw-cursor-pointer"
                htmlFor="photo"
              >
                {t("profile.addProfilePicture")}
              </FormLabel>
              <div className="" onClick={() => fileInputRef.current?.click()}>
                {imagePreview ? (
                  <div className="tw-relative">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="tw-object-cover tw-rounded-[32px] tw-border-2 tw-border-dental-primary-P2 tw-mb-6"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <Trash
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImage();
                        onChange("");
                      }}
                      size={24}
                      className="tw-text-dental-semantic-S1 tw-cursor-pointer tw-absolute tw-right-0 tw-bottom-0"
                    />
                  </div>
                ) : (
                  <div className="tw-bg-white tw-p-5 tw-rounded-3xl tw-border-[2px] tw-border-dental-primary-P2">
                    <User
                      size={40}
                      weight="fill"
                      className="tw-text-dental-neutral-N4 tw-bg-white"
                    />
                  </div>
                )}
              </div>
              <input
                id="photo"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => {
                  handleImageChange(e);
                  if (e.target.files && e.target.files[0]) {
                    onChange(e.target.files[0]);
                  }
                }}
              />
            </>
          )}
        />
      </FormGroup>
      <FormGroup className="tw-space-y-3 tw-w-full">
        <FormLabel className="tw-text-sm" htmlFor="name">
          {t("label.name")}
        </FormLabel>
        <FormInput
          id="name"
          type="text"
          placeholder="Enter your name"
          {...register("name")}
        />
        <FormError>{errors.name?.message as React.ReactNode}</FormError>
      </FormGroup>

      <FormGroup className="tw-space-y-3 tw-w-full">
        <FormLabel className="tw-text-sm" htmlFor="email">
          {t("label.email")}
        </FormLabel>
        <FormInput
          id="email"
          type="email"
          placeholder="Enter email address"
          {...register("email", {
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Entered value does not match email format",
            },
          })}
        />
        <FormError>{errors.email?.message as React.ReactNode}</FormError>
      </FormGroup>

      <FormGroup className="tw-space-y-3 tw-w-full">
        <FormLabel className="tw-text-sm" htmlFor="phone">
          {t("label.phone")}
        </FormLabel>
        <FormInput
          id="phone"
          type="tel"
          placeholder="Enter phone number"
          {...register("phone", {
            pattern: {
              value: /^\+[0-9]{7,13}$/,
              message:
                "The phone number can only contain numbers and + and has to be between 7 and 13 characters",
            },
          })}
        />
        <FormError>{errors.phone?.message as React.ReactNode}</FormError>
      </FormGroup>

      <div className="tw-flex tw-w-full tw-space-x-2">
        <Button
          variant="outline"
          className="tw-w-1/2"
          //   onClick={() => setDialogOpen(true)}
        >
          {t("label.changePassword")}
        </Button>
        <Button
          type="submit"
          className="tw-w-1/2"
          isLoading={updateUserLoading}
        >
          {t("label.save")}
        </Button>
      </div>
    </form>
  );
};

export default EditProfile;
