import React from "react";
import clsx from "clsx";

type TextSizeType = "xs" | "sm" | "md" | "lg";
type TextweightType = "normal" | "medium" | "bold" | "fill";

type IconDescriptionProps = {
  Icon?: React.ReactNode;
  description: React.ReactNode;
  textSize?: TextSizeType;
  textWeight?: TextweightType;
  className?: string;
  onClick?: () => void;
};

const textSizeClass = {
  xs: "tw-text-xs",
  sm: "tw-text-sm",
  md: "tw-text-base",
  lg: "tw-text-lg",
};

const textWeightClass = {
  normal: "tw-font-normal",
  medium: "tw-font-medium",
  bold: "tw-font-bold",
  fill: "tw-font-extrabold",
};

const IconDescription: React.FC<IconDescriptionProps> = ({
  Icon,
  description,
  textSize = "sm",
  textWeight = "normal",
  className = "",
  onClick,
}) => {
  return (
    <div
      className={clsx("tw-flex tw-items-center tw-gap-4", className)}
      onClick={onClick}
    >
      <div>{Icon}</div>
      <p
        className={clsx(
          textSizeClass[textSize],
          textWeightClass[textWeight],
          "tw-font-semibold"
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default IconDescription;
