import { Badge } from "../../../components/design-systems/Badge";
import PhoneIcon from "../../../components/icons/Phone";
import EmailIcon from "../../../components/icons/EmailIcon";
import CommentIcon from "../../../components/icons/CommentIcon";
import SmsIcon from "../../../components/icons/SmsIcon";

type ProspectRowProps = {
  prospect: any;
  clinicId: number;
  onClick: (prospectId: number) => void;
};

const ProspectRow: React.FC<ProspectRowProps> = ({ prospect, onClick }) => {
  const renderReasons = (prospect: any) => {
    return (
      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {prospect.reasons.map((reason: string, index: number) => (
          <div key={index}>
            {typeof reason === "string" ? (
              reason.includes("Dropout") ? (
                <Badge className="tw-w-full" color="success">
                  <strong className="tw-mr-1">{reason}:</strong>
                  {new Date(
                    prospect.latest_appointment_date
                  ).toLocaleDateString() || ""}
                </Badge>
              ) : reason.includes("Recall") ? (
                <Badge className="tw-w-full" color="purple">
                  <strong className="tw-mr-1">{reason}:</strong>
                  {new Date(prospect.recall_date).toLocaleDateString() || ""}
                </Badge>
              ) : reason.includes("Proposal") ? (
                <Badge className="tw-w-full" color="blue">
                  <strong className="tw-mr-1">{reason}:</strong>{" "}
                  {new Date(prospect.recall_date).toLocaleDateString() || ""}
                </Badge>
              ) : (
                <strong>{reason}:</strong>
              )
            ) : (
              <strong>Invalid reason data</strong>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderProgress = (prospect: any) => {
    if (prospect.actionstatus?.latest_progress?.type?.label) {
      return (
        <Badge className="tw-w-full" color="purple">
          <strong className="tw-mr-1">
            {prospect.actionstatus?.latest_progress?.type?.label}
          </strong>
        </Badge>
      );
    } else {
      return "";
    }
  };

  const returnIcon = (event_type_id: any) => {
    if (event_type_id === 1) {
      return <PhoneIcon className="tw-text-white" size={25} />;
    } else if (event_type_id === 2) {
      return <EmailIcon className="tw-text-white" size={25} />;
    } else if (event_type_id === 3) {
      return <SmsIcon className="tw-text-white" size={25} />;
    } else if (event_type_id === 4) {
      return <CommentIcon className="tw-text-white" size={25} />;
    } else {
      return <span></span>;
    }
  };

  return (
    <tr
      key={prospect.id}
      onClick={() => onClick(prospect.id)}
      className="tw-cursor-pointer hover:tw-bg-gray-200"
    >
      <td className="tw-px-4 tw-py-4 tw-border-b">{renderReasons(prospect)}</td>
      <td className="tw-px-4 tw-py-4 tw-border-b">{prospect.id}</td>
      <td className="tw-px-4 tw-py-4 tw-border-b ">{prospect.name}</td>
      <td className="tw-px-4 tw-py-4 tw-border-b">
        {renderProgress(prospect)}
      </td>
      <td className="tw-px-4 tw-py-4 tw-border-b">
        <div className="tw-flex tw-items-center">
          {returnIcon(prospect.actionstatus?.latest_event?.type?.id)}
          <span className="tw-ml-2">
            {prospect?.actionstatus?.latest_event?.created_at
              ? new Date(
                  prospect.actionstatus?.latest_event?.created_at
                ).toLocaleDateString()
              : ""}
          </span>
        </div>
      </td>
      <td className="tw-px-4 tw-py-4 tw-border-b">
        {prospect?.actionstatus?.next_reminder?.reminder_date
          ? new Date(
              prospect?.actionstatus?.next_reminder?.reminder_date
            ).toLocaleDateString()
          : ""}
      </td>
    </tr>
  );
};

export default ProspectRow;
