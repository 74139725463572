import { useParams } from "react-router-dom";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { Funnel, Megaphone } from "@phosphor-icons/react";
import { useGetProspectsQuery } from "../../api/rest/communication/communication";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { notify } from "../../components/design-systems/Alert/notify";
import { useMemo, useState } from "react";
import { Button } from "../../components/design-systems/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import FilterSideDialog from "./FilterDialog/FilterDialog";
import CommunicationTable from "./CommunicationTable/CommunicationTable";
import { Description } from "../../components/design-systems/Description/Description";
import Accordion from "../../components/design-systems/Accordion/Accordion";
import {
  FormGroup,
  FormInput,
} from "../../components/design-systems/FormGroup/FormGroup";

type FilterFormData = {
  // filterId: string;
  reasons: string[];
  actionstatusProgress: string[];
  availableAppointmentTypes: string[];
  proposalDate?: string;
  recallDate?: string;
  dropoutDate?: string;
};

type SearchFormData = {
  query: string;
};

const ClinicCommunication = () => {
  const { clinicId } = useParams<{ clinicId: string }>();
  const [isSideDialogOpen, setIsSideDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [filterValues, setFilterValues] = useState<FilterFormData>({
    // filterId: "",
    reasons: [],
    actionstatusProgress: [],
    availableAppointmentTypes: [],
    proposalDate: undefined,
    recallDate: undefined,
    dropoutDate: undefined,
  });

  const {
    // filterId,
    // reasons,
    // actionstatusProgress,
    // availableAppointmentTypes,
    proposalDate,
    recallDate,
    dropoutDate,
  } = filterValues;

  const { register, watch } = useForm<SearchFormData>({
    defaultValues: {
      query: "",
    },
  });

  const watchedQuery = watch("query");

  useMemo(() => {
    setSearchQuery(watchedQuery || "");
  }, [watchedQuery]);

  const findPropspectsQuery = useGetProspectsQuery(Number(clinicId), {
    proposal_date: proposalDate,
    recall_date: recallDate,
    dropout_date: dropoutDate,
  });

  const openSideDialog = () => {
    setIsSideDialogOpen(true);
  };

  const closeSideDialog = () => {
    setIsSideDialogOpen(false);
  };

  const handleFilterSubmit: SubmitHandler<FilterFormData> = (data) => {
    setFilterValues(data);
    closeSideDialog();
  };

  const handleFilterClear = () => {
    setFilterValues({
      // filterId: "",
      reasons: [],
      availableAppointmentTypes: [],
      actionstatusProgress: [],
      proposalDate: undefined,
      recallDate: undefined,
      dropoutDate: undefined,
    });
  };

  const handleReasonChange = (newReasons: string[]) => {
    setFilterValues((prev) => ({ ...prev, reasons: newReasons }));
  };

  const handleActionStatusChange = (newActionStatus: string[]) => {
    setFilterValues((prev) => ({
      ...prev,
      actionstatusProgress: newActionStatus,
    }));
  };

  const handleAppointmentTypeChange = (newAppointmentTypes: any[]) => {
    setFilterValues((prev) => ({
      ...prev,
      availableAppointmentTypes: newAppointmentTypes,
    }));
  };

  const findPropsectsData = findPropspectsQuery.data;
  const findProspectsActionStatusable = findPropsectsData?.actionstatusable;
  const patientProspects = findProspectsActionStatusable?.Patient;

  const actionstatusProgressTypes =
    findPropsectsData?.AvailableProgressTypes.map((progressType) => ({
      id: progressType.id,
      value: progressType.label,
      label: progressType.label,
    }));

  const availableAppointmentTypesOptions =
    findPropsectsData?.AvailableAppointmentTypes;

  const filteredPresets = findPropsectsData?.FilterPreset;

  const filteredProspects =
    patientProspects
      ?.filter((prospect: any) => {
        const hasMatchingStatus =
          filteredPresets?.status.length === 0 ||
          filteredPresets?.status.some(
            (presetStatus: string) =>
              prospect.actionstatus?.latest_progress?.type?.label
                ?.toLowerCase()
                ?.includes(presetStatus.toLowerCase())
          );

        const hasMatchingSegment =
          filteredPresets?.segment.length === 0 ||
          filteredPresets?.segment.some((presetSegment: string) =>
            prospect.reasons.some(
              (prospectReason: string) =>
                prospectReason.toLowerCase() === presetSegment.toLowerCase()
            )
          );

        const hasMatchingAppointmentType =
          filteredPresets?.appointment_time.length === 0 ||
          filteredPresets?.appointment_time.some(
            (presetAppointmentTime: string) =>
              prospect.latest_appointment_type?.name
                ?.toLowerCase()
                ?.includes(presetAppointmentTime.toLowerCase())
          );

        const matchesSearchQuery = searchQuery
          ? prospect.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            prospect.id.toString().includes(searchQuery)
          : true;

        return (
          hasMatchingStatus &&
          hasMatchingSegment &&
          hasMatchingAppointmentType &&
          matchesSearchQuery
        );
      })
      ?.sort((a: any, b: any) => {
        const aDate = new Date(a.latest_appointment_date).getTime();
        const bDate = new Date(b.latest_appointment_date).getTime();
        return bDate - aDate;
      }) ?? patientProspects;

  const filteredReminders = useMemo(() => {
    return filteredProspects
      ?.filter((prospect: any) => {
        const reminder = prospect?.actionstatus?.next_reminder;
        if (!reminder || !reminder.reminder_date) {
          return false;
        }

        const copenhagenReminderDate = new Date(
          reminder.reminder_date
        ).toLocaleDateString("en-GB", {
          timeZone: "Europe/Copenhagen",
        });
        const copenhagenToday = new Date().toLocaleDateString("en-GB", {
          timeZone: "Europe/Copenhagen",
        });

        return copenhagenToday >= copenhagenReminderDate ? reminder : false;
      })
      ?.sort((a: any, b: any) => {
        const aDate = new Date(
          a.actionstatus.next_reminder.reminder_date
        ).getTime();
        const bDate = new Date(
          b.actionstatus.next_reminder.reminder_date
        ).getTime();
        return bDate - aDate;
      });
  }, [filteredProspects]);

  if (findPropspectsQuery.isLoading || !filteredProspects) {
    return <Loading />;
  }

  if (findPropspectsQuery.isError) {
    notify("Failed to fetch prospects data", { type: "error" });
    return null;
  }

  // console.log({
  //   filteredProspects,
  //   findPropsectsData,
  //   availableAppointmentTypes,
  //   filteredPresets,
  //   actionstatusProgress,
  //   patientProspects,
  // });

  return (
    <div>
      <PageWithHeroWrapper
        title="Communication"
        text="communication"
        IconComponent={Megaphone}
        showBackButton="/communication"
        actions={
          <>
            <div className="tw-flex md:tw-w-[400px] tw-w-full tw-items-center tw-gap-2 max-md:tw-flex-col">
              <Button
                className="max-md:tw-w-full"
                prefix={<Funnel />}
                onClick={openSideDialog}
              >
                Filters
              </Button>

              <FormGroup className="tw-space-y-1 tw-w-full">
                <FormInput
                  id="query"
                  placeholder="Search by name or patient ID"
                  {...register("query")}
                />
              </FormGroup>
            </div>
          </>
        }
      >
        {filteredReminders && filteredReminders.length > 0 ? (
          <div>
            <Accordion
              title="Today's reminders"
              defaultOpen={true}
              showBorder={false}
            >
              <CommunicationTable
                patientProspects={filteredReminders}
                clinicId={Number(clinicId)}
              />
            </Accordion>
          </div>
        ) : (
          <div></div>
        )}

        {patientProspects && patientProspects.length > 0 ? (
          <div>
            <Accordion
              title="All patients"
              defaultOpen={true}
              showBorder={false}
            >
              <CommunicationTable
                patientProspects={filteredProspects}
                clinicId={Number(clinicId)}
              />
            </Accordion>
          </div>
        ) : (
          <Description title="No prospects found." />
        )}
      </PageWithHeroWrapper>

      <FilterSideDialog
        clinicId={Number(clinicId)}
        isOpen={isSideDialogOpen}
        onClose={closeSideDialog}
        onSubmit={handleFilterSubmit}
        onClear={handleFilterClear}
        defaultValues={filterValues}
        filteredPresets={filteredPresets}
        actionstatusProgressTypes={actionstatusProgressTypes}
        availableAppointmentTypes={availableAppointmentTypesOptions}
        onReasonChange={handleReasonChange}
        onActionStatusChange={handleActionStatusChange}
        onAppointmentTypeChange={handleAppointmentTypeChange}
      />
    </div>
  );
};

export default ClinicCommunication;
export type { FilterFormData };
