import { toast } from "react-toastify";

import clsx from "clsx";
import { Alert, AlertType } from "./Alert";

const notify = Object.assign(
  (
    message: React.ReactNode,
    { type, ...options }: { type: AlertType } = { type: "success" }
  ) =>
    toast(
      () => (
        <Alert
          type={type}
          className={clsx("tw-h-full tw-text-success", {
            "tw-text-alert-success": type === "success",
            "tw-text-alert-warning": type === "warning",
            "tw-text-alert-error": type === "error",
            "tw-text-alert-info": type === "neutral",
          })}
        >
          {message}
        </Alert>
      ),
      {
        className: clsx(
          "!tw-min-h-0 !tw-rounded !tw-border !tw-p-4 !tw-text-body !tw-shadow-md",
          {
            "!tw-bg-alert-success-light": type === "success",
            "!tw-bg-alert-warning-light": type === "warning",
            "!tw-bg-alert-error-light": type === "error",
            "!tw-bg-alert-info-light": type === "neutral",
          }
        ),
        ...options,
      }
    ),
  toast
);

export { notify };
