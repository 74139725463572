const EmailIcon = ({
    className,
    size = 20,
    ...props
}: {
    className?: string;
    size?: number;
}) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 40 32"
            stroke="currentColor"
            {...props}
        >

            <path d="M3 32C2.2 32 1.5 31.7 0.9 31.1C0.3 30.5 0 29.8 0 29V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H37C37.8 0 38.5 0.3 39.1 0.9C39.7 1.5 40 2.2 40 3V29C40 29.8 39.7 30.5 39.1 31.1C38.5 31.7 37.8 32 37 32H3ZM37 5.75L20.8 16.35C20.6667 16.4167 20.5417 16.475 20.425 16.525C20.3083 16.575 20.1667 16.6 20 16.6C19.8333 16.6 19.6917 16.575 19.575 16.525C19.4583 16.475 19.3333 16.4167 19.2 16.35L3 5.75V29H37V5.75ZM20 13.9L36.8 3H3.25L20 13.9ZM3 6.1V4.13415V4.17075V3V4.15V4.1044V6.1Z" fill="black" />
        </svg>


    );
};

export default EmailIcon;
