import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./dictionary/en.json";

const resources = {
  en: {
    translation: en,
  },
};

const defaultNS = "translation";

const DEFAULT_LOCALE = "en";

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

type Ti18nKey =
  keyof (typeof resources)[typeof DEFAULT_LOCALE][typeof defaultNS];

export default i18n;
export type { Ti18nKey };
