import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { notify } from "../design-systems/Alert/notify";
import { RadioGroup } from "../design-systems/RadioGroup/RadioGroup";
import { ErrorResponse } from "../AddEmployee/AddEmployee";
import { AccessLevel, User } from "../../types/models/user";
import { useState } from "react";
import {
  FormGroup,
  FormLabel,
  FormInput,
  FormError,
} from "../design-systems/FormGroup/FormGroup";
import { StepDialog } from "../design-systems/Dialog/StepDialog/StepDialog";
import { PageWrapper } from "../PageWrapper";
import { Button } from "../design-systems/Button/Button";
import { Description } from "../design-systems/Description/Description";
import { useUpdateUserMutation } from "../../api/rest/profile";

type Step = "USER_ACCESS" | "USER_INFO";

const steps: Record<Step, number> = {
  USER_ACCESS: 0,
  USER_INFO: 1,
};

type FormValues = {
  access: AccessLevel;
  name: string;
  phone: string;
  email: string;
};

type EditUserProps = {
  onClose: () => void;
  user: User;
};

const EditUser: React.FC<EditUserProps> = ({ onClose, user }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      access: user.access_level,
      name: user.name,
      phone: user.phone,
      email: user.email,
    },
  });

  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState(steps.USER_ACCESS);

  const { mutate: updateUserMutation, isLoading: updateUserLoading } =
    useUpdateUserMutation({
      onSuccess: (data: any) => {
        notify(data.message, { type: "success" });
        queryClient.invalidateQueries();
        reset();
        onClose();
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
        console.error("Mutation error:", error);
      },
    });

  const userAccessOptions = [
    {
      value: "full",
      label:
        "Access to all clinic- and user data from the clinic(s) the admin is connected to",
      title: "Full access",
    },
    {
      value: "practitioner",
      label:
        "Limited access to the admin’s own data and their clinic benchmarks",
      title: "Practitioner access",
    },
  ];

  const onSubmit = (data: FormValues) => {
    let formData = new FormData();
    const userId = user.id;
    formData.append("_method", "PATCH");
    formData.append("access_level", data.access);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);

    updateUserMutation({ userId, formData });
    closeDialog();
  };

  const closeDialog = () => {
    setCurrentStep(steps.USER_ACCESS);
    reset();
    onClose();
  };

  const stepComponent = () => {
    switch (currentStep) {
      case steps.USER_ACCESS:
        return (
          <Controller
            control={control}
            name="access"
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <h1 className="tw-font-normal tw-pb-12">Select user access</h1>
                <div className="tw-space-y-6">
                  {userAccessOptions.map((option) => (
                    <RadioGroup.Option key={option.value} value={option.value}>
                      {({ checked }) => (
                        <RadioGroup.OptionContent checked={checked}>
                          <Description
                            title={option.title}
                            description={option.label}
                          />
                        </RadioGroup.OptionContent>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            )}
          />
        );
      case steps.USER_INFO:
        return (
          <div>
            <h1 className="tw-font-normal tw-pb-12">
              Fill in user information
            </h1>
            <div className="tw-space-y-6 tw-w-full tw-max-w-lg">
              <FormGroup>
                <FormLabel htmlFor="name">Name</FormLabel>
                <FormInput
                  id="name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
                <FormError>{errors.phone?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="phone">Phone</FormLabel>
                <FormInput
                  id="phone"
                  {...register("phone", {
                    required: "Phone number is required",
                  })}
                />
                <FormError>{errors.phone?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="email">Address</FormLabel>
                <FormInput
                  id="email"
                  {...register("email", { required: "Address is required" })}
                />
                <FormError>{errors.email?.message}</FormError>
              </FormGroup>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <StepDialog
      totalSteps={2}
      currentStep={currentStep}
      actions={
        <>
          {currentStep < 1 && (
            <Button onClick={closeDialog} color="primary" variant="outline">
              Cancel
            </Button>
          )}
          {currentStep < 1 && (
            <Button onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </Button>
          )}
          {currentStep > 0 && (
            <Button
              onClick={() => setCurrentStep(currentStep - 1)}
              color="primary"
              variant="outline"
            >
              Back
            </Button>
          )}
          {currentStep === 1 && (
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={updateUserLoading}
            >
              Save
            </Button>
          )}
        </>
      }
      isLoading={false}
      onClose={closeDialog}
    >
      <PageWrapper className="tw-flex tw-mx-auto tw-h-full tw-max-w-xl tw-flex-col tw-px-0 tw-pb-12 tw-pt-4">
        {stepComponent()}
      </PageWrapper>
    </StepDialog>
  );
};

export default EditUser;
