import axiosInstance from "../../../axiosConfig";
import {
  UseQueryOptions,
  useQuery,
  UseMutationOptions,
  useMutation,
} from "@tanstack/react-query";
import { useSession } from "../../../context/session";
import { AxiosError } from "axios";
import { SetUpdateActionStatusResponseType } from "../../../types/models/actionStatus";
import { ReminderFormData } from "../../../Pages/ClinicCommunication/ProspectDialog/ProspectDialog";
import {
  ActionStatusAbleDetailType,
  ProspectType,
} from "./communication.types";

{
  /* ----------> GET PROSPECTS <---------- */
}

const getProspects = async (
  token: string,
  clinicId: number,
  filters?: {
    proposal_date?: string;
    recall_date?: string;
    dropout_date?: string;
  }
): Promise<ProspectType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch prospects."
    );
  }

  // Construct query string
  const queryParams = new URLSearchParams();
  if (filters?.proposal_date)
    queryParams.append("proposal_date", filters.proposal_date);
  if (filters?.recall_date)
    queryParams.append("recall_date", filters.recall_date);
  if (filters?.dropout_date)
    queryParams.append("dropout_date", filters.dropout_date);

  const queryString = queryParams.toString()
    ? `?${queryParams.toString()}`
    : "";

  const response = await axiosInstance.get(
    `/api/public/v2/actionstatus/${clinicId}/find${queryString}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch prospects");
  }

  return response.data.data;
};

const useGetProspectsQuery = (
  clinicId: number,
  filters?: {
    proposal_date?: string;
    recall_date?: string;
    dropout_date?: string;
  },
  options?: UseQueryOptions<ProspectType, AxiosError>
) => {
  const { token } = useSession();

  return useQuery<ProspectType, AxiosError>(
    ["findProspects", clinicId, filters],
    () => getProspects(token!, clinicId, filters),
    {
      ...options,
      enabled: !!token && !!clinicId,
    }
  );
};

{
  /* ----------> GET ACTIONSTATUS DETAIL <---------- */
}

const getActionStatusableDetail = async (
  token: string,
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number
): Promise<ActionStatusAbleDetailType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch action status."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/actionstatus/${clinicId}/${actionStatusableType}/${actionStatusableId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch action status");
  }

  return response.data.data;
};

const useActionStatusableDetailQuery = (
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  options?: UseQueryOptions<ActionStatusAbleDetailType, AxiosError>
) => {
  const { token } = useSession();

  return useQuery<ActionStatusAbleDetailType, AxiosError>(
    ["actionStatusableDetail"],
    () =>
      getActionStatusableDetail(
        token!,
        clinicId,
        actionStatusableType,
        actionStatusableId
      ),
    {
      ...options,
      enabled:
        !!token && !!clinicId && !!actionStatusableType && !!actionStatusableId,
    }
  );
};

{
  /* ----------> UPDATE ACTIONSTATUS EVENT <---------- */
}

const updateActionStatusEvent = async (
  token: string,
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  eventData: {
    event_id: number;
    event_content: string;
  }
): Promise<SetUpdateActionStatusResponseType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to add or update event."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/actionstatus/${clinicId}/${actionStatusableType}/${actionStatusableId}`,
    eventData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to add user");
  }

  return response.data.data;
};

const useUpdateActionStatusEventMutation = (
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  options?: UseMutationOptions<
    SetUpdateActionStatusResponseType,
    AxiosError,
    {
      event_id: number;
      event_content: string;
    }
  >
) => {
  const { token } = useSession();

  return useMutation<
    SetUpdateActionStatusResponseType,
    AxiosError,
    {
      event_id: number;
      event_content: string;
    }
  >(
    (eventData) =>
      updateActionStatusEvent(
        token!,
        clinicId,
        actionStatusableType,
        actionStatusableId,
        eventData
      ),
    {
      ...options,
      // onSuccess, onError, etc.
    }
  );
};

{
  /* ----------> UPDATE ACTIONSTATUS PROGRESS <---------- */
}

const updateActionStatusProgress = async (
  token: string,
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  progressData: {
    progress_id: number;
  }
): Promise<SetUpdateActionStatusResponseType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to add or update event."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/actionstatus/${clinicId}/${actionStatusableType}/${actionStatusableId}`,
    progressData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to add user");
  }

  return response.data.data;
};

const useUpdateActionStatusProgressMutation = (
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  options?: UseMutationOptions<
    SetUpdateActionStatusResponseType,
    AxiosError,
    {
      progress_id: number;
    }
  >
) => {
  const { token } = useSession();

  return useMutation<
    SetUpdateActionStatusResponseType,
    AxiosError,
    {
      progress_id: number;
    }
  >(
    (progressData) =>
      updateActionStatusProgress(
        token!,
        clinicId,
        actionStatusableType,
        actionStatusableId,
        progressData
      ),
    {
      ...options,
      // onSuccess, onError, etc.
    }
  );
};

{
  /* ----------> CREATE ACTIONSTATUS REMINDER <---------- */
}

const createReminder = async (
  token: string,
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  reminderData: ReminderFormData
): Promise<SetUpdateActionStatusResponseType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to add or update event."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/actionstatus/${clinicId}/${actionStatusableType}/${actionStatusableId}/reminders/create`,
    reminderData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to add reminder");
  }

  return response.data.data;
};

const useCreateReminderMutation = (
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  options?: UseMutationOptions<
    SetUpdateActionStatusResponseType,
    AxiosError,
    ReminderFormData
  >
) => {
  const { token } = useSession();

  return useMutation<
    SetUpdateActionStatusResponseType,
    AxiosError,
    ReminderFormData
  >(
    (reminderData) =>
      createReminder(
        token!,
        clinicId,
        actionStatusableType,
        actionStatusableId,
        reminderData
      ),
    {
      ...options,
    }
  );
};

{
  /* ----------> DELETE REMINDER <---------- */
}

const deleteReminder = async (
  token: string,
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  reminderData: {
    reminder_id: number;
  }
): Promise<SetUpdateActionStatusResponseType> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to add or update event."
    );
  }

  const response = await axiosInstance.post(
    `/api/public/v2/actionstatus/${clinicId}/${actionStatusableType}/${actionStatusableId}/reminders/done`,
    reminderData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to clear reminder");
  }

  return response.data.data;
};

const useDeleteReminderMutation = (
  clinicId: number,
  actionStatusableType: "patient" | "campaignlead" | "contactformentry",
  actionStatusableId: number,
  options?: UseMutationOptions<
    SetUpdateActionStatusResponseType,
    AxiosError,
    {
      reminder_id: number;
    }
  >
) => {
  const { token } = useSession();

  return useMutation<
    SetUpdateActionStatusResponseType,
    AxiosError,
    {
      reminder_id: number;
    }
  >(
    (reminderData) =>
      deleteReminder(
        token!,
        clinicId,
        actionStatusableType,
        actionStatusableId,
        reminderData
      ),
    {
      ...options,
      // onSuccess, onError, etc.
    }
  );
};

{
  /* ----------> SET FILTER PRESETS <---------- */
}

const setFilterPreset = async (
  token: string,
  clinicId: number,
  presetData: {
    preset: {
      segment: string[];
      status: string[];
      appointment_time: string[];
    };
  }
): Promise<void> => {
  if (!token) {
    throw new Error("No token found. User must be logged in to set presets.");
  }

  const response = await axiosInstance.post(
    `/api/public/v2/actionstatus/${clinicId}/setFilterPreset`,
    presetData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to set filter presets");
  }

  return response.data;
};

const useSetFilterPresetMutation = (
  clinicId: number,
  options?: UseMutationOptions<
    void,
    AxiosError,
    {
      preset: {
        segment: string[];
        status: string[];
        appointment_time: string[];
      };
    }
  >
) => {
  const { token } = useSession();

  return useMutation<
    void,
    AxiosError,
    {
      preset: {
        segment: string[];
        status: string[];
        appointment_time: string[];
      };
    }
  >((presetData) => setFilterPreset(token!, clinicId, presetData), {
    ...options,
    // onSuccess, onError, etc.
  });
};

export {
  useGetProspectsQuery,
  useActionStatusableDetailQuery,
  useUpdateActionStatusEventMutation,
  useUpdateActionStatusProgressMutation,
  useCreateReminderMutation,
  useDeleteReminderMutation,
  useSetFilterPresetMutation,
  // useSendClearReminderMutation,
};
