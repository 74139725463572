import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Dialog } from "./DIalog";
import { Button } from "../Button/Button";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../FormGroup/FormGroup";
import { useForgotPasswordMutation } from "../../../api/rest/user";
import { notify } from "../Alert/notify";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../AddEmployee/AddEmployee";

type ForgotPasswordInputType = {
  email: string;
};

type ForgotPasswordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordInputType>();

  const { mutate: ForgotPasswordMutation, isLoading } =
    useForgotPasswordMutation({
      onSuccess: (data) => {
        notify(data.message, { type: "success" });
        onClose();
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
        console.error("Mutation error:", error);
      },
    });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<ForgotPasswordInputType> = (data) => {
    ForgotPasswordMutation(data);
    handleClose();
  };
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={"Forgot Password"}
      description="We'll email you a link to reset password."
      actions={
        <div className="tw-flex tw-justify-end space-x-2">
          <Button form="ForgotPasswordForm" type="submit" isLoading={isLoading}>
            Reset Password
          </Button>
        </div>
      }
    >
      <form
        id="ForgotPasswordForm"
        onSubmit={handleSubmit(onSubmit)}
        className="tw-w-full tw-space-y-8"
      >
        <FormGroup className="tw-space-y-3">
          <FormLabel htmlFor="email" className="text-sm">
            Email
          </FormLabel>
          <FormInput
            id="email"
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Entered value does not match email format",
              },
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
          {errors.email && (
            <FormError className="mt-1 text-sm text-red-600">
              {errors.email.message}
            </FormError>
          )}
        </FormGroup>
      </form>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
