import { ResponsivePie } from "@nivo/pie";
import { HourglassHigh } from "@phosphor-icons/react";
import { formatNumber } from "../../../../utils/format";
import {
  AvgRevPerHourType,
  ViewModeType,
} from "../../../../types/models/bireport";
import { useTranslation } from "react-i18next";

const CenteredMetric = ({
  centerX,
  centerY,
  percentageDifference,
}: {
  centerX: number;
  centerY: number;
  percentageDifference: number;
}) => (
  <text
    x={centerX}
    y={centerY}
    textAnchor="middle"
    dominantBaseline="central"
    style={{
      fontSize: "22px",
      fontWeight: "bold",
      fill: percentageDifference < 0 ? "#f00" : "#0f0",
    }}
  >
    {percentageDifference > 0 && "+"}
    {percentageDifference}%
  </text>
);

interface RevenuePerHourProps {
  viewMode: ViewModeType;
  revenuePerHour: AvgRevPerHourType;
}

const RevenuePerHourChartNivo: React.FC<RevenuePerHourProps> = ({
  viewMode,
  revenuePerHour,
}) => {
  const i18n = useTranslation();
  const currentYear = 2024;
  const lastYear = 2023;

  const dataCurrentYear = {
    yearly: revenuePerHour.year_to_date[currentYear] || 0,
    monthly: revenuePerHour.month_to_date[currentYear] || 0,
  };

  const dataLastYear = {
    yearly: revenuePerHour.year_to_date[lastYear] || 0,
    monthly: revenuePerHour.month_to_date[lastYear] || 0,
  };

  const yearlyDifference = dataCurrentYear.yearly - dataLastYear.yearly;
  const monthlyDifference = dataCurrentYear.monthly - dataLastYear.monthly;

  const percentageDifference =
    viewMode === "yearly"
      ? (
          ((dataCurrentYear.yearly - dataLastYear.yearly) /
            dataLastYear.yearly) *
          100
        ).toFixed(2)
      : (
          ((dataCurrentYear.monthly - dataLastYear.monthly) /
            dataLastYear.monthly) *
          100
        ).toFixed(2);

  const chartData = [
    {
      id: "This Year",
      label: viewMode === "yearly" ? "This Year" : "This Month",
      value:
        viewMode === "yearly"
          ? dataCurrentYear.yearly
          : dataCurrentYear.monthly,
      color: "hsl(197, 71%, 73%)",
    },
    {
      id: "Remaining",
      label: "Remaining",
      value:
        (viewMode === "yearly" ? dataLastYear.yearly : dataLastYear.monthly) -
        (viewMode === "yearly"
          ? dataCurrentYear.yearly
          : dataCurrentYear.monthly),
      color: "hsl(351, 100%, 86%)",
    },
  ];

  return (
    <div className="tw-relative tw-shadow-2xl">
      <div className="tw-flex tw-items-center tw-justify-between tw-px-6 tw-pt-6">
        <h1>{i18n.t("chart.revenuePerHour")}</h1>
        <HourglassHigh weight="fill" size={32} />
      </div>
      <div style={{ height: 300 }}>
        <ResponsivePie
          data={chartData}
          margin={{ top: 40, right: 20, bottom: 80, left: 20 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          startAngle={-90}
          endAngle={90}
          colors={{ datum: "data.color" }}
          borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
          enableArcLinkLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          tooltip={({ datum }) => (
            <div style={{ color: datum.data.color }}>
              <strong>{datum.data.label}:</strong>{" "}
              {formatNumber(datum.data.value)}
            </div>
          )}
          layers={[
            "arcs",
            "arcLinkLabels",
            "legends",
            ({ centerX, centerY }) => (
              <CenteredMetric
                centerX={centerX}
                centerY={centerY}
                percentageDifference={Number(percentageDifference)}
              />
            ),
          ]}
        />
      </div>
      <div className="tw-mt-[-50px] tw-pb-6" style={{ textAlign: "center" }}>
        {viewMode === "yearly" ? (
          <>
            <p className="tw-text-base">
              <strong>{`${i18n.t("label.thisYear")}: `}</strong>
              {formatNumber(dataCurrentYear.yearly)}
            </p>
            <p className="tw-text-base">
              <strong>{`${i18n.t("label.lastYear")}: `}</strong>
              {formatNumber(dataLastYear.yearly)}
            </p>
          </>
        ) : (
          <>
            <p className="tw-text-base">
              <strong>{`${i18n.t("label.thisMonth")}: `}</strong>
              {formatNumber(dataCurrentYear.monthly)}
            </p>
            <p className="tw-text-base">
              <strong>{`${i18n.t("label.lastMonth")}: `}</strong>
              {formatNumber(dataLastYear.monthly)}
            </p>
          </>
        )}
        <p
          style={{
            color:
              viewMode === "yearly" && yearlyDifference >= 0 ? "green" : "red",
          }}
          className="tw-text-base"
        >
          <strong>{`${i18n.t("label.difference")}: `}</strong>
          {viewMode === "yearly"
            ? formatNumber(yearlyDifference)
            : formatNumber(monthlyDifference)}
        </p>
      </div>
    </div>
  );
};

export { RevenuePerHourChartNivo };
