import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { ReactComponent as LogoSvg } from "../../img/logo.svg";
import { useBIReportQuery } from "../../api/rest/bi-reports";
import { ViewModeType } from "../../types/models/bireport";
import { Button } from "../../components/design-systems/Button/Button";
import { useParams } from "react-router-dom";
import { PageWrapper } from "../../components/PageWrapper";
import { TotalRevenueChartNivo } from "../../components/Charts/Nivo/TotalRevenueChartNivo/TotalRevenueChartNivo";
import { RevenuePerPatientChartNivo } from "../../components/Charts/Nivo/RevenuePerPatientChartNIvo";
import { RevenuePerHourChartNivo } from "../../components/Charts/Nivo/RevenuePerHourChartNivo";
import { UtilizationChartNivo } from "../../components/Charts/Nivo/UtilizatiomChartNivo";
import {
  BookingsPerTreatmentNivo,
  RevenuePerTreatmentNivo,
} from "../../components/Charts/Nivo/TreatmentChartNivo/TreatmentChartNivo";

const BiReportPage: React.FC = () => {
  const { t } = useTranslation();
  const { reportId } = useParams<{ reportId: string }>();
  const [viewMode, setViewMode] = useState<ViewModeType>("yearly");

  const { data: biReportData, isLoading: isBiReportLoading } = useBIReportQuery(
    reportId!,
    {
      enabled: !!reportId,
    }
  );

  if (isBiReportLoading) {
    return <Loading />;
  }

  if (!biReportData) {
    return null;
  }

  const {
    treatments,
    avg_rev_pr_hour,
    avg_rev_pr_pt,
    total_revenue,
    utilization,
  } = biReportData;

  return (
    <PageWrapper className="max-md:tw-px-8 max-sm:tw-px-3">
      <div className="tw-pb-16">
        {isBiReportLoading && <Loading />}
        <div className="tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-dental-neutral-N6 tw-pb-4 max-sm:tw-mb-10">
          <LogoSvg className="tw-w-40" />
          <div>
            <h1>{t("label.insights")}</h1>
            <p className="max-sm:tw-hidden">{t("insights.subHeader")}</p>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-w-full">
          <div className="tw-fixed tw-z-50 tw-bottom-0 tw-left-0 tw-w-full tw-bg-white tw-border-t tw-border-gray-300 tw-py-2 tw-px-4 tw-shadow-md sm:tw-hidden">
            <div className="md:tw-max-w-md tw-mx-auto tw-flex tw-gap-2 tw-justify-center">
              <Button
                color={viewMode === "monthly" ? "dental-primary" : "secondary"}
                variant={viewMode === "monthly" ? "outline" : "solid"}
                className="tw-w-1/2"
                onClick={() => setViewMode("yearly")}
              >
                {t("label.yearly")}
              </Button>
              <Button
                color={viewMode === "yearly" ? "dental-primary" : "secondary"}
                variant={viewMode === "yearly" ? "outline" : "solid"}
                className="tw-w-1/2"
                onClick={() => setViewMode("monthly")}
              >
                {t("label.monthly")}
              </Button>
            </div>
          </div>

          <div className="md:tw-max-w-md tw-flex tw-gap-2 tw-py-10 tw-w-full max-sm:tw-hidden">
            <Button
              color={viewMode === "monthly" ? "dental-primary" : "secondary"}
              variant={viewMode === "monthly" ? "outline" : "solid"}
              className="tw-w-1/2"
              onClick={() => setViewMode("yearly")}
            >
              {t("label.yearly")}
            </Button>
            <Button
              color={viewMode === "yearly" ? "dental-primary" : "secondary"}
              variant={viewMode === "yearly" ? "outline" : "solid"}
              className="tw-w-1/2"
              onClick={() => setViewMode("monthly")}
            >
              {t("label.monthly")}
            </Button>
          </div>

          {biReportData && (
            <>
              <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-4">
                <TotalRevenueChartNivo
                  viewMode={viewMode}
                  totalRevenue={total_revenue}
                />
                <RevenuePerPatientChartNivo
                  viewMode={viewMode}
                  revenuePerPatient={avg_rev_pr_pt}
                />
                <RevenuePerHourChartNivo
                  viewMode={viewMode}
                  revenuePerHour={avg_rev_pr_hour}
                />
                <UtilizationChartNivo
                  viewMode={viewMode}
                  utilization={utilization}
                />
                <RevenuePerTreatmentNivo
                  viewMode={viewMode}
                  treatments={treatments}
                />
                <BookingsPerTreatmentNivo
                  viewMode={viewMode}
                  treatments={treatments}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export { BiReportPage };
