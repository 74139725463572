import { FileText } from "@phosphor-icons/react";
import PageWithHeroWrapper from "../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";

const Agreements = () => {
  return (
    <PageWithHeroWrapper
      title="Agreements"
      text="Manage and approve your Dentli agreements"
      IconComponent={FileText}
    >
      <></>
    </PageWithHeroWrapper>
  );
};

export default Agreements;
