import { useTranslation } from "react-i18next";
import { CalendarCheck } from "@phosphor-icons/react";
import { Button } from "../../components/design-systems/Button";

const OnlineBookingEmptyState = ({ onSetup }: { onSetup: () => void }) => {
  const { t } = useTranslation();

  return (
    <div
      id="booking"
      className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-20 md:tw-flex-row"
    >
      <div className="tw-relative tw-max-w-[450px] tw-text-center md:tw-text-left">
        <CalendarCheck
          className="tw-absolute tw-z-0 max-sm:tw-w-[300px] md:tw-w-[300px] lg:tw-w-[400px]"
          size={400}
          color="#EDEBF5"
          style={{
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%",
          }}
        />
        <div className="tw-z-10 tw-relative">
          <h1 className="tw-text-3xl tw-font-bold tw-pb-6">
            {t("onlineBooking.setup.title")}
          </h1>
          <p className="tw-pb-12 tw-text-base">
            {t("onlineBooking.setup.subTitle")}
          </p>
          <Button
            onClick={onSetup}
            className="tw-px-14 tw-font-semibold tw-text-sm"
          >
            {t("onlineBooking.setup")}
          </Button>
        </div>
      </div>
      <div>
        <img
          alt="online-booking"
          className="tw-w-[560px] max-md:tw-hidden"
          src={require("../../img/booking.png")}
        />
      </div>
    </div>
  );
};

export default OnlineBookingEmptyState;
