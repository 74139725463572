import { Pen, Plus, Storefront } from "@phosphor-icons/react";
import { useState } from "react";
import { useOrganisationsQuery } from "../../api/rest/organisation";
import { Loading } from "../../components/design-systems/Loading/Loading";
import { useClinicsQuery } from "../../api/rest/clinic";
import { useSession } from "../../context/session";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { Button } from "../../components/design-systems/Button/Button";
import CompanyMenu from "./CompanyMenu";
import AddUser from "../../components/AddUser/AddUser";
import EditCompanyInformationDialog from "./CompanySettings/EditCompanyInformationDialog";
import CompanyRoutes from "./routes";

type ActiveTabType = "clinics" | "users" | "settings";

const Company = () => {
  const { user, token, productAccess } = useSession();
  const { t } = useTranslation();
  const location = useLocation();

  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditCompanySettingsDialogOpen, setIsEditCompanySettingsDialogOpen] =
    useState(false);

  const clinicsQuery = useClinicsQuery();
  const organisationQuery = useOrganisationsQuery();
  const clinics = clinicsQuery.data || [];
  const organisation = organisationQuery.data || [];

  const isLoading = clinicsQuery.isLoading || organisationQuery.isLoading;
  const isError = clinicsQuery.isError || organisationQuery.isError;

  const openAddUserDialog = () => setIsAddUserDialogOpen(true);
  const closeAddUserDialog = () => setIsAddUserDialogOpen(false);
  const openEditCompanySettingsDialog = () =>
    setIsEditCompanySettingsDialogOpen(true);
  const closeEditCompanySettingsDialog = () =>
    setIsEditCompanySettingsDialogOpen(false);

  const getActiveTab = (): ActiveTabType => {
    if (location.pathname.includes("/clinics")) {
      return "clinics";
    } else if (location.pathname.includes("/users")) {
      return "users";
    } else if (location.pathname.includes("/settings")) {
      return "settings";
    } else {
      return "clinics"; // Default to "clinics" if no match
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !user || !token) {
    return null;
  }

  console.log({ organisation, clinics, productAccess });

  return (
    <PageWithHeroWrapper
      title="Company"
      text="Manage Company clinics and users on Dentli"
      IconComponent={Storefront}
      actions={
        <>
          {getActiveTab() === "users" && (
            <Button prefix={<Plus />} onClick={openAddUserDialog}>
              {t("label.addUser")}
            </Button>
          )}
          {getActiveTab() === "settings" && (
            <Button prefix={<Pen />} onClick={openEditCompanySettingsDialog}>
              {t("label.editCompany")}
            </Button>
          )}
        </>
      }
      submenu={<CompanyMenu activeTab={getActiveTab()} />}
    >
      <div>
        <CompanyRoutes />
      </div>
      {isAddUserDialogOpen && (
        <AddUser onClose={closeAddUserDialog} clinics={clinics} token={token} />
      )}
      {isEditCompanySettingsDialogOpen && (
        <EditCompanyInformationDialog
          onClose={closeEditCompanySettingsDialog}
        />
      )}
    </PageWithHeroWrapper>
  );
};

export type { ActiveTabType };
export default Company;
