const PenIcon = ({
  className,
  onClick,
  size = 20,
  ...props
}: {
  className?: string;
  onClick?: () => void;
  size?: number;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#7A69C0"
        d="M3 13h.733l7.384-7.383-.734-.734L3 12.267V13zm10.233-8.1L11.1 2.767l.7-.7a.95.95 0 01.7-.284.95.95 0 01.7.284l.733.733a.951.951 0 01.284.7.951.951 0 01-.284.7l-.7.7zm-.7.7l-8.4 8.4H2v-2.133l8.4-8.4L12.533 5.6zm-1.783-.35l-.367-.367.734.734-.367-.367z"
      ></path>
    </svg>
  );
};

export default PenIcon;
