import { useTranslation } from "react-i18next";
import { CalendarCheck, Plus } from "@phosphor-icons/react";
import { Button } from "../../components/design-systems/Button/Button";
import { useState } from "react";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";

import {
  useDeleteOnlineBookingMutation,
  useOnlineBookingQuery,
} from "../../api/rest/booking";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/design-systems/Loading/Loading";

import { notify } from "../../components/design-systems/Alert/notify";
import { ErrorResponse } from "../../components/AddEmployee/AddEmployee";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import OnlineBookingList from "./OnlineBookingList";
import OnlineBookingEmptyState from "./OnlineBookingEmptyState";
import AddOnlineBookingDialog from "../../components/AddOnlineBookingDialog/AddOnlineBookingDialog";

const OnlineBooking = () => {
  const { t } = useTranslation();
  const [isFormOpen, setFormOpen] = useState(false);
  const { clinicId } = useParams<{ clinicId: string }>(); // URL clinic ID
  const queryClient = useQueryClient();

  const onlineBookingQuery = useOnlineBookingQuery(Number(clinicId));
  const onlineBookingData = onlineBookingQuery.data || [];

  const clinic = onlineBookingData.find(
    (clinic) => clinic.id === Number(clinicId)
  );

  const deleteOnlineBookingMutation = useDeleteOnlineBookingMutation({
    onSuccess: (data) => {
      notify(data.message, { type: "success" });
      queryClient.invalidateQueries(["onlineBooking"]);
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as ErrorResponse)?.message ||
        "An unexpected error occurred";
      notify(errorMessage, { type: "error" });
      console.error("Mutation error:", error);
    },
  });

  const handleDeleteBooking = (onlineBookingId: number) => {
    deleteOnlineBookingMutation.mutate({ onlineBookingId });
  };

  if (onlineBookingQuery.isLoading) {
    return <Loading />;
  }

  if (onlineBookingQuery.error || !clinic) {
    return null;
  }

  return (
    <div>
      <PageWithHeroWrapper
        title={t("label.onlineBooking")}
        text={t("onlineBooking.subTitle")}
        IconComponent={CalendarCheck}
        showBackButton="/booking"
        actions={
          clinic?.onlinebookings?.length > 0 && (
            <Button
              className="tw-rounded-lg tw-flex tw-items-center max-md:tw-w-full"
              onClick={() => setFormOpen(true)}
            >
              <Plus className="tw-mr-1" />
              <p>{t("label.add")}</p>
            </Button>
          )
        }
      >
        {clinic?.onlinebookings?.length > 0 ? (
          <OnlineBookingList
            clinic={clinic}
            clinicId={Number(clinicId)}
            onDeleteBooking={handleDeleteBooking}
          />
        ) : (
          <OnlineBookingEmptyState onSetup={() => setFormOpen(true)} />
        )}

        <AddOnlineBookingDialog
          isOpen={isFormOpen}
          onClose={() => setFormOpen(false)}
        />
      </PageWithHeroWrapper>
    </div>
  );
};

export default OnlineBooking;
