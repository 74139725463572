const LogoutIcon = ({
  className,
  size = 20,
  ...props
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      stroke="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3.75 17.5a1.2 1.2 0 01-.875-.375 1.2 1.2 0 01-.375-.875V3.75a1.2 1.2 0 01.375-.875A1.2 1.2 0 013.75 2.5h5.604c.177 0 .326.06.445.18.12.121.18.27.18.449a.61.61 0 01-.625.621H3.75v12.5h5.604c.177 0 .326.06.445.18.12.121.18.27.18.449a.61.61 0 01-.625.621H3.75zm11.354-6.875H8.125a.603.603 0 01-.445-.18.61.61 0 01-.18-.449.61.61 0 01.625-.621h6.938l-1.688-1.688a.564.564 0 01-.177-.437.64.64 0 01.198-.438.611.611 0 01.448-.187c.173 0 .323.063.448.188l2.77 2.77a.6.6 0 01.188.438.6.6 0 01-.188.437l-2.75 2.75a.554.554 0 01-.435.177.67.67 0 01-.448-.197.625.625 0 01-.179-.448c0-.174.063-.323.188-.448l1.666-1.667z"
      ></path>
    </svg>
  );
};

export default LogoutIcon;
