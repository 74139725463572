const PhoneIcon = ({
    className,
    size = 20,
    ...props
}: {
    className?: string;
    size?: number;
}) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 40 40"
            stroke="currentColor"
            {...props}
        >
            <path d="M33.75 36C29.8833 36 25.9417 35.0667 21.925 33.2C17.9083 31.3333 14.1833 28.6833 10.75 25.25C7.31667 21.8167 4.66667 18.0917 2.8 14.075C0.933333 10.0583 0 6.11667 0 2.25C0 1.60713 0.214283 1.07142 0.64285 0.64285C1.07142 0.214283 1.60713 0 2.25 0H9.25C9.71667 0 10.1167 0.166667 10.45 0.5C10.7833 0.833333 11.0167 1.25 11.15 1.75L12.4964 8.03205C12.5655 8.51068 12.5583 8.94167 12.475 9.325C12.3917 9.70833 12.2128 10.0371 11.9385 10.3113L6.95 15.35C7.81667 16.8167 8.73333 18.1833 9.7 19.45C10.6667 20.7167 11.7333 21.9167 12.9 23.05C14.1333 24.3167 15.4333 25.475 16.8 26.525C18.1667 27.575 19.6 28.5 21.1 29.3L25.85 24.4C26.1833 24.0333 26.5692 23.7833 27.0076 23.65C27.4459 23.5167 27.8767 23.4833 28.3 23.55L34.25 24.85C34.75 24.9833 35.1667 25.2507 35.5 25.6522C35.8333 26.0537 36 26.5029 36 27V33.75C36 34.3929 35.7857 34.9286 35.3572 35.3572C34.9286 35.7857 34.3929 36 33.75 36ZM5.45 12.6L9.5 8.5L8.35 3H3C3.06667 4.4 3.29167 5.875 3.675 7.425C4.05833 8.975 4.65 10.7 5.45 12.6ZM23.9 30.75C25.2667 31.3833 26.75 31.9 28.35 32.3C29.95 32.7 31.5 32.9333 33 33V27.65L27.85 26.6L23.9 30.75Z" fill="black" />
        </svg>

    );
};

export default PhoneIcon;
