import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import {
  getPractitionersPerClinic,
  useAddUserMutation,
} from "../../api/rest/company";
import { AxiosError } from "axios";
import { notify } from "../design-systems/Alert/notify";
import { RadioGroup } from "../design-systems/RadioGroup/RadioGroup";
import { ErrorResponse } from "../AddEmployee/AddEmployee";
import { AccessLevel } from "../../types/models/user";
import { useState } from "react";
import {
  FormGroup,
  FormLabel,
  FormInput,
  FormError,
} from "../design-systems/FormGroup/FormGroup";
import { StepDialog } from "../design-systems/Dialog/StepDialog/StepDialog";
import { PageWrapper } from "../PageWrapper";
import { Button } from "../design-systems/Button/Button";
import { Description } from "../design-systems/Description/Description";
import { Clinic } from "../../types/models/company";
import Select, { MultiValue } from "react-select";
import { Tooltip } from "../design-systems/Tooltip/Tooltip";
import { Info } from "@phosphor-icons/react";

type Step = "USER_ACCESS" | "USER_INFO";

const steps: Record<Step, number> = {
  USER_ACCESS: 0,
  USER_INFO: 1,
};

type FormValues = {
  access: AccessLevel;
  phone: string;
  email: string;
  clinics: { label: string; value: number }[];
  practitioner: { label: string; value: number } | null;
  nonPractitionerName?: string;
};

type AddUserProps = {
  onClose: () => void;
  clinics: Clinic[];
  token: string;
};

const AddUser: React.FC<AddUserProps> = ({ onClose, clinics, token }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      access: "practitioner",
      phone: "",
      clinics: [],
      practitioner: null,
    },
  });

  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState(steps.USER_ACCESS);
  const [availablePractitioners, setAvailablePractitioners] = useState<
    { label: string; value: number }[]
  >([]);
  const [isNonPractitioner, setIsNonPractitioner] = useState(false);

  const { mutate: addUserMutation, isLoading: addUserLoading } =
    useAddUserMutation({
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as ErrorResponse)?.message ||
          "An unexpected error occurred";
        notify(errorMessage, { type: "error" });
      },
      onSuccess: () => {
        notify("You have successfully added user");
        queryClient.invalidateQueries();
      },
    });

  const userAccessOptions = [
    {
      value: "full",
      label:
        "Access to all clinic- and user data from the clinic(s) the admin is connected to",
      title: "Full access",
    },
    {
      value: "practitioner",
      label:
        "Limited access to the admin’s own data and their clinic benchmarks",
      title: "Practitioner access",
    },
  ];

  const clinicOptions = clinics.map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  const fetchPractitioners = async (clinicId: number) => {
    if (!token) return [];
    const practitioners = await getPractitionersPerClinic(token, clinicId);
    return practitioners.map((practitioner) => ({
      label: practitioner.name,
      value: practitioner.id,
    }));
  };

  const handleClinicChange = async (
    selectedOptions: MultiValue<{ label: string; value: number }>
  ) => {
    const clinicIds = selectedOptions.map((option) => option.value);
    const allPractitioners: { label: string; value: number }[] = [];

    for (const clinicId of clinicIds) {
      const practitioners = await fetchPractitioners(clinicId);
      allPractitioners.push(...practitioners);
    }
    setAvailablePractitioners(allPractitioners);
  };

  const onSubmit = (data: FormValues) => {
    const formattedData = {
      access_level: isNonPractitioner ? "full" : data.access,
      name: isNonPractitioner
        ? data.nonPractitionerName
        : data.practitioner?.label || "",
      clinics: data.clinics.map((clinic) => ({
        clinic_id: clinic.value,
        practitioner_id: isNonPractitioner
          ? null
          : data.practitioner?.value || null,
      })),
      email: data.email,
      phone: data.phone,
    };

    addUserMutation(formattedData);
    closeDialog();
    console.log({ formattedData, data });
  };

  const closeDialog = () => {
    setCurrentStep(steps.USER_ACCESS);
    reset();
    onClose();
  };

  const stepComponent = () => {
    switch (currentStep) {
      case steps.USER_ACCESS:
        return (
          <Controller
            control={control}
            name="access"
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <h1 className="tw-font-normal tw-pb-12">Select user access</h1>
                <div className="tw-space-y-6">
                  {userAccessOptions.map((option) => (
                    <RadioGroup.Option key={option.value} value={option.value}>
                      {({ checked }) => (
                        <RadioGroup.OptionContent checked={checked}>
                          <Description
                            title={option.title}
                            description={option.label}
                          />
                        </RadioGroup.OptionContent>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            )}
          />
        );
      case steps.USER_INFO:
        return (
          <div>
            <h1 className="tw-font-normal tw-pb-12">
              Fill in user information
            </h1>
            <div className="tw-space-y-6 tw-w-full tw-max-w-lg">
              <FormGroup>
                <FormLabel htmlFor="clinics">Clinics</FormLabel>
                <Controller
                  control={control}
                  name="clinics"
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      options={clinicOptions}
                      className="tw-w-full"
                      onChange={(selected) => {
                        console.log({ selected });
                        field.onChange(selected);
                        handleClinicChange(selected);
                      }}
                    />
                  )}
                />
                <FormError>{errors.clinics?.message}</FormError>
              </FormGroup>
              <FormGroup>
                {isNonPractitioner ? (
                  <>
                    <FormLabel htmlFor="nonPractitionerName">Name</FormLabel>
                    <FormInput
                      id="nonPractitionerName"
                      placeholder="Enter name"
                      {...register("nonPractitionerName", {
                        required:
                          "Name is required when adding a non-practitioner",
                      })}
                    />
                    <FormError>{errors.nonPractitionerName?.message}</FormError>
                    <div
                      className="tw-cursor-pointer tw-mt-2 tw-flex tw-items-center tw-gap-1.5"
                      onClick={() => setIsNonPractitioner(false)}
                    >
                      <p className="tw-text-dental-primary-500">
                        Select from existing practitioners
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <FormLabel htmlFor="practitioner">Practitioners</FormLabel>
                    <Controller
                      control={control}
                      name="practitioner"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={availablePractitioners}
                          className="tw-w-full"
                        />
                      )}
                    />
                    <FormError>{errors.practitioner?.message}</FormError>
                    <Tooltip
                      placement="bottom-start"
                      content="Enter the name of the non-practitioner. Non-practitioners are always given full access."
                    >
                      <div
                        className="tw-cursor-pointer tw-mt-2 tw-flex tw-items-center tw-gap-1.5"
                        onClick={() => setIsNonPractitioner(true)}
                      >
                        <p className="tw-text-dental-primary-500">
                          Add a non-practitioner
                        </p>
                        <Info size={20} />
                      </div>
                    </Tooltip>
                  </>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="phone">Phone</FormLabel>
                <FormInput
                  id="phone"
                  {...register("phone", {
                    required: "Phone number is required",
                  })}
                />
                <FormError>{errors.phone?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormInput
                  id="email"
                  {...register("email", { required: "Email is required" })}
                />
                <FormError>{errors.email?.message}</FormError>
              </FormGroup>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <StepDialog
      totalSteps={2}
      currentStep={currentStep}
      actions={
        <>
          {currentStep < 1 && (
            <Button color="primary" variant="outline" onClick={closeDialog}>
              Cancel
            </Button>
          )}
          {currentStep < 1 && (
            <Button onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </Button>
          )}
          {currentStep > 0 && (
            <Button
              color="primary"
              variant="outline"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Back
            </Button>
          )}
          {currentStep === 1 && (
            <Button onClick={handleSubmit(onSubmit)} isLoading={addUserLoading}>
              Save
            </Button>
          )}
        </>
      }
      isLoading={false}
      onClose={closeDialog}
    >
      <PageWrapper className="tw-flex tw-mx-auto tw-h-full tw-max-w-xl tw-flex-col tw-px-0 tw-pb-12 tw-pt-4">
        {stepComponent()}
      </PageWrapper>
    </StepDialog>
  );
};

export default AddUser;
