import React from "react";
import clsx from "clsx";

type MultiToggleButtonGroupProps = {
  options: { value: string; label: string }[];
  value: string[];
  onChange: (value: string[]) => void;
};

const MultiToggleButtonGroup: React.FC<MultiToggleButtonGroupProps> = ({
  options,
  value,
  onChange,
}) => {
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: string
  ) => {
    event.preventDefault();
    const newValue = value.includes(selectedValue)
      ? value.filter((v) => v !== selectedValue)
      : [...value, selectedValue];
    onChange(newValue);
  };

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {options.map((option) => (
        <button
          key={option.value}
          onClick={(event) => handleToggle(event, option.value)}
          className={clsx(
            "tw-m-1 tw-px-4 tw-py-2 tw-font-medium tw-rounded  tw-cursor-pointer",
            {
              "tw-bg-dental-primary-P2 text-white": value.includes(
                option.value
              ),
              "tw-bg-dental-primary-P5 text-black": !value.includes(
                option.value
              ),
            }
          )}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default MultiToggleButtonGroup;
