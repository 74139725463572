import { useParams } from "react-router-dom";
import { useUsersFromClinicQuery } from "../../api/rest/company";
import UserRow from "../../components/design-systems/UserRow/UserRow";

const SingleClinicUsers = () => {
  const { clinicId } = useParams<{ clinicId: string }>();

  const usersInClinicQuery = useUsersFromClinicQuery(
    clinicId ? Number(clinicId) : 0,
    {
      enabled: !!clinicId,
    }
  );

  const users = usersInClinicQuery.data || [];

  return (
    <div className="tw-flex-1">
      {users.map((user) => (
        <UserRow key={user.id} user={user} />
      ))}
    </div>
  );
};

export default SingleClinicUsers;
