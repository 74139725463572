import { Clinic, Organisation } from "../../types/models/company";

const genericColors = {
  primary_color: "#565656",
  secondary_color: "#EBEBEB",
  base_color: "#FFFFFF",
  accent_color: "#161616",
};

const getClinicById = (
  clinics: Clinic[],
  clinicId: string | undefined
): Clinic | undefined => {
  return clinics.find((clinic) => clinic.id === Number(clinicId));
};

const getBrandColors = (clinic: Clinic, organisation: Organisation[]) => {
  const clinicBrandColorsDefined =
    clinic.brand_colors &&
    (clinic.brand_colors.primary_color !== "undefined" ||
      clinic.brand_colors.secondary_color !== "undefined" ||
      clinic.brand_colors.base_color !== "undefined" ||
      clinic.brand_colors.accent_color !== "undefined");

  const brandColors = clinicBrandColorsDefined
    ? clinic.brand_colors
    : organisation[0]?.brand_colors || genericColors;

  return {
    primary_color: brandColors?.primary_color || genericColors.primary_color,
    secondary_color:
      brandColors?.secondary_color || genericColors.secondary_color,
    base_color: brandColors?.base_color || genericColors.base_color,
    accent_color: brandColors?.accent_color || genericColors.accent_color,
  };
};

export { getClinicById, getBrandColors };
