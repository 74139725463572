import React, { useState } from "react";
import ProspectRow from "./ProspectRow";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/design-systems/Button";

type CommunicationTableProps = {
  patientProspects: any[];
  clinicId: number;
};

const CommunicationTable: React.FC<CommunicationTableProps> = ({
  patientProspects,
  clinicId,
}) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const prospectsPerPage = 30;

  const totalPages = Math.ceil(patientProspects.length / prospectsPerPage);

  const indexOfLastProspect = currentPage * prospectsPerPage;
  const indexOfFirstProspect = indexOfLastProspect - prospectsPerPage;
  const currentProspects = patientProspects.slice(
    indexOfFirstProspect,
    indexOfLastProspect
  );

  const handleRowClick = (prospectId: number) => {
    navigate(`/communication/${clinicId}/${prospectId}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="tw-overflow-x-auto tw-mb-14">
      {/* Table for larger screens */}
      <table className="tw-min-w-full tw-border-gray-300 tw-hidden md:tw-table ">
        <thead>
          <tr>
            <th className="tw-px-4 tw-py-2 tw-border-b tw-text-left tw-text-sm tw-font-semibold tw-text-dental-neutral-N4 tw-w-1/12">
              Type
            </th>
            <th className="tw-px-4 tw-py-2 tw-border-b tw-text-left tw-text-sm tw-font-semibold tw-text-dental-neutral-N4 tw-w-1/12">
              Patient ID
            </th>
            <th className="tw-px-4 tw-py-2 tw-border-b tw-text-left tw-text-sm tw-font-semibold tw-text-dental-neutral-N4 tw-w-1/12">
              Name
            </th>
            <th className="tw-px-4 tw-py-2 tw-border-b tw-text-left tw-text-sm tw-font-semibold tw-text-dental-neutral-N4 tw-w-1/12">
              Status
            </th>
            <th className="tw-px-4 tw-py-2 tw-border-b tw-text-left tw-text-sm tw-font-semibold tw-text-dental-neutral-N4 tw-w-1/12">
              Last Action
            </th>
            <th className="tw-px-4 tw-py-2 tw-border-b tw-text-left tw-text-sm tw-font-semibold tw-text-dental-neutral-N4 tw-w-1/12">
              Reminder Date
            </th>
          </tr>
        </thead>
        <tbody className="pb-5">
          {currentProspects.map((prospect: any) => (
            <ProspectRow
              key={prospect.id}
              prospect={prospect}
              clinicId={clinicId}
              onClick={handleRowClick}
            />
          ))}
        </tbody>
      </table>

      {/* Mobile view */}
      <div className="tw-block md:tw-hidden">
        {currentProspects.map((prospect: any) => (
          <div
            key={prospect.id}
            className="tw-mb-4 tw-border tw-rounded-md tw-p-4"
          >
            <p>
              <strong>Reason:</strong> {prospect.reasons.join(", ")}
            </p>
            <p>
              <strong>Patient ID:</strong> {prospect.id}
            </p>
            <p>
              <strong>Name:</strong> {prospect.name}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              {prospect.actionstatus?.latest_progress?.type?.label || ""}
            </p>
            <p>
              <strong>Last Action:</strong>{" "}
              {prospect.actionstatus?.latest_event?.created_at || ""}
            </p>
            <p>
              <strong>Reminder Date:</strong>{" "}
              {prospect?.actionstatus?.next_reminder?.reminder_date || ""}
            </p>
          </div>
        ))}
      </div>

      {/* Pagination controls */}
      {totalPages > 1 && (
        <div className="tw-flex tw-justify-between tw-mt-4 tw-px-4">
          <Button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            variant="outline"
          >
            Previous
          </Button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            variant="outline"
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default CommunicationTable;
