import { useParams } from "react-router-dom";
import { useFullUrlFromShortlinkQuery } from "../api/shortlink";
import { Navigate } from "react-router-dom";
import { Loading } from "../components/design-systems/Loading/Loading";
import { useEffect } from "react";

const ShortlinkRedirect = () => {
  const { shortlink } = useParams();
  const { data, isLoading, error } = useFullUrlFromShortlinkQuery(
    shortlink || ""
  );

  useEffect(() => {
    if (!isLoading && !error && data) {
      window.location.href = data.full_url;
    }
  }, [data, isLoading, error]);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Navigate to="/" replace />;
  }

  return <Navigate to={data.full_url} replace />;
};

export default ShortlinkRedirect;
