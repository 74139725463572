import { Megaphone } from "@phosphor-icons/react";
import PageWithHeroWrapper from "../../components/design-systems/PageWithHeroWrapper/PageWithHeroWrapper";
import { useClinicsQuery } from "../../api/rest/clinic";
import { Link } from "react-router-dom";
import ClinicRow from "../../components/design-systems/ClinicRow/ClinicRow";
import { useSession } from "../../context/session";
import { Clinic } from "../../types/models/company";

const Communication = () => {
  const { productAccess } = useSession();
  const clinicsQuery = useClinicsQuery();
  const clinicsData = clinicsQuery.data || [];

  const communicationProduct = productAccess?.find(
    (product) => product.name === "Communication"
  );

  const clinicsFromCommunicationProduct =
    communicationProduct?.products.flatMap((product: any) => product.clinics) ||
    [];

  console.log({
    productAccess,
    clinicsData,
    communicationProduct,
    clinicsFromCommunicationProduct,
  });

  return (
    <PageWithHeroWrapper
      title="Communication"
      text="communication"
      IconComponent={Megaphone}
    >
      <div className="">
        {clinicsFromCommunicationProduct.length > 0 ? (
          clinicsFromCommunicationProduct.map((clinic: Clinic) => (
            <Link
              key={clinic.id}
              to={`/communication/${clinic.id}`}
              className="tw-no-underline"
            >
              <ClinicRow clinic={clinic} />
            </Link>
          ))
        ) : (
          <p>No clinics available for the Communication product.</p>
        )}
      </div>
    </PageWithHeroWrapper>
  );
};

export default Communication;
