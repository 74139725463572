import React from "react";
import Accordion from "../../components/design-systems/Accordion/Accordion";
import { Description } from "../../components/design-systems/Description/Description";
import { ActionStatusAbleDetailType } from "../../api/rest/communication/communication.types";

type ActionStatusType = {
  actionStatusData: ActionStatusAbleDetailType;
};

const ProspectsAccordion: React.FC<ActionStatusType> = ({
  actionStatusData,
}) => {
  return (
    <div className="tw-space-y-6 tw-py-10">
      <Accordion title="Appointments">
        {actionStatusData.patient.appointments.map((appointment, index) => (
          <div
            className="tw-grid tw-pl-4 sm:tw-grid-cols-3 tw-grid-cols-1 tw-gap-4 tw-my-4 tw-py-3 tw-border-2 tw-border-gray-200 tw-rounded-xl"
            key={index}
          >
            <Description
              title="Type"
              titleSize="base"
              titleWeight="medium"
              description={appointment.appointment_type.name}
            />
            <Description
              title="Date"
              titleSize="base"
              titleWeight="medium"
              description={appointment.apt_date}
            />
            <Description
              title="Time"
              titleSize="base"
              titleWeight="medium"
              description={appointment.apt_time}
            />
          </div>
        ))}
      </Accordion>

      <Accordion title="Treatments">
        {actionStatusData.patient.treatments.map((treatment, index) => (
          <div
            className="tw-grid tw-pl-4 tw-gap-4 sm:tw-grid-cols-3 tw-grid-cols-1  tw-my-4 tw-py-3 tw-border-2 tw-border-gray-200 tw-rounded-xl"
            key={index}
          >
            <div className="tw-col-span-2">
              <Description
                title="Type"
                titleSize="base"
                titleWeight="medium"
                description={`${treatment.treatment_type?.name || "N/A"}`}
              />
            </div>
            <div className="tw-col-span-1">
              <Description
                title="Date"
                titleSize="base"
                titleWeight="medium"
                description={treatment.treatment_date}
                className="tw-col-span-1"
              />
            </div>
          </div>
        ))}
      </Accordion>

      <Accordion title="Treatment Proposals">
        {actionStatusData.patient.treatment_proposals.map((proposal, index) => (
          <div
            className="tw-grid tw-pl-4 tw-gap-4 sm:tw-grid-cols-3 tw-grid-cols-1  tw-my-4 tw-py-3 tw-border-2 tw-border-gray-200 tw-rounded-xl"
            key={index}
          >
            <Description
              title="Type"
              titleSize="base"
              titleWeight="medium"
              description={proposal.type}
            />
            <Description
              title="Description"
              titleSize="base"
              titleWeight="medium"
              description={proposal.description}
            />
            <Description
              title="Status"
              titleSize="base"
              titleWeight="medium"
              description={proposal.status}
            />
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default ProspectsAccordion;
