const DashboardIcon = ({
  className,
  size = 20,
  ...props
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      stroke="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.389 7.917a.672.672 0 01-.495-.2.672.672 0 01-.2-.495V3.194c0-.196.067-.361.2-.494a.672.672 0 01.495-.2h5.417c.196 0 .361.067.494.2s.2.298.2.494v4.028a.672.672 0 01-.2.495.672.672 0 01-.494.2h-5.417zm-8.195 2.777a.672.672 0 01-.494-.2.672.672 0 01-.2-.494V3.194c0-.196.067-.361.2-.494a.672.672 0 01.494-.2h5.417c.197 0 .362.067.495.2s.2.298.2.494V10a.672.672 0 01-.2.495.672.672 0 01-.495.2H3.194zM11.39 17.5a.672.672 0 01-.495-.2.672.672 0 01-.2-.494V10c0-.197.067-.362.2-.495a.672.672 0 01.495-.2h5.417c.196 0 .361.067.494.2s.2.298.2.495v6.806a.672.672 0 01-.2.494.672.672 0 01-.494.2h-5.417zm-8.195 0a.672.672 0 01-.494-.2.672.672 0 01-.2-.494v-4.028c0-.197.067-.362.2-.495a.672.672 0 01.494-.2h5.417c.197 0 .362.067.495.2s.2.298.2.495v4.028a.672.672 0 01-.2.494.672.672 0 01-.495.2H3.194z"
      ></path>
    </svg>
  );
};

export default DashboardIcon;
