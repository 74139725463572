import { CalendarPlus } from "@phosphor-icons/react";
import {
  UtilizationType,
  ViewModeType,
} from "../../../../types/models/bireport";
import { ResponsiveBar } from "@nivo/bar";

const UtilizationChartNivo: React.FC<{
  viewMode: ViewModeType;
  utilization: UtilizationType;
}> = ({ viewMode, utilization }) => {
  let chartData = [];

  if (viewMode === "yearly") {
    const yearlyData = utilization?.year_to_date || {};
    chartData = [
      {
        Appointments: yearlyData.appointments || 0,
        "Available Slots": yearlyData.available || 0,
        Blockings: yearlyData.blockings || 0,
      },
    ];
  } else {
    const last30DaysData = utilization?.period?.last_30_days || {};
    chartData = [
      {
        Appointments: last30DaysData.appointments || 0,
        "Available Slots": last30DaysData.available || 0,
        Blockings: last30DaysData.blockings || 0,
      },
    ];
  }

  const getColor = (bar: { id: any }) => {
    switch (bar.id) {
      case "Appointments":
        return "hsl(351, 100%, 86%)";
      case "Available Slots":
        return "hsl(197, 71%, 73%)";
      case "Blockings":
        return "hsl(263, 51%, 77%)";
      default:
        return "#ccc";
    }
  };

  return (
    <div className="tw-relative tw-shadow-2xl">
      <div className="tw-flex tw-items-center tw-justify-between tw-px-6 tw-pt-6">
        <h1>Utilization overview</h1>
        <CalendarPlus size={32} weight="fill" />
      </div>
      <div style={{ height: "400px" }}>
        <ResponsiveBar
          data={chartData}
          keys={["Appointments", "Available Slots", "Blockings"]}
          indexBy="period"
          groupMode="grouped"
          margin={{ top: 100, right: 20, bottom: 50, left: 60 }}
          padding={0.2}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={getColor}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendPosition: "middle",
            legendOffset: -40,
            tickValues: 5,
          }}
          enableGridX={false}
          enableGridY={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          borderRadius={4}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: -60,
              itemsSpacing: 20,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
        />
      </div>
    </div>
  );
};

export { UtilizationChartNivo };
