import { AxiosError } from "axios";
import axiosInstance from "../../../src/axiosConfig";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useSession } from "../../context/session";

type UpdateUserDataType = {
  id: number;
  name: string;
  email: string;
  phone: string;
  access_level: string;
  phone_verified_at: string | null;
  language: string;
  photo: string;
  deleted_at: string | null;
};

type UpdateUserResponseType = {
  message: string;
  data: UpdateUserDataType;
};

type UpdateUserRequestType = {
  userId: number;
  formData: FormData;
};

const updateUser = async (
  userId: number,
  formData: FormData,
  token: string
): Promise<UpdateUserResponseType> => {
  const response = await axiosInstance.post(
    `/api/public/v2/users/${userId}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to update user");
  }

  return response.data;
};

const useUpdateUserMutation = (
  options?: UseMutationOptions<
    UpdateUserResponseType,
    AxiosError,
    UpdateUserRequestType
  >
) => {
  const { token } = useSession();

  return useMutation<UpdateUserResponseType, AxiosError, UpdateUserRequestType>(
    ({ userId, formData }) => updateUser(userId, formData, token!),
    options
  );
};

export { useUpdateUserMutation };
