import clsx from "clsx";
import React from "react";

type IconCountButtonProps = {
  icon?: React.ReactElement;
  text: string;
  count?: number;
  color?: "gray" | "purple" | "red";
  className?: string;
  transparentBackground?: boolean;
  textSize?: "xs" | "sm" | "base" | "lg";
  textWeight?: "normal" | "medium" | "semibold" | "bold";
};

const IconCountButton: React.FC<IconCountButtonProps> = ({
  icon,
  text,
  count,
  color = "gray",
  className,
  transparentBackground = false,
  textSize = "md",
  textWeight = "medium",
}) => {
  const backgroundColorClass = transparentBackground
    ? "tw-bg-transparent"
    : color === "purple"
      ? "tw-bg-dental-primary-P2"
      : color === "red"
        ? "tw-bg-dental-semantic-S1"
        : "tw-bg-dental-neutral-N8";

  const textColorClass =
    color === "purple"
      ? "tw-text-dental-neutral-N10"
      : color === "red"
        ? "tw-text-dental-semantic-S1"
        : "tw-text-dental-neutral-N1";

  const textSizeClass =
    textSize === "xs"
      ? "tw-text-xs"
      : textSize === "sm"
        ? "tw-text-sm"
        : textSize === "lg"
          ? "tw-text-lg"
          : "tw-text-base";

  const textWeightClass =
    textWeight === "normal"
      ? "tw-font-normal"
      : textWeight === "semibold"
        ? "tw-font-semibold"
        : textWeight === "bold"
          ? "tw-font-bold"
          : "tw-font-medium";

  return (
    <button
      className={clsx(
        "tw-flex tw-items-center tw-text tw-gap-[6px] tw-p-[5px] tw-rounded-[4px]",
        backgroundColorClass,
        textColorClass,
        className
      )}
    >
      {icon}
      <p className={clsx(textSizeClass, textWeightClass)}>
        {text} {count && <span>{count}</span>}
      </p>
    </button>
  );
};

export default IconCountButton;
