import { ResponsivePie } from "@nivo/pie";
import { Tooth } from "@phosphor-icons/react";
import {
  TreatmentDataType,
  TreatmentsType,
  ViewModeType,
} from "../../../../types/models/bireport";

const truncateString = (str: string | undefined, num: number) => {
  if (!str) return ""; // Return an empty string or some default text if the input is undefined
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

const preparePieChartData = (
  treatments: TreatmentsType,
  viewMode: ViewModeType,
  isRevenue: boolean
) => {
  const dataKey = viewMode === "yearly" ? "year_to_date" : "month_to_date";
  const currentYear = new Date().getFullYear().toString();

  const currentData: TreatmentDataType[] =
    treatments[dataKey][currentYear] || [];

  return currentData.map((data) => ({
    id: truncateString(data.treatment_type?.name, 15),
    label: truncateString(data.treatment_type?.name, 15),
    value: isRevenue ? parseFloat(data.revenue) : data.amount,
  }));
};

const RevenuePerTreatmentNivo: React.FC<{
  treatments: TreatmentsType;
  viewMode: ViewModeType;
}> = ({ treatments, viewMode }) => {
  const revenueChartData = preparePieChartData(treatments, viewMode, true);

  return (
    <div className="tw-shadow-2xl tw-relative">
      <div className="tw-flex tw-items-center tw-justify-between tw-px-6 tw-pt-8 sm:tw-pb-8">
        <h1>Revenue per treatment</h1>
        <Tooth weight="fill" size={32} />
      </div>
      <div className="tw-mx-4" style={{ height: "400px" }}>
        <ResponsivePie
          data={revenueChartData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "set3" }} // Use a predefined color scheme or customize it as needed
          borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsRadiusOffset={0.75}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          tooltip={({ datum }) => (
            <div style={{ color: datum.color }}>
              <strong>{datum.id}:</strong> {datum.value}
            </div>
          )}
        />
      </div>
    </div>
  );
};

const BookingsPerTreatmentNivo: React.FC<{
  treatments: TreatmentsType;
  viewMode: ViewModeType;
}> = ({ treatments, viewMode }) => {
  const amountChartData = preparePieChartData(treatments, viewMode, false);

  return (
    <div className="tw-shadow-2xl tw-relative">
      <div className="tw-flex tw-items-center tw-justify-between tw-px-6 tw-pt-8 sm:tw-pb-8">
        <h1>Bookings per treatment</h1>
        <Tooth weight="fill" size={32} />
      </div>
      <div className="tw-mx-4" style={{ height: "400px" }}>
        <ResponsivePie
          data={amountChartData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "set3" }} // Use a predefined color scheme or customize it as needed
          borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsRadiusOffset={0.75}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          tooltip={({ datum }) => (
            <div style={{ color: datum.color }}>
              <strong>{datum.id}:</strong> {datum.value}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export { RevenuePerTreatmentNivo, BookingsPerTreatmentNivo };
