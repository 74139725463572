import type React from "react";
import { forwardRef } from "react";

import {
  CheckCircle,
  WarningOctagon,
  Info,
  Warning,
} from "@phosphor-icons/react";
import clsx from "clsx";

type AlertType = "error" | "neutral" | "success" | "warning";

type Size = "md" | "sm";

type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
  type?: AlertType;
  size?: Size;
  contentClassname?: string;
  "data-testid"?: string;
};

const Alert = forwardRef(
  (
    {
      children,
      type = "neutral",
      size = "md",
      className,
      contentClassname,
      "data-testid": dataTestId,
    }: AlertProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const Icon = {
      success: CheckCircle,
      error: WarningOctagon,
      warning: Warning,
      neutral: Info,
    }[type];

    const backgroundColor =
      type === "success" ? "tw-bg-success-light" : " tw-bg-error-light";

    return (
      <div
        ref={ref}
        className={clsx("tw-flex", backgroundColor, className, {
          "tw-gap-1 tw-text-sm": size === "sm",
          "tw-gap-2": size === "md",
        })}
        data-testid={dataTestId}
        role="alert"
      >
        <div className="tw-pt-0.5">
          <Icon
            className={clsx({
              "tw-text-alert-success": type === "success",
              "tw-text-alert-warning": type === "warning",
              "tw-text-alert-error": type === "error",
              "tw-h-5 tw-w-5": size === "md",
              "tw-h-4 tw-w-4": size === "sm",
            })}
          />
        </div>
        <span className={contentClassname}>{children}</span>
      </div>
    );
  }
);

export { Alert };
export type { AlertProps, AlertType };
