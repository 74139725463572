import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../src/axiosConfig";
import { BIReportType } from "../../types/models/bireport";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../components/AddEmployee/AddEmployee";
import { notify } from "../../components/design-systems/Alert/notify";

/**
 * Fetch BI Report by URL Report Identifier.
 *
 * @param urlReportIdentifier - The identifier for the report URL.
 * @returns The BI Report data as a generic object since the structure is large and unspecified.
 */

function isErrorWithMessage(error: any): error is ErrorResponse {
  return (error as ErrorResponse).message !== undefined;
}

const fetchBIReport = async (
  urlReportIdentifier: string
): Promise<BIReportType> => {
  const response = await axiosInstance.get(
    `/api/bireports/${urlReportIdentifier}`
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch BI report");
  }

  return response.data.data;
};

const useBIReportQuery = (
  urlReportIdentifier: string,
  options?: UseQueryOptions<BIReportType, AxiosError>
) => {
  return useQuery<BIReportType, AxiosError>(
    ["biReport", urlReportIdentifier],
    () => fetchBIReport(urlReportIdentifier),
    {
      ...options,
      enabled: !!urlReportIdentifier,
      onError: (error) => {
        let errorMessage =
          "An unexpected error occurred while fetching the BI report.";
        // Check if the error response structure matches ErrorResponse
        if (error.response && isErrorWithMessage(error.response.data)) {
          errorMessage = error.response.data.message;
        }
        notify(errorMessage, { type: "error" });
        console.error("Error fetching BI report:", error.message);
      },
    }
  );
};

export { fetchBIReport, useBIReportQuery };
