import clsx from "clsx";

type ThemeType =
  | "default"
  | "defaultFlip"
  | "disabled"
  | "grayBlack"
  | "whiteGray";
export type FontWeightType = keyof typeof fontWeightClass;
export type FontSizeType = keyof typeof fontSizeClass;

type DescriptionProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  align?: "left" | "right";
  theme?: ThemeType;
  size?: FontSizeType;
  titleSize?: FontSizeType;
  descriptionSize?: FontSizeType;
  titleWeight?: FontWeightType;
  descriptionWeight?: FontWeightType;
  gap?: number;
  className?: string;
};

const alignClass = { left: "", right: "tw-text-right" } as const;
const themeClass = {
  default: {
    body: "tw-text-dental-neutral-N1",
    secondary: "tw-text-secondary",
  },
  defaultFlip: {
    body: "tw-text-secondary",
    secondary: "tw-text-dental-neutral-N1",
  },
  disabled: {
    body: "tw-text-body tw-opacity-50",
    secondary: "tw-text-secondary tw-opacity-50",
  },
  grayBlack: {
    body: "tw-text-secondary",
    secondary: "tw-text-black",
  },
  whiteGray: {
    body: "tw-text-white",
    secondary: "tw-text-neutral-400",
  },
} as const;

const fontSizeClass = {
  sm: "tw-text-sm",
  base: "tw-text-base",
  lg: "tw-text-lg",
  xl: "tw-text-xl",
  xxl: "tw-text-2xl",
} as const;

const fontWeightClass = {
  normal: "tw-font-normal",
  medium: "tw-font-medium",
  semibold: "tw-font-semibold",
  bold: "tw-font-bold",
} as const;

const Description = ({
  title,
  description,
  align = "left",
  theme = "default",
  size = "sm",
  titleSize,
  descriptionSize,
  titleWeight = "normal",
  descriptionWeight = "normal",
  gap = 0,
  className,
}: DescriptionProps) => (
  <dl>
    <dt
      className={clsx(
        "tw-leading-5 ",
        fontSizeClass[titleSize || size],
        fontWeightClass[titleWeight],
        alignClass[align],
        themeClass[theme].body,
        `tw-mb-${gap}`,
        className
      )}
    >
      {title}
    </dt>
    {description && (
      <dd
        className={clsx(
          "",
          fontSizeClass[descriptionSize || size],
          fontWeightClass[descriptionWeight],
          alignClass[align],
          themeClass[theme].secondary
        )}
      >
        {description}
      </dd>
    )}
  </dl>
);

export { Description };
export type { DescriptionProps, ThemeType };
