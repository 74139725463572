import { AxiosError } from "axios";
import axiosInstance from "../../src/axiosConfig";
import { useSession } from "../context/session";
import { LoginCredentials } from "../types/models/auth";
import { LoginAsAdminResponse, LoginResponse } from "../types/models/user";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

const login = async (
  userCredentials: LoginCredentials
): Promise<LoginResponse> => {
  await axiosInstance.get("/sanctum/csrf-cookie");

  const response = await axiosInstance.post("/api/auth", userCredentials);

  if (response.status !== 200) {
    throw new Error("Network response was not ok");
  }

  return response.data;
};

const loginAsUser = async (
  userId: string,
  userCredentials: LoginCredentials
): Promise<LoginAsAdminResponse> => {
  const response = await axiosInstance.post(
    `/api/login-as?user_id=${userId}`,
    userCredentials
  );

  if (response.status !== 200) {
    throw new Error("Failed to log in as user.");
  }

  return response.data;
};

const useLoginMutation = (
  options?: UseMutationOptions<LoginResponse, AxiosError, LoginCredentials>
) => {
  const { handleSignedIn } = useSession();

  return useMutation<LoginResponse, AxiosError, LoginCredentials>(login, {
    onSuccess: (data) => {
      if (data.token) {
        handleSignedIn(data.user, data.token, data.productAccess);
      }
    },
    ...options,
  });
};

const useLoginAsMutation = (
  options?: UseMutationOptions<
    LoginAsAdminResponse,
    AxiosError,
    { userId: string; credentials: LoginCredentials }
  >
) => {
  const { handleSignedIn } = useSession();

  return useMutation<
    LoginAsAdminResponse,
    AxiosError,
    { userId: string; credentials: LoginCredentials }
  >(({ userId, credentials }) => loginAsUser(userId, credentials), {
    onSuccess: (data) => {
      if (data.token) {
        handleSignedIn(data.logged_in_as, data.token, data.productAccess);
      }
    },
    ...options,
  });
};

export { useLoginMutation, useLoginAsMutation };
export type { LoginCredentials };
