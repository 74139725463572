import { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import MobileNavbar from "../components/Navbar/MobileNavbar";
import { CaretLeft, CaretRight } from "@phosphor-icons/react"; 

const MainLayout = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const toggleNavbar = () => {
    setIsNavbarOpen((prev) => !prev);
  };

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-gray-100 lg:tw-flex-row">
      <div
        className={`tw-transition-all tw-duration-500 tw-ease-in-out ${
          isNavbarOpen ? "tw-translate-x-0" : "-tw-translate-x-[260px]"
        }`}
      >
        <Navbar />
      </div>
      <button
        className={`tw-fixed tw-top-1/2 tw-transform -tw-translate-y-1/2 ${
          isNavbarOpen ? "tw-left-[260px]" : "tw-left-2"
        } tw-z-50 tw-p-0.5 tw-py-8 tw-bg-dental-neutral-900 tw-rounded-lg max-lg:tw-hidden tw-text-white tw-transition-all tw-duration-500 tw-ease-in-out`}
        onClick={toggleNavbar}
      >
        {isNavbarOpen ? <CaretLeft size={20} /> : <CaretRight size={20} />}
      </button>
      <MobileNavbar className="lg:tw-hidden tw-w-full tw-z-50" />
      <div
        className={`tw-w-full tw-transition-all tw-duration-500 tw-ease-in-out ${
          isNavbarOpen ? "lg:tw-ml-[260px]" : "lg:tw-ml-0"
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
