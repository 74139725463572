import axiosInstance from "../../../src/axiosConfig";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { useSession } from "../../context/session";
import {
  AccessLevel,
  ClinicWithPractitioners,
  Practitioner,
  // PractitionersPerClinic,
  User,
} from "../../types/models/user";
import { AxiosError } from "axios";

const fetchUsers = async (token: string): Promise<User[]> => {
  if (!token) {
    throw new Error("No token found. User must be logged in to fetch users.");
  }

  const response = await axiosInstance.get("/api/public/v2/users", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Failed to fetch users");
  }

  return response.data.data;
};

const fetchUsersFromClinic = async (
  token: string,
  clinicId: number
): Promise<User[]> => {
  if (!token) {
    throw new Error("No token found. User must be logged in to fetch users.");
  }

  const response = await axiosInstance.get(
    `/api/public/v2/users/?clinic_id=${clinicId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch users from clinic");
  }

  return response.data.data;
};

const fetchCurrentUser = async (
  token: string,
  userId: number
): Promise<User> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch user data."
    );
  }

  const response = await axiosInstance.get(`/api/public/v2/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Failed to fetch user data");
  }

  return response.data.data;
};

const addUser = async (
  token: string,
  userData: {
    access_level: string;
    clinics: { clinic_id: number; practitioner_id: number | null }[];
    email: string;
    phone: string;
  }
): Promise<User> => {
  if (!token) {
    throw new Error("No token found. User must be logged in to add a user.");
  }

  const response = await axiosInstance.post("/api/public/v2/users", userData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Failed to add user");
  }

  return response.data.data;
};

const fetchPractitionersForUser = async (
  token: string,
  userId: number
): Promise<ClinicWithPractitioners[]> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch practitioners."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/users/${userId}/practitioners`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch practitioners");
  }

  return response.data.data;
};

const getPractitionersPerClinic = async (
  token: string,
  clinicId: number
): Promise<Practitioner[]> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch practitioners."
    );
  }

  const response = await axiosInstance.get(
    `/api/public/v2/clinics/${clinicId}/practitioners`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch practitioners");
  }

  return response.data.data;
};

type DeleteUserResponse = {
  message: string;
};

const deleteUser = async (
  token: string,
  userId: number
): Promise<DeleteUserResponse> => {
  if (!token) {
    throw new Error("No token found. User must be logged in to delete a user.");
  }

  const response = await axiosInstance.delete(
    `/api/public/v2/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to delete user");
  }

  return response.data;
};

const removeClinicFromUser = async (
  token: string,
  userId: number,
  clinicId: number
): Promise<void> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to remove a clinic from a user."
    );
  }

  const response = await axiosInstance.delete(
    `/api/public/v2/users/${userId}}/${clinicId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to remove clinic from user");
  }
};

const useUsersQuery = (options?: UseQueryOptions<User[], Error>) => {
  const { token } = useSession();

  return useQuery<User[], Error>(["users"], () => fetchUsers(token!), {
    ...options,
    enabled: !!token,
  });
};

const useUsersFromClinicQuery = (
  clinicId: number,
  options?: UseQueryOptions<User[], Error>
) => {
  const { token } = useSession();

  return useQuery<User[], Error>(
    ["usersFromClinic", clinicId],
    () => fetchUsersFromClinic(token!, clinicId),
    {
      ...options,
      enabled: !!token && !!clinicId,
    }
  );
};

const useCurrentUserQuery = (
  userId: number,
  options?: UseQueryOptions<User, Error>
) => {
  const { token } = useSession();

  return useQuery<User, Error>(
    ["user", userId],
    () => fetchCurrentUser(token!, userId),
    {
      ...options,
      enabled: !!token && !!userId,
    }
  );
};

const useAddUserMutation = (
  options?: UseMutationOptions<
    User,
    AxiosError,
    {
      access_level: AccessLevel;
      clinics: { clinic_id: number; practitioner_id: number | null }[];
      email: string;
      phone: string;
    }
  >
) => {
  const { token } = useSession();

  return useMutation<
    User,
    AxiosError,
    {
      access_level: AccessLevel;
      clinics: { clinic_id: number; practitioner_id: number | null }[];
      email: string;
      phone: string;
    }
  >((userData) => addUser(token!, userData), {
    ...options,
    // onSuccess, onError, etc.
  });
};

const usePractitionersForUserQuery = (
  userId: number,
  options?: UseQueryOptions<ClinicWithPractitioners[], Error>
) => {
  const { token, user } = useSession();
  const isAdminOrFullAccess =
    user?.access_level === "admin" || user?.access_level === "full";

  return useQuery<ClinicWithPractitioners[], Error>(
    ["practitionersForUser", userId],
    () => fetchPractitionersForUser(token!, userId),
    {
      ...options,
      enabled: !!token && !!userId && isAdminOrFullAccess,
    }
  );
};

const useGetPractitionersPerClinic = (
  clinicId: number,
  options?: UseQueryOptions<Practitioner[], Error>
) => {
  const { token } = useSession();

  return useQuery<Practitioner[], Error>(
    ["practitionersPerClinic", clinicId],
    () => getPractitionersPerClinic(token!, clinicId),
    {
      ...options,
      enabled: !!token && !!clinicId,
    }
  );
};

const useDeleteUserMutation = (
  options?: UseMutationOptions<DeleteUserResponse, AxiosError, number>
) => {
  const { token } = useSession();

  return useMutation<DeleteUserResponse, AxiosError, number>(
    (userId) => deleteUser(token!, userId),
    {
      ...options,
    }
  );
};

const useRemoveClinicFromUserMutation = (
  options?: UseMutationOptions<
    void,
    Error,
    { userId: number; clinicId: number }
  >
) => {
  const { token } = useSession();

  return useMutation<void, Error, { userId: number; clinicId: number }>(
    ({ userId, clinicId }) => removeClinicFromUser(token!, userId, clinicId),
    {
      ...options,
    }
  );
};

export {
  useUsersQuery,
  useUsersFromClinicQuery,
  useCurrentUserQuery,
  useAddUserMutation,
  usePractitionersForUserQuery,
  useDeleteUserMutation,
  useRemoveClinicFromUserMutation,
  useGetPractitionersPerClinic,
  getPractitionersPerClinic,
};
