import React from "react";
import { IconProps } from "@phosphor-icons/react";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

type HeroBlockProps = {
  title: string;
  text: string;
  IconComponent?: React.ElementType<IconProps>;
  actions?: React.ReactNode;
  showBackButton?: boolean | string;
};

const HeroBlock: React.FC<HeroBlockProps> = ({
  title,
  text,
  IconComponent,
  actions,
  showBackButton = false,
}) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (typeof showBackButton === "string") {
      navigate(showBackButton);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-w-full tw-items-center tw-border-b-2">
        {showBackButton && (
          <CaretLeft
            size={40}
            className="tw-mr-2 tw-cursor-pointer"
            onClick={handleBackButtonClick}
          />
        )}
        <div className="tw-relative tw-w-full">
          {IconComponent && (
            <IconComponent size={150} color="#EDEBF5" className="" />
          )}
          <div
            className="tw-z-10 tw-absolute tw-top-0 tw-flex tw-flex-col tw-justify-center"
            style={{ height: "100%" }}
          >
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
        </div>
        <div>
          {actions && <div className="max-md:tw-w-full">{actions}</div>}
        </div>
      </div>
    </>
  );
};

export default HeroBlock;
