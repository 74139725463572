import { useUsersQuery } from "../../api/rest/company";
import UserRow from "../../components/design-systems/UserRow/UserRow";

const CompanyUsers = () => {
  const usersQuery = useUsersQuery();
  const users = usersQuery.data || [];

  console.log({ users });

  return (
    <div className="tw-flex-1">
      {users.map((user) => (
        <UserRow key={user.id} user={user} />
      ))}
    </div>
  );
};

export default CompanyUsers;
