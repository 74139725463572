import { List } from "@phosphor-icons/react";
import clsx from "clsx";
import MobileMenu from "./MobileMenu";
import { useEffect, useState } from "react";
import { ReactComponent as LogoSvg } from "../../img/logo.svg";

type MobileNavbarProps = {
  className?: string;
};

const MobileNavbar: React.FC<MobileNavbarProps> = ({ className }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleOpenMobileMenu = () => setIsMobileMenuOpen(true);
  const handleCloseMobileMenu = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobileMenuOpen]);

  return (
    <div className={clsx("tw-bg-dental-neutral-100", className)}>
      <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-5">
        <LogoSvg className="tw-w-44" />
        <div className="tw-rounded-full tw-p-2" onClick={handleOpenMobileMenu}>
          <List size={40} weight="bold" className="tw-text-dental-primary-P2" />
        </div>
      </div>
      <MobileMenu isOpen={isMobileMenuOpen} onClose={handleCloseMobileMenu} />
    </div>
  );
};

export default MobileNavbar;
