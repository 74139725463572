import axios from "axios";
import { apiUrl } from "./config";
// import { useSession } from '../context/SessionProvider'; // Adjust the path according to your file structure

function getCsrfToken() {
  const cookies = document.cookie.split("; ");
  const csrfCookie = cookies.find((cookie) => cookie.startsWith("XSRF-TOKEN="));
  return csrfCookie ? decodeURIComponent(csrfCookie.split("=")[1]!) : "";
}

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

// Add request interceptor to include CSRF token in headers
axiosInstance.interceptors.request.use(
  (config) => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers["X-XSRF-TOKEN"] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle session expiration and other errors
const setupAxiosInterceptors = (onSignout: () => void) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      // If the response is successful, return it as is
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: // Unauthorized, usually indicates the user is not logged in
          case 419: // Laravel's CSRF token mismatch or session expired
          case 503: // Service unavailable, could be maintenance mode
            onSignout(); // This will clear the session and local storage
            window.location.href = "/login"; // Redirect the user to the login page
            break;
          case 500:
            alert("Oops, something went wrong! The team has been notified.");
            break;
          default:
            return Promise.reject(error);
        }
      } else {
        // Handle other errors (like network errors) here if needed
        return Promise.reject(error);
      }
    }
  );
};

// Optionally, you can call setupAxiosInterceptors in the session provider or App component
export { setupAxiosInterceptors };
export default axiosInstance;
