import { Outlet } from "react-router-dom";

const NoNavbarLayout = () => {
  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-gray-100">
      <div className="tw-w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default NoNavbarLayout;
