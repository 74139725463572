const CommentIcon = ({
    className,
    size = 20,
    ...props
}: {
    className?: string;
    size?: number;
}) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 34 40"
            stroke="currentColor"
            {...props}
        >
            <path d="M9.99995 33.3334C9.09995 33.3334 8.3194 33.0028 7.6583 32.3417C6.9972 31.6806 6.66665 30.9001 6.66665 30.0001V3.3333C6.66665 2.4333 6.9972 1.65275 7.6583 0.99165C8.3194 0.33055 9.09995 0 9.99995 0H30.6667C31.5667 0 32.3473 0.33055 33.0084 0.99165C33.6694 1.65275 34 2.4333 34 3.3333V30.0001C34 30.9001 33.6694 31.6806 33.0084 32.3417C32.3473 33.0028 31.5667 33.3334 30.6667 33.3334H9.99995ZM9.99995 30.0001H30.6667V3.3333H9.99995V30.0001ZM3.3333 40C2.4333 40 1.65275 39.6695 0.99165 39.0084C0.33055 38.3473 0 37.5667 0 36.6667V8.3333C0 7.86107 0.160284 7.46523 0.48085 7.1458C0.801417 6.82637 1.19863 6.66665 1.6725 6.66665C2.14637 6.66665 2.54163 6.82637 2.8583 7.1458C3.17497 7.46523 3.3333 7.86107 3.3333 8.3333V36.6667H25.6667C26.1389 36.6667 26.5348 36.827 26.8542 37.1476C27.1736 37.4681 27.3333 37.8653 27.3333 38.3392C27.3333 38.8131 27.1736 39.2083 26.8542 39.525C26.5348 39.8417 26.1389 40 25.6667 40H3.3333Z" fill="black" />
        </svg>
    );
};

export default CommentIcon;
