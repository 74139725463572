import axiosInstance from "../../../src/axiosConfig";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { Organisation } from "../../types/models/company";
import { useSession } from "../../context/session";

const fetchOrganisations = async (token: string): Promise<Organisation[]> => {
  if (!token) {
    throw new Error(
      "No token found. User must be logged in to fetch organisation."
    );
  }

  const response = await axiosInstance.get("/api/public/v2/organisations", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Failed to fetch organisation");
  }

  return response.data.data;
};

const updateOrganisation = async (
  token: string,
  organisationId: number,
  formData: FormData
) => {
  const response = await axiosInstance.post(
    `/api/public/v2/organisations/${organisationId}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to update organisation");
  }

  return response.data;
};

const useOrganisationsQuery = (
  options?: UseQueryOptions<Organisation[], Error>
) => {
  const { token } = useSession();

  return useQuery<Organisation[], Error>(
    ["organisations"],
    () => fetchOrganisations(token!),
    {
      ...options,
      enabled: !!token,
    }
  );
};

type UpdateOrganisationRequestType = {
  organisationId: number;
  formData: any;
};

const useUpdateOrganisationMutation = (
  options?: UseMutationOptions<unknown, Error, UpdateOrganisationRequestType>
) => {
  const { token } = useSession();

  return useMutation<unknown, Error, UpdateOrganisationRequestType>(
    ({ organisationId, formData }) =>
      updateOrganisation(token!, organisationId, formData),
    options
  );
};

export { useOrganisationsQuery, useUpdateOrganisationMutation };
