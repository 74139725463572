import "react-toastify/dist/ReactToastify.css";

import { toast, ToastContainer } from "react-toastify";

// import styles from "./index.module.css";
import { X } from "@phosphor-icons/react";
import styles from "./index.module.css";

const NotificationsContainer = () => (
  <ToastContainer
    className={styles.notificationsContainer}
    bodyClassName={styles.notificationsBody}
    position={toast.POSITION.TOP_RIGHT}
    autoClose={6000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    draggable
    pauseOnHover
    closeButton={<X className="tw-h-5 tw-w-5" />}
  />
);

export { NotificationsContainer };
