import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../src/axiosConfig";
import { AxiosError } from "axios";
import { UpdatePasswordType } from "../../types/models/user";
import { useSession } from "../../context/session";

type UpdatePasswordResponse = {
  message: string;
};

type SetNewPasswordRequest = {
  email: string;
  password: string;
  password_confirmation: string;
};

export type ResetPasswordType = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

type ResetPasswordResponse = UpdatePasswordResponse;

type SetNewPasswordResponse = {
  message: string;
};

type ForgotPasswordRequest = {
  email: string;
};

type ForgotPasswordResponse = {
  message: string;
};

const resetPassword = async (
  resetPasswordData: ResetPasswordType
): Promise<ResetPasswordResponse> => {
  const response = await axiosInstance.post(
    "/api/reset-password",
    resetPasswordData
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Failed to update password");
  }
};

const useResetPasswordMutation = (
  options?: UseMutationOptions<
    ResetPasswordResponse,
    AxiosError,
    ResetPasswordType
  >
) => {
  return useMutation<ResetPasswordResponse, AxiosError, ResetPasswordType>(
    (resetPasswordData) => resetPassword(resetPasswordData),
    {
      ...options,
    }
  );
};

const forgotPassword = async (
  data: ForgotPasswordRequest
): Promise<ForgotPasswordResponse> => {
  const response = await axiosInstance.post("/api/forgot-password", data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Failed to send forgot password request");
  }
};

const useForgotPasswordMutation = (
  options?: UseMutationOptions<
    ForgotPasswordResponse,
    AxiosError,
    ForgotPasswordRequest
  >
) => {
  return useMutation<ForgotPasswordResponse, AxiosError, ForgotPasswordRequest>(
    (data) => forgotPassword(data),
    {
      ...options,
    }
  );
};

const updatePassword = async (
  updatePasswordData: UpdatePasswordType
): Promise<UpdatePasswordResponse> => {
  const response = await axiosInstance.post(
    "/api/update-password",
    updatePasswordData
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Failed to update password");
  }
};

const useUpdatePasswordMutation = (
  options?: UseMutationOptions<
    UpdatePasswordResponse,
    AxiosError,
    UpdatePasswordType
  >
) => {
  return useMutation<UpdatePasswordResponse, AxiosError, UpdatePasswordType>(
    (updatePasswordData) => updatePassword(updatePasswordData),
    {
      ...options,
    }
  );
};

const setNewPassword = async (
  token: string,
  data: SetNewPasswordRequest
): Promise<SetNewPasswordResponse> => {
  const postData = {
    ...data,
    _method: "PATCH",
  };

  const response = await axiosInstance.post(`/api/set-new-password`, postData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Failed to set new password");
  }
};

const useSetNewPasswordMutation = (
  options?: UseMutationOptions<
    SetNewPasswordResponse,
    AxiosError,
    Omit<SetNewPasswordRequest, "email">
  >
) => {
  const { token, user } = useSession();

  return useMutation<
    SetNewPasswordResponse,
    AxiosError,
    Omit<SetNewPasswordRequest, "email">
  >((data) => setNewPassword(token!, { email: user!.email, ...data }), {
    ...options,
  });
};

export {
  useUpdatePasswordMutation,
  useSetNewPasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
};
