import { useForm } from "react-hook-form";
import {
  LoginCredentials,
  useLoginAsMutation,
  useLoginMutation,
} from "../api/auth";
import {
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
} from "../components/design-systems/FormGroup/FormGroup";
import { Button } from "../components/design-systems/Button/Button";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../components/AddEmployee/AddEmployee";
import { AxiosError } from "axios";
import { notify } from "../components/design-systems/Alert/notify";
import { useState } from "react";
import { ReactComponent as LogoSvg } from "../img/logo-white.svg";
import ForgotPasswordDialog from "../components/design-systems/Dialog/ForgotPasswordDialog";

const Login = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get("user_id") || "";
  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] =
    useState(false);

  const handleDialogOpen = () => setIsForgotPasswordDialogOpen(true);
  const handleDialogClose = () => setIsForgotPasswordDialogOpen(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>();

  const { mutate: login, isLoading } = useLoginMutation();
  const { mutate: loginAs, isLoading: loginAsIsLoading } = useLoginAsMutation();

  const onSubmit = (data: LoginCredentials) => {
    if (userId) {
      // Adjusted to pass both userId and credentials to the loginAs mutation
      loginAs(
        {
          userId: userId,
          credentials: data, // Pass the login credentials along with userId
        },
        {
          onSuccess: () => {
            navigate("/company/clinics");
          },
          onError: (error: AxiosError) => {
            const errorMessage =
              (error.response?.data as ErrorResponse)?.message ||
              "An unexpected error occurred";
            notify(errorMessage, { type: "error" });
          },
        }
      );
    } else {
      // Regular login flow remains unchanged
      login(data, {
        onSuccess: () => {
          navigate("/company/clinics");
        },
        onError: (error: AxiosError) => {
          const errorMessage =
            (error.response?.data as ErrorResponse)?.message ||
            "An unexpected error occurred";
          notify(errorMessage, { type: "error" });
        },
      });
    }
  };

  return (
    <div className="tw-flex tw-min-h-screen">
      <div className="tw-flex-1 tw-bg-dental-neutral-900 tw-m-2 tw-flex tw-flex-col tw-justify-center tw-pr-24 max-sm:tw-hidden">
        <div className="tw-m-20">
          <LogoSvg className="tw-w-32 tw-mb-16" />
          <h1 className="tw-text-3xl tw-text-dental-primary-P6 tw-mb-6">
            Welcome back!
          </h1>
          <p className="tw-text-base tw-text-dental-neutral-N6">
            Just enter your login credentials to get back onto Dental Media and
            continue your workflow.
          </p>
        </div>
        <img
          src={require("../img/mockup-front-view.png")}
          alt="platform-mockup"
          className="tw-pb-28"
        />
      </div>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-p-8">
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-items-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tw-space-y-6 tw-w-full tw-max-w-md"
          >
            <h1 className="tw-text-left tw-text-3xl tw-mb-16">Login</h1>
            <FormGroup>
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput
                type="email"
                {...register("email", { required: "Email is required" })}
                placeholder="Enter your email"
              />
              <FormError>{errors.email?.message}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="password">Password</FormLabel>
              <FormInput
                type="password"
                {...register("password", { required: "Password is required" })}
                placeholder="Enter your password"
              />
              <FormError>{errors.password?.message}</FormError>
              <p
                className="tw-text-dental-neutral-N6 tw-pt-2 tw-cursor-pointer"
                onClick={handleDialogOpen}
              >
                Forgot password
              </p>
            </FormGroup>
            <div className="tw-flex tw-justify-end">
              <Button
                type="submit"
                disabled={isLoading || loginAsIsLoading}
                isLoading={isLoading || loginAsIsLoading}
                className="tw-w-"
              >
                Login
              </Button>
            </div>
          </form>
          <ForgotPasswordDialog
            isOpen={isForgotPasswordDialogOpen}
            onClose={handleDialogClose}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
